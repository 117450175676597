import React from "react";
import Phase2BondsTopBarView from "../views/BondsTopBarView";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { TopbarView } from "../views/TopbarView";

export default function Header(): React.JSX.Element {
    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");

    return (
        <>
            {phase3UIEnabled ? <TopbarView /> : <Phase2BondsTopBarView />}
        </>
    );
}
