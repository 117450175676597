import { RtcResolution } from "@avos-io/rtc-client";

import { Optional } from "../misc/types";
import * as d from "./domain";

export interface TrackResolution {
    height: number;
    width: number;
}

export function toRtcResolution(res: TrackResolution): RtcResolution {
    return { ...res, $typeName: "rtc.Resolution" };
}

export interface Track {
    enabled: boolean;
    muted: boolean;
    source?: MediaStream;
    rtcTrackId?: string;
    updateRenderHint?: (hint: TrackResolution) => void;
}

// get a disabled and muted track
export function getDisabledTrack(): Track {
    return { enabled: false, muted: true };
}

// Is a track defined and, if so, is it active - i.e. enabled and not muted.
export function isTrackActive(track: Optional<Track>): track is Track {
    return !!track && track.enabled && !track.muted;
}

export interface Participant {
    id: d.RtcParticipantId;
    userId: d.UserId;
    audioTrack: Track;
    videoTrack: Track;
    displayTrack: Track;
}

export interface DisplayMediaParticipant {
    id: d.RtcParticipantId;
    userId: d.UserId;
    displayMediaTrack: Track;
}

export type OrderedParticipant = Participant | DisplayMediaParticipant;

export function isSelfView(p: OrderedParticipant, currentUserId: Optional<d.UserId>): boolean {
    return !!currentUserId && p.userId === currentUserId && !isDisplay(p);
}

export function isDisplay(p: OrderedParticipant): p is DisplayMediaParticipant {
    return "displayMediaTrack" in p;
}

function getTileIdString(pId: string, isDisplay?: boolean): string {
    return `${pId}${isDisplay ? "-display" : ""}`;
}

// getTileId gets the key for the tile in the grid corresponding to the participant.
// If the participant is display media, append `-display` to the tile ID.
// TODO: make proper domain type for tile IDs
export function getTileId(p: OrderedParticipant): string {
    return getTileIdString(p.id, isDisplay(p));
}

export const getVideo = (p: OrderedParticipant) =>
    isDisplay(p) ? p.displayMediaTrack : p.videoTrack;

export const getAudio = (p: OrderedParticipant) => isDisplay(p) ? undefined : p.audioTrack;

export const hasActiveAudio = (p: OrderedParticipant) => isTrackActive(getAudio(p));
export const hasActiveVideo = (p: OrderedParticipant) => isTrackActive(getVideo(p));

export const getParticipantUserId = (p: OrderedParticipant | Participant): d.UserId => p.userId;

// Extracts screen-share from a list of participants and prepends these as DisplayMediaParticipants
export function orderParticipants(participants: Participant[]) {
    // Put participants with display media in their own tiles.
    const displayMediaParticipants: DisplayMediaParticipant[] = participants
        .filter(p => p.displayTrack.enabled)
        .map(p => ({
            id: p.id,
            userId: p.userId,
            displayMediaTrack: p.displayTrack,
        }));

    // Safely typecast and combine all call participants
    const typed: OrderedParticipant[] = displayMediaParticipants;
    const orderedParticipants: OrderedParticipant[] = typed.concat(participants);

    return { orderedParticipants, displayMediaParticipants };
}

export const canOpenLiveView = (
    participants: OrderedParticipant[],
    currentUserId: Optional<d.UserId>,
) => participants.some(p => (hasActiveVideo(p) && !isSelfView(p, currentUserId)));
