import { separateDiscriminatedUnion } from "../misc/utils";
import * as d from "./domain";
import { SquadOverview } from "./squads";
import { UserOverview } from "./users";

export type SquadMention = {
    target: d.SquadId;
};

export type UserMention = {
    target: d.UserId;
};

export type Mention =
    | { case: "squad"; } & SquadMention
    | { case: "user"; } & UserMention;

/**
 * @deprecated
 */
export type ContentMention = Mention & {
    startOffset: number;
    endOffset: number;
};

export const isSquadMention = (mention: Mention): mention is { case: "squad"; } & SquadMention =>
    mention.case == "squad";

export const isUserMention = (mention: Mention): mention is { case: "user"; } & UserMention =>
    mention.case == "user";

export function extractSquadIdsFromMentions(mentions: Mention[]): d.SquadId[] {
    return mentions.filter(isSquadMention).map(m => m.target);
}

export function newSquadMention(squadId: d.SquadId): Mention {
    return {
        case: "squad",
        target: squadId,
    };
}

export function newUserMention(userId: d.UserId): Mention {
    return {
        case: "user",
        target: userId,
    };
}

export function filterMentions(ms: Mention[]): { userIds: d.UserId[]; squadIds: d.SquadId[]; } {
    const [users, squads] = separateDiscriminatedUnion<
        Mention & UserMention,
        Mention & SquadMention
    >(isUserMention, ms);

    return {
        userIds: users.map(m => m.target),
        squadIds: squads.map(m => m.target),
    };
}

export const squadNameForMention = (squad: SquadOverview) => squad.name;

export const userNameForMention = (user: UserOverview) =>
    (user.nickname.trim() || user.name.split(/\s+/g)[0]) ?? "Unknown";
