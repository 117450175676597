import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import classNames from "classnames";
import isEqual from "lodash.isequal";
import React, {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import { bondCreationDraftTarget, DraftTarget, DraftType } from "../domain/channels";
import { changeDeltaBuilder, EmitterSources, isMarkupDeltaEmpty } from "../domain/delta";
import { MediaToggleMap } from "../domain/mediaDevices";
import { Mention } from "../domain/mentions";
import {
    DraftChatMessage,
    MessageSendableStatus,
    messageSendableStatusText,
} from "../domain/messages";
import { updateBondCreationDraftQueryParameters } from "../features/bondCreation";
import {
    selectDraft,
    selectDraftMarkup,
    selectDraftMessageSendableStatus,
    updateDraftMarkup,
} from "../features/channels";
import { sustainTypingActivityReport } from "../features/connection";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/meta";
import useMetaInterest from "../hooks/interest/useMetaInterest";
import useDeltaSync from "../hooks/useDeltaSync";
import useOutsideClick from "../hooks/useOutsideClick";
import usePrevious from "../hooks/usePrevious";
import useRichTextAutoCompleteQuery from "../hooks/useRichTextAutoCompleteQuery";
import useSelectorArgs from "../hooks/useSelectorArgs";
import useStreamDispatch from "../hooks/useStreamDispatch";
import { isMobileBrowser } from "../misc/mobile";
import { Focusable, NumberRange, Optional } from "../misc/types";
import { useAppDispatch } from "../store/redux";
import AttachmentUploadControls from "./AttachmentUploadControls";
import AudienceHint from "./AudienceHint";
import { MentionAutoComplete } from "./MentionAutoComplete";
import PeopleCount from "./PeopleCount";
import QuillRichTextEditor from "./QuillRichTextEditor";
import type { RichTextEditorOps } from "./RichTextEditor";
import { CloseButton } from "./buttons/Close";
import CallControls, { CallControlsLocation } from "./gui/CallControls";
import ComposerButton from "./gui/ComposerButton";
import { MentionInsertFunc } from "../hooks/useMentionSuggestions";

export const typingTimeoutDelay = 5 * 1000;

export interface MessageComposerProps {
    id: string;
    draftTarget: DraftTarget;
    msgCompletionAction: (draft: DraftChatMessage | undefined) => void;

    showBondInterestedAction?: () => void;
    numberOfParticipants?: number;
    mediaControls?: MediaToggleMap;
    bondComposer?: boolean;
    placeholder?: string;

    onModalChange?: (current: boolean) => void;
    onEditorFocus?: () => void;
    onEditorBlur?: () => void;

    escapeAction?: () => void;
    discardAction?: () => void;
}

const triggers = ["@"];

export const RichTextMessageComposer = forwardRef((
    props: MessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        draftTarget,
        msgCompletionAction: onMessageCompletion,
        discardAction,
        escapeAction,
        bondComposer,
        placeholder,
        mediaControls,
        onModalChange,
        onEditorFocus,
        onEditorBlur,
    } = props;
    const numberOfParticipants = props.numberOfParticipants ?? 0;
    const openBondInterested = props.showBondInterestedAction ?? (() => {});
    const showPeopleCount = numberOfParticipants > 0;

    const dispatch = useAppDispatch();

    const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [currentlyTyping, setCurrentlyTyping] = useState<boolean>(false);

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const draftMarkup = useSelectorArgs(selectDraftMarkup, draftTarget);

    const [selection, setSelection] = useState<NumberRange>({ start: 0, end: 0 });
    const onSelectionChange = useCallback(
        (range: NumberRange | null) => {
            if (range) {
                setSelection(range);
            }
        },
        [],
    );

    const editorRef = useRef<RichTextEditorOps>(null);

    const mentionQuery = useRichTextAutoCompleteQuery(
        triggers,
        draftMarkup,
        selection.end,
    );

    const { range: currentAutoCompleteRange } = mentionQuery ?? {};

    // Push mention-related data into the store so bond-creation suggestions
    // can use it in whatever way it likes.
    useEffect(() => {
        if (draftTarget.type !== DraftType.BondCreation) return;

        dispatch(updateBondCreationDraftQueryParameters({
            triggers,
            currentAutoCompleteRange,
        }));
    }, [dispatch, draftTarget, currentAutoCompleteRange]);

    const prevMentionOffset = usePrevious(mentionQuery?.range.start);

    // Get the screen position of mention query (if one is active)
    const mentionScreenPos = useMemo(
        () => editorRef.current?.getOffsetScreenPos(mentionQuery?.range.start),
        [mentionQuery],
    );

    // Show the mention autocomplete if a mention query is present and the autocomplete has not
    // already been shown for this mention query offset
    const [showMentionAutoComplete, setShowMentionAutoComplete] = useState(false);
    useEffect(() => {
        if (prevMentionOffset != mentionQuery?.range.start) {
            setShowMentionAutoComplete(mentionQuery !== undefined);
        }
    }, [prevMentionOffset, mentionQuery]);

    useEffect(() => {
        // Both the window and the editor must be focused to be considered typing
        if (
            !isMarkupDeltaEmpty(draftMarkup) &&
            document.hasFocus() &&
            editorRef.current?.hasFocus()
        ) {
            setCurrentlyTyping(true);

            // Clear the existing timeout and start a new one
            if (typingTimeout.current !== null) {
                clearTimeout(typingTimeout.current);
            }
            typingTimeout.current = setTimeout(() => {
                setCurrentlyTyping(false);
            }, typingTimeoutDelay);
        }
        else {
            setCurrentlyTyping(false);
        }
    }, [draftMarkup]);

    // This stream should start sending when currentlyTyping goes from false to
    // true, and stop sending when it goes from true to false.
    useStreamDispatch(() => currentlyTyping && sustainTypingActivityReport(), [currentlyTyping]);

    const msgCompletionBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockMsgCompletion,
    );
    const msgSendableStatus = useSelectorArgs(
        selectDraftMessageSendableStatus,
        draftTarget,
        isEqual(draftTarget, bondCreationDraftTarget),
    );
    const complete = useCallback(() => {
        // Suppress completion when e.g. the mention autocomplete is shown with suggestions
        if (msgCompletionBlocked) {
            return;
        }

        if (msgSendableStatus == MessageSendableStatus.Ready) {
            onMessageCompletion(draft);
        }
    }, [draft, msgCompletionBlocked, msgSendableStatus, onMessageCompletion]);

    const sendButtonText = messageSendableStatusText(msgSendableStatus);

    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const showEmojiPickerRef = useRef<HTMLButtonElement>(null);
    const outsideClickCallback = useCallback((target: EventTarget | null) => {
        // Filter out clicks on the button which shows the emoji picker itself.
        if (showEmojiPickerRef && showEmojiPickerRef.current?.contains(target as Node)) {
            return;
        }
        setShowEmojiPicker(false);
    }, [showEmojiPickerRef, setShowEmojiPicker]);
    const [emojiPickerDivRef] = useOutsideClick<HTMLDivElement>(outsideClickCallback);

    const insertEmoji = useCallback((emoji: { native: string; }) => {
        dispatch(
            updateDraftMarkup({
                draftTarget,
                update: {
                    change: changeDeltaBuilder()
                        .retain(selection.start)
                        .delete(selection.end - selection.start)
                        .insert(emoji.native)
                        .buildPlain(),
                },
                source: EmitterSources.API,
            }),
        );

        setShowEmojiPicker(false);

        editorRef.current?.focus();
    }, [dispatch, draftTarget, selection]);

    const insertMention: MentionInsertFunc = useCallback(
        (mention: Mention, text: string, range: NumberRange) =>
            dispatch(
                updateDraftMarkup({
                    draftTarget,
                    update: {
                        change: changeDeltaBuilder()
                            .retain(range.start)
                            .delete(range.end - range.start)
                            .insert({ mention: { ...mention, text } })
                            .insert(" ")
                            .buildPlain(),
                    },
                    source: EmitterSources.API,
                }),
            ),
        [dispatch, draftTarget],
    );

    // Notify the parent when a modal opens or closes
    const modalState = showMentionAutoComplete || showEmojiPicker;
    const prevModalState = usePrevious(modalState);
    useLayoutEffect(() => {
        if (modalState != prevModalState) {
            onModalChange?.(modalState);
        }
    }, [onModalChange, modalState, prevModalState]);

    // Sync the markup delta between the redux store and the rich text editor
    const onEditorUserChange = useCallback(() => setShowEmojiPicker(false), []);
    const onEditorChange = useDeltaSync(draftTarget, editorRef, onEditorUserChange);

    // Document-level escape key handler (for when composer is not focussed)
    useEffect(() => {
        if (!showEmojiPicker) return;

        const check = (e: KeyboardEvent) => {
            // Ignore events for all keys bar 'Escape'
            if (e.key != "Escape") return;

            // Always close the emoji viewer if open
            if (!showEmojiPicker) return;

            e.preventDefault();

            setShowEmojiPicker(false);

            editorRef.current?.focus();
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [showEmojiPicker]);

    const escapeVariant = useCallback((action: Optional<() => void>) => {
        // Always close the mention autocomplete if open
        if (showMentionAutoComplete) {
            setShowMentionAutoComplete(false);
        }
        // Blur the editor if focussed
        else if (editorRef.current) {
            editorRef.current.blur();
            action?.();
        }
    }, [showMentionAutoComplete]);

    const onEditorEscape = useCallback(
        () => escapeVariant(escapeAction),
        [escapeAction, escapeVariant],
    );

    const onEditorShiftEscape = useCallback(
        () => escapeVariant(discardAction),
        [discardAction, escapeVariant],
    );

    const onEditorBlurWrapper = useCallback(() => {
        onEditorBlur?.();
        setCurrentlyTyping(false);
    }, [onEditorBlur]);

    useImperativeHandle(ref, () => ({
        focus: () => editorRef.current?.focus(),
        blur: () => editorRef.current?.blur(),
        hasFocus: () => editorRef.current?.hasFocus() ?? false,
    }), []);

    // Block hotkeys when the emoji picker is open
    useMetaInterest(showEmojiPicker, MetaInterestCounterKey.BlockHotkey);

    // Focus the editor when the background of the control area is clicked (visually this
    // looks to the user like part of the editor so it makes sense to focus)
    const onClickControlArea = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (e.currentTarget === e.target) {
            editorRef.current?.focus({ goToEnd: true });
        }
    }, []);

    const classes = classNames(
        "c-composer",
        "c-composer--rich",
        {
            "c-composer--bond": bondComposer,
        },
    );

    const headerClasses = classNames(
        "c-composer__header",
        {
            "c-composer__header--bond": bondComposer,
        },
    );

    return (
        <>
            {showMentionAutoComplete && !showEmojiPicker && mentionScreenPos && mentionQuery &&
                createPortal(
                    <MentionAutoComplete
                        mentionX={mentionScreenPos.x}
                        mentionY={mentionScreenPos.y}
                        query={mentionQuery}
                        insert={insertMention}
                        draftTarget={draftTarget}
                    />,
                    document.body,
                )}
            <div className={classes} data-testid={props.id}>
                {showEmojiPicker && (
                    <div ref={emojiPickerDivRef} className="c-emoji-wrapper">
                        <Picker
                            data={data}
                            onEmojiSelect={insertEmoji}
                            emojiSize={19}
                            previewPosition={"none"}
                            theme="dark"
                            autoFocus={true}
                        />
                    </div>
                )}
                <div className={headerClasses}>
                    <div className="c-composer-header-count">
                        {showPeopleCount && (
                            <PeopleCount
                                n={numberOfParticipants}
                                testId="comms-interested"
                                onClick={openBondInterested}
                            />
                        )}
                        {bondComposer && discardAction && (
                            <CloseButton
                                title="Discard draft"
                                onClick={discardAction}
                                side={"composer"}
                            />
                        )}
                    </div>
                </div>
                <div className="c-composer__actions">
                    <AudienceHint draftTarget={draftTarget} />
                    <QuillRichTextEditor
                        ref={editorRef}
                        tabIndex={1}
                        markupForInit={draftMarkup}
                        placeholder={placeholder}
                        onChange={onEditorChange}
                        onSelect={onSelectionChange}
                        onSubmit={complete}
                        onEscape={onEditorEscape}
                        onShiftEscape={onEditorShiftEscape}
                        onFocus={onEditorFocus}
                        onBlur={onEditorBlurWrapper}
                    />
                    <div
                        className="c-composer__controls"
                        onClick={onClickControlArea}
                    >
                        <div className="c-composer__btn-group">
                            {!isMobileBrowser() && (
                                <ComposerButton
                                    content="Emojis"
                                    onClick={() => setShowEmojiPicker(old => !old)}
                                    buttonRef={showEmojiPickerRef}
                                    extraStyle="cp-btn-composer--emojis"
                                    title="Emoji"
                                />
                            )}
                            <AttachmentUploadControls draftTarget={draftTarget} />
                        </div>
                        <div className="c-composer__btn-group">
                            <CallControls
                                mediaToggles={mediaControls}
                                location={CallControlsLocation.LiveBond}
                            />
                        </div>
                        <div className="c-composer__btn-group">
                            <ComposerButton
                                content={"Send"}
                                onClick={complete}
                                extraStyle="cp-btn-composer--send"
                                disabled={msgSendableStatus != MessageSendableStatus.Ready}
                                title={sendButtonText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default RichTextMessageComposer;
