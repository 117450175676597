import { DraftTarget } from "../domain/channels";
import { userNameForMention } from "../domain/mentions";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import { AutoComplete } from "./gui/AutoComplete";
import Avatar from "./gui/Avatar";
import SensitiveText from "./gui/SensitiveText";
import useMentionSuggestions, {
    MentionInsertFunc,
    SquadMentionSuggestionContentProps,
    UserMentionSuggestionContentProps,
} from "../hooks/useMentionSuggestions";

function SquadMentionSuggestionContent(
    props: SquadMentionSuggestionContentProps,
): React.JSX.Element {
    const { squad } = props;
    return (
        <>
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{squad.name}</SensitiveText>
                </em>
            </div>
            <div className="c-people-count">
                <div className="c-people-count__icon"></div>
                {squad.userIds.length}
            </div>
        </>
    );
}

function UserMentionSuggestionContent(
    props: UserMentionSuggestionContentProps,
): React.JSX.Element {
    const { user } = props;
    return (
        <>
            <Avatar userId={user.id} size="xs-small" />
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{userNameForMention(user)}</SensitiveText>
                </em>
                &nbsp;&bull;&nbsp;
                <SensitiveText>{user.name}</SensitiveText>
            </div>
        </>
    );
}

interface MentionAutoCompleteProps {
    mentionX: number;
    mentionY: number;
    insert: MentionInsertFunc;
    query: AutoCompleteQuery;
    draftTarget: DraftTarget;
    similarityThreshold?: number;
}

export const MentionAutoComplete = (props: MentionAutoCompleteProps): React.JSX.Element => {
    const { mentionX, mentionY, insert, query, draftTarget } = props;

    const { suggestionCount, suggestionGroups } = useMentionSuggestions({
        insert,
        query,
        draftTarget,
        SquadMentionSuggestionContent,
        UserMentionSuggestionContent,
    });

    return (
        <>
            {(suggestionCount > 0) && (
                <AutoComplete
                    anchorX={mentionX}
                    anchorY={mentionY}
                    verticalAnchor="bottom"
                    horizontalClampBehaviour="clamp"
                    suggestionGroups={suggestionGroups}
                />
            )}
        </>
    );
};
