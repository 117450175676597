import React, { useEffect, useRef } from "react";

interface ScrollDetectorProps {
    onScroll: (isScrolled: boolean) => void;
    children: React.ReactNode;
    className?: string;
}
// Use with useScrollDetector.
const ScrollDetector: React.FC<ScrollDetectorProps> = ({ onScroll, children, className }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const checkScroll = () => {
            const scrolled = container.scrollLeft > 0;
            onScroll(scrolled);
        };

        container.addEventListener("scroll", checkScroll);
        checkScroll();

        return () => {
            container.removeEventListener("scroll", checkScroll);
        };
    }, [onScroll]);

    return (
        <div ref={containerRef} className={className}>
            {children}
        </div>
    );
};

export default ScrollDetector;
