import { createContext, useCallback, useContext, useRef } from "react";
import {
    completeAttachmentThunk,
    getAttachmentCredentialsThunk,
    selectProposedAttachments,
    selectCompletableAttachmentIds,
    selectLocalAttachment,
    updateLocalAttachment,
    deleteAttachmentFromDraftThunk,
    selectCredentialedAttachmentIds,
    updateLocalAttachmentBackoff,
} from "../../features/chats";
import { BlockBlobClient } from "@azure/storage-blob";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useConnectedEffect } from "../../hooks/useConnectedEffect";
import log from "../../misc/log";
import * as d from "../../domain/domain";
import useQueueProcessor from "../../hooks/useQueueProcessor";
import { fileNamePreparer } from "../../misc/attachments";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import {
    AnyLocalAttachment,
    createProposedAttachment,
    isCompletableAttachment,
    isCredentialedAttachment,
    isProposedAttachment,
    LocalAttachmentStatus,
    credentialedToUploadingAttachment,
    uploadingToCompletableAttachment,
} from "../../domain/attachments";
import { credentialsValidWithin } from "../../domain/blobs";

// The number of times one of the processors can encounter an error on an enqueued
// attachment before giving up.
const maxRetries = 5;

export const fileStashMap = new Map<d.LocalAttachmentId, File>();
export const FileStashContext = createContext(fileStashMap);

/** Fetch upload credentials for ProposedAttachments.
 */
function ProposedProcessor(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const fileStash = useContext(FileStashContext);

    const needCredentials = useAppSelector(selectProposedAttachments);

    // Combine backoff state per-attachment and across all attachments.
    // That means: if an attempt fails, it gets a certain backoff period,
    // and the processor also applies its own backoff before attempting the
    // next attachment (whether or not it is the one that failed).
    const {
        target: targetId,
        succeeded,
        failed,
    } = useQueueProcessor(needCredentials);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, targetId);
    targetRef.current = targetAttachment;

    const cleanupAttachment = useCallback((localId: d.LocalAttachmentId) => {
        if (!localId) return;

        dispatch(deleteAttachmentFromDraftThunk(localId))
            .unwrap()
            .catch(e => {
                log.error(`Failed to remove attachment ${localId} from draft`, e);
            })
            .finally(() => {
                fileStash.delete(localId);
                failed();
            });
    }, [dispatch, fileStash, failed]);

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!targetId || !target) return;

        if (!isProposedAttachment(target)) {
            log.error(
                `Attachment ${targetId} in inconsistent state, Proposed vs ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // This will persist even after restarts; safest just to purge it.
            return cleanupAttachment(targetId);
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${targetId} credential fetch reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            return cleanupAttachment(targetId);
        }

        const file = fileStash.get(targetId);
        if (!file) {
            log.warn(`Attachment ${targetId} missing file in stash`);
            // Inconsistent state; we need to purge the attachment.
            return cleanupAttachment(targetId);
        }

        dispatch(getAttachmentCredentialsThunk(target))
            .unwrap()
            .then(() => {
                succeeded();
            }, (e: any) => {
                //  Backoff state also updated by thunk rejection slice reducer
                log.info("Problem when getting attachment upload credentials, retrying:", e);
                failed();
            });
    }, [
        targetId,
        dispatch,
        fileStash,
        succeeded,
        failed,
        cleanupAttachment,
    ]);

    return <></>;
}

export type BlockBlobClientFactory = (url: string) => BlockBlobClient;

/** Upload attachments that have credentials to the blob store.
 */
function CredentialedProcessor(
    { clientFactory }: { clientFactory: BlockBlobClientFactory; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const fileStash = useContext(FileStashContext);

    const uploadableAttachments = useAppSelector(selectCredentialedAttachmentIds);

    const {
        target: targetId,
        succeeded,
        failed,
    } = useQueueProcessor(uploadableAttachments);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, targetId);
    targetRef.current = targetAttachment;

    const cleanupAttachment = useCallback((localId: d.LocalAttachmentId) => {
        if (!localId) return;

        dispatch(deleteAttachmentFromDraftThunk(localId))
            .unwrap()
            .catch()
            .finally(() => {
                fileStash.delete(localId);
                failed();
            });
    }, [dispatch, fileStash, failed]);

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!targetId || !target) return;

        if (!isCredentialedAttachment(target)) {
            log.error(
                `Attachment ${targetId} in inconsistent state, wanted Credentialed, got ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // This will persist even after restarts; safest just to purge it.
            return cleanupAttachment(targetId);
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${targetId} data upload reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            return cleanupAttachment(targetId);
        }

        const targetAsUploading = credentialedToUploadingAttachment(target);

        const { credentials } = targetAsUploading;
        if (!credentialsValidWithin(credentials, 30)) {
            log.info(`Credentials for attachment ${targetId} expired`);
            const { credentials: _, ...withoutCreds } = target;
            const downgradedAttachment = createProposedAttachment(withoutCreds);
            dispatch(updateLocalAttachment(downgradedAttachment));
            return succeeded();
        }

        const file = fileStash.get(target.localId);
        if (!file) {
            // If we reach here, we may have leaked something into the backend.
            // Let the backend clear it up.
            log.warn(
                `Attachment ${target.localId} about to be uploaded, but missing from file cache`,
            );
            return cleanupAttachment(targetId);
        }

        dispatch(updateLocalAttachment(targetAsUploading));

        const uploadFileName = fileNamePreparer(file.name);

        clientFactory(credentials.url)
            .uploadData(file, {
                maxSingleShotSize: 4 * 1024 * 1024,
                blobHTTPHeaders: {
                    blobContentType: file.type,
                    blobCacheControl: "max-age=31536000, immutable",
                    blobContentDisposition: `attachment;filename="${uploadFileName}"`,
                },
            })
            .then(
                _ => {
                    const targetAsCompletable = uploadingToCompletableAttachment(targetAsUploading);
                    dispatch(updateLocalAttachment(targetAsCompletable));

                    fileStash.delete(targetId);

                    succeeded();
                },
                (e: any) => {
                    log.info(`Attachment ${targetId} failed to upload`, e);
                    // Serialise uploads - don't try to upload another attachment
                    dispatch(updateLocalAttachmentBackoff(targetId));
                    failed();
                },
            );
    }, [
        targetId,
        dispatch,
        clientFactory,
        cleanupAttachment,
        fileStash,
        succeeded,
        failed,
    ]);

    return <></>;
}

/** "Complete" the attachment by telling the backend it has finished uploading.
 */
function CompletableProcessor(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const completeableIds = useAppSelector(selectCompletableAttachmentIds);

    // Combine backoff state per-attachment and across all attachments.
    // That means: if an attempt fails, it gets a certain backoff period,
    // and the processor also applies its own backoff before attempting the
    // next attachment (whether or not it is the one that failed).
    const {
        target: targetId,
        succeeded,
        failed,
    } = useQueueProcessor(completeableIds);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, targetId);
    targetRef.current = targetAttachment;

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!targetId || !target) return;

        if (!isCompletableAttachment(target)) {
            log.error(
                `Attachment ${targetId} in inconsistent state: expected Completable, got ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // Purge, let the backend deal with anything leaked.
            dispatch(deleteAttachmentFromDraftThunk(targetId));
            return succeeded();
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${targetId} completer reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            dispatch(deleteAttachmentFromDraftThunk(targetId));
            return failed();
        }

        dispatch(completeAttachmentThunk(target))
            .unwrap()
            .then(
                () => {
                    succeeded();
                },
                (e: any) => {
                    log.info("Completing attachment failed: retrying", e);
                    failed();
                },
            );
    }, [
        targetId,
        dispatch,
        succeeded,
        failed,
    ]);

    return <></>;
}

export default function AttachmentManager(
    props: { clientFactory: BlockBlobClientFactory; },
): React.JSX.Element {
    return (
        <>
            <ProposedProcessor />
            <CredentialedProcessor clientFactory={props.clientFactory} />
            <CompletableProcessor />
        </>
    );
}
