export const BondNewDivider = (): React.JSX.Element => (
    <div className="c-message-divider">
        <div className="c-message-divider__title">New</div>
        <div className="c-message-divider__rule"></div>
    </div>
);

export const BondTimeDivider = (): React.JSX.Element => (
    <div className="c-message-divider">
        <div className="c-message-divider__rule--grey"></div>
    </div>
);
