export type MobileViewName = "mybonds" | "mysquads" | "squad" | "discover" | "bond";

export function mobilePathToViewName(pathname: string): MobileViewName {
    if (pathname === "/mobile/tab/mybonds") {
        return "mybonds";
    }
    if (pathname === "/mobile/tab/mysquads") {
        return "mysquads";
    }
    if (pathname.startsWith("/squad")) {
        return "squad";
    }
    if (pathname === "/mobile/tab/discover") {
        return "discover";
    }
    return "bond";
}
