import { useOutletContext } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";

import * as d from "../domain/domain";
import { useAppDispatch } from "../store/redux";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import {
    inviteUserToBondThunk,
    inviteUserToBondViaEmailThunk,
    selectBondById,
} from "../features/bonds";
import log from "../misc/log";

import { CloseButton } from "./buttons/Close";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import { useNavigateBack } from "../hooks/useNavigateBack";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import { fetchContactsBookThunk, selectUsers } from "../features/users";
import Avatar from "./gui/Avatar";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import useMentionSuggestions, {
    EmailInvitationSuggestionContentProps,
    MentionInsertFunc,
    SquadMentionSuggestionContentProps,
    UserMentionSuggestionContentProps,
} from "../hooks/useMentionSuggestions";
import { Mention, userNameForMention } from "../domain/mentions";
import { NumberRange } from "../misc/types";
import SensitiveText from "./gui/SensitiveText";
import classNames from "classnames";
import { FeatureFlagged } from "./FeatureFlags";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { isEmailAddress } from "../misc/utils";
import useSet from "../hooks/useSet";

function UserMentionSuggestionContent(
    props: UserMentionSuggestionContentProps,
): React.JSX.Element {
    const { user, highlighted } = props;

    const classes = classNames(
        "c-suggestion c-suggestion--human",
        highlighted && "c-suggestion--highlighted",
    );

    return (
        <div className={classes}>
            <Avatar userId={user.id} size="xs-small" />
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{userNameForMention(user)}</SensitiveText>
                </em>
                &nbsp;&bull;&nbsp;
                <SensitiveText>{user.name}</SensitiveText>
            </div>
        </div>
    );
}

function SquadMentionSuggestionContent(
    props: SquadMentionSuggestionContentProps,
): React.JSX.Element {
    const { squad } = props;

    const classes = "c-suggestion c-suggestion--human";

    return (
        <button className={classes}>
            <div className="c-suggestion__squad-icon"></div>
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{squad.name}</SensitiveText>
                </em>
                &nbsp;&bull;&nbsp;{squad.userIds.length}&nbsp;people
            </div>
        </button>
    );
}

function EmailInvitationSuggestionContent(
    props: EmailInvitationSuggestionContentProps,
): React.JSX.Element {
    const { email, highlighted } = props;

    const classes = classNames(
        "c-suggestion c-suggestion--human",
        highlighted && "c-suggestion--highlighted",
    );

    if (!isEmailAddress(email)) {
        return <></>;
    }

    return (
        <div className={classes}>
            <div className="u-truncate-auto">
                Invite&nbsp;
                <em>
                    <SensitiveText>{email}</SensitiveText>
                </em>
                &nbsp;by email.
            </div>
        </div>
    );
}

export default function InviteUsersModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const bondOverview = useSelectorArgs(selectBondById, bondId);

    // The sketches have squads appear in the 'Add people' modal, but setting a
    // channel draft target hides squads from the suggestions at the moment.
    const draftTarget = useMemo(
        () => bondOverview && newChannelDraftTarget(bondOverview.channelId),
        [bondOverview],
    );

    // Fetch the full list of all the current user's potential contacts when the
    // modal loads, and register interest in them.
    const [contactIds, setContactIds] = useState<Array<d.UserId>>([]);
    useInterestedUsers(contactIds);

    useConnectedEffect(() => {
        const getContactIds = async () => {
            setContactIds(await dispatch(fetchContactsBookThunk()).unwrap());
        };
        getContactIds();
    }, [dispatch]);

    const closeModal = useCallback(() => {
        log.debug("Closing modal");
        navigateBack();
    }, [navigateBack]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const inviteUserToBondCb = useCallback((userId: d.UserId) => {
        dispatch(
            inviteUserToBondThunk({
                bondId,
                invitedUserId: userId,
            }),
        );
    }, [dispatch, bondId]);

    const addUserToBondViaEmailCb = useCallback((emailAddress: string) => {
        const confirm = window.confirm(`Invite ${emailAddress} to this bond?`);
        if (!confirm) return;

        dispatch(
            inviteUserToBondViaEmailThunk({
                bondId,
                invitedEmailAddress: emailAddress,
            }),
        );
    }, [dispatch, bondId]);

    const [userLookupText, setUserLookupText] = useState("");

    const userLookupQuery: AutoCompleteQuery = useMemo(() => ({
        trigger: "",
        text: userLookupText,
        range: { start: 0, end: userLookupText.length },
    }), [userLookupText]);

    const {
        add: addChosenUserId,
        remove: removeChosenUserId,
        has: userIdIsChosen,
        sorted: sortedChosenUserIds,
    } = useSet<d.UserId>();

    const chosenUsers = useSelectorArgs(selectUsers, sortedChosenUserIds);
    useInterestedUsers(sortedChosenUserIds);

    const selectUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        if (userIdIsChosen(userId)) {
            removeChosenUserId(userId);
        }
        else {
            addChosenUserId(userId);
        }
    }, [addChosenUserId, removeChosenUserId, userIdIsChosen]);

    const insert: MentionInsertFunc = useCallback(
        (
            mention: Mention,
            _text: string,
            _range: NumberRange,
        ) => {
            if (mention.case != "user") return;
            selectUser(mention.target);
        },
        [selectUser],
    );

    const { suggestionCount, suggestionGroups } = useMentionSuggestions({
        insert,
        query: userLookupQuery,
        draftTarget: draftTarget || bondCreationDraftTarget,
        SquadMentionSuggestionContent,
        UserMentionSuggestionContent,
        EmailInvitationSuggestionContent,
        emailInvitationCb: addUserToBondViaEmailCb,
    });

    const inviteAllChosen = useCallback(() => {
        chosenUsers.forEach(u => inviteUserToBondCb(u.id));
        closeModal();
    }, [chosenUsers, inviteUserToBondCb, closeModal]);

    if (!bondOverview) return <></>;

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">Add people</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <div className="c-form-element-new">
                        <input
                            className="c-input-new c-input-new--full"
                            type="text"
                            value={userLookupText}
                            onChange={e => setUserLookupText(e.target.value)}
                            placeholder="Name or name@example.com"
                        />
                    </div>

                    <div className="c-autocomplete-list">
                        {suggestionCount > 0 && suggestionGroups.map(group => (
                            <div>
                                {group.map(suggestion => (
                                    // TODO: Add keyboard navigation
                                    <div key={suggestion.key} onClick={suggestion.onAdd}>
                                        {suggestion.Content({
                                            highlighted: userIdIsChosen(
                                                suggestion.key as d.UserId,
                                            ),
                                        })}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </article>

            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--add"
                    onClick={inviteAllChosen}
                    disabled={chosenUsers.length == 0}
                >
                    {`Add ${chosenUsers.length > 0 ? chosenUsers.length : ""} users to bond`}
                </button>
            </div>
        </dialog>
    );
}

export function FlaggedInviteUsersModal(): React.JSX.Element {
    return (
        <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
            <InviteUsersModal />
        </FeatureFlagged>
    );
}
