import { RefObject, useCallback, useLayoutEffect } from "react";
import { RichTextEditorChangeHandler, RichTextEditorOps } from "../components/RichTextEditor";
import { DraftTarget } from "../domain/channels";
import {
    ChangeDelta,
    EmitterSource,
    EmitterSources,
    MarkupDelta,
    compareDeltas,
    composeDeltas,
    parseChangeDelta,
    plainifyChangeDelta,
    plainifyMarkupDelta,
} from "../domain/delta";
import {
    selectDraft,
    selectDraftLastApiChange,
    selectDraftMarkup,
    updateDraftMarkup,
} from "../features/channels";
import { useAppDispatch } from "../store/redux";
import usePrevious from "./usePrevious";
import useSelectorArgs from "./useSelectorArgs";

export const useDeltaSync = (
    draftTarget: DraftTarget,
    editorRef: RefObject<RichTextEditorOps>,
    onEditorUserChange?: () => void,
): RichTextEditorChangeHandler => {
    const dispatch = useAppDispatch();

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const markup = useSelectorArgs(selectDraftMarkup, draftTarget);
    const prevMarkup = usePrevious(markup);
    const lastApiChange = useSelectorArgs(selectDraftLastApiChange, draftTarget);

    // Handle direct changes to the store that need to be returned to the editor
    useLayoutEffect(() => {
        // If we don't have an editor ref, we can't push any changes anyway
        if (editorRef.current === null) return;

        // If the draft markup has not changed, there is nothing to do
        if (compareDeltas(markup, prevMarkup)) return;

        // If there is a draft, but the last change was not an api change, there is nothing to do. The
        // case where draft is undefined handles the deletion of the draft (e.g. sending a message), while
        // the case where lastApiChange is undefined handles a programmatic change to the draft (e.g.
        // inserting a mention); in both of these cases we need the change to be reflected in the editor
        if (draft !== undefined && lastApiChange === undefined) return;

        const change = lastApiChange && parseChangeDelta(lastApiChange);

        editorRef.current.setOrUpdateContent(markup, change);
    }, [draft, markup, prevMarkup, lastApiChange, editorRef]);

    // Set the text and check for any style changes
    const onEditorChange = useCallback(
        (change: ChangeDelta, oldMarkup: MarkupDelta, source: EmitterSource) => {
            if (source == EmitterSources.API) return;

            const markup = composeDeltas(oldMarkup, change);

            dispatch(
                updateDraftMarkup({
                    draftTarget,
                    update: {
                        markup: plainifyMarkupDelta(markup),
                        change: plainifyChangeDelta(change),
                    },
                    source,
                }),
            );

            onEditorUserChange?.();
        },
        [dispatch, draftTarget, onEditorUserChange],
    );

    return onEditorChange;
};

export default useDeltaSync;
