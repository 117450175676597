import { Draft as ImmerDraft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";
import { CreateBondFromMsgParams, createBondFromMsg } from "../api/bondCreation";
import {
    AnyLocalAttachment,
    ProposedAttachment,
    isUploadedAttachment,
} from "../domain/attachments";
import { SuggestedBond } from "../domain/bonds";
import {
    DraftTarget,
    DraftType,
    bondCreationDraftTarget,
    isBondCreationDraftTarget,
    isChannelDraftTarget,
} from "../domain/channels";
import {
    DraftChatMessage,
    convertDraftToUnsentMessage,
    emptyDraftChatMessage,
} from "../domain/messages";
import {
    changeDeltaBuilder,
    concatDeltas,
    deletePrefilledChange,
    EmitterSources,
    emptyChangeDelta,
    promotePrefilledChange,
    renderDeltaToTextForSuggestionsQuery,
} from "../domain/delta";
import * as d from "../domain/domain";
import {
    DraftChatContent_V1,
    convertDraftContentToV1,
    convertDraftContentToV2,
    getDraftBodyText,
    getDraftMessageMarkup,
    getDraftMessageText,
    isDraftBodyEmpty,
    switchDraftContentVersion,
    withChange,
    withMarkup,
} from "../domain/draftChatContent";
import { isUserMention, newSquadMention, squadNameForMention } from "../domain/mentions";
import * as migrateV14 from "../domain/migrate/v14";
import log from "../misc/log";
import {
    ContentMutation,
    applyTextToContent,
    clearContent,
    insertMentionIntoContent,
    insertTextIntoContent,
    promotePrefixInContent,
    removePrefixFromContent,
    setPrefixMentionInContent,
} from "../misc/messageContent";
import { assignDefined, mapIsSubset } from "../misc/primatives";
import { ExpandType, NumberRange, Optional } from "../misc/types";
import { checkPersistor, sessionStorageStore } from "../persist/shared";
import { storageRead, storageRemove, storageWrite } from "../persist/storage";
import type { Connection, PersistenceUpdate, RWTransaction } from "../persist/types";
import { createAppAsyncThunk, createAppSelector } from "../store/redux";
import { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { selectBondEntities } from "./bonds";
import {
    InsertDraftMentionArgs,
    InsertDraftTextArgs,
    TransferDraftArgs,
    UpdateDraftTextArgs,
    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
    insertDraftMention,
    insertDraftText,
    selectDraft,
    selectDraftMentions,
    selectLocalAttachmentsDraftForMessage,
    stageMessageForChannel,
    updateDraftMarkup,
    updateDraftText,
} from "./channels";
import { deleteAttachmentFromDraftThunk, selectLocalAttachment } from "./chats";
import { SetPrivateOrSquadFilterThunkResult, setPrivateOrSquadFilterThunk } from "./filterPanel";
import { memoizeOptions } from "./selectors";
import { unaryThunkHandler } from "./thunk";

type CreateBondFromMessageArgs = ExpandType<
    & Omit<CreateBondFromMsgParams, "senderId" | "msg" | "officialAttachmentIds">
    & { msg: DraftChatMessage; }
>;
export const createBondFromMessageThunk = createAppAsyncThunk(
    "bonds/createFromMessage",
    async (args: CreateBondFromMessageArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const senderId = selectCurrentUserId(state);

        if (!senderId) {
            log.warn("Cannot create bond: no user id");
            return thunkAPI.rejectWithValue({ error: "No user id" });
        }

        const msg = convertDraftToUnsentMessage(args.msg, Date.now());
        if (!msg) {
            log.warn("Cannot create bond: failed to convert draft to unsent message");
            return thunkAPI.rejectWithValue({ error: "Failed to convert draft to unsent message" });
        }

        const officialAttachmentIds = selectOfficialAttachmentIdsForBondCreationMessage(state);

        return await unaryThunkHandler(
            thunkAPI,
            createBondFromMsg({
                ...args,
                senderId,
                msg,
                officialAttachmentIds,
            }),
            "createBondFromMessage",
        );
    },
);

// Avoid needing to use a selector to grab the draft message content.
export const transferDraftThunk = createAppAsyncThunk(
    "bonds/transferDraft",
    ({ from, to }: Omit<TransferDraftArgs, "content">, thunkAPI) => {
        const state = thunkAPI.getState();

        const draft = selectDraft(state, from);
        if (!draft || isDraftBodyEmpty(draft.content)) {
            return thunkAPI.rejectWithValue({ error: "Cannot transfer empty draft" });
        }

        // Only transfer the draft back to bond-creation if
        // `draftPassesBack` is true.
        if (
            isChannelDraftTarget(from)
            && isBondCreationDraftTarget(to)
            && !selectors.draftPassesBack(state)
        ) {
            return thunkAPI.rejectWithValue({ error: "Cancelling transfer as !draftPassesBack" });
        }

        return { from, to, content: draft };
    },
);

export const clearDraftThunk = createAppAsyncThunk(
    "bonds/clearDraft",
    (
        draftTarget: DraftTarget,
        thunkAPI,
    ): { draftTarget: DraftTarget; attachments?: AnyLocalAttachment[]; } => {
        const state = thunkAPI.getState();
        const attachments = selectLocalAttachmentsDraftForMessage(state, draftTarget);

        return { draftTarget, attachments };
    },
);

export interface BondSuggestionQueryParameters {
    triggers: string[];
    currentAutoCompleteRange?: NumberRange;
}

export interface BondCreationState {
    draft: DraftChatMessage;

    /**
     * User specified title for the bond. If undefined, an ai-generated title will be used
     */
    title?: string;

    // When leaving a bond, should the draft travel back into the
    // BondCreationBar?
    draftPassesBack: boolean;

    // Track the intended visibility of the suggestions (via some
    // rather complex logic) separately to tracking the actual suggested bonds.
    suggestedBonds: SuggestedBond[];

    // Parameters for preparing the draft itself before using it to query for
    // bond suggestions.
    queryParameters?: BondSuggestionQueryParameters;
}

/**
 * Return the query string from a message that should be used to query for suggested bonds
 */
export const determineQueryString = (
    draft: Optional<DraftChatMessage>,
    params: Optional<BondSuggestionQueryParameters>,
) => switchDraftContentVersion<string>(
    draft?.content,
    c1 => {
        if (!params) return "";

        const s = getDraftBodyText(c1);
        if (!s) return "";

        const { triggers, currentAutoCompleteRange } = params;
        // Only remove whitespace from the *start* of the string, as we use
        // trailing whitespace to determine when we should fetch new suggestions
        const t = s.trimStart();

        if (!currentAutoCompleteRange) {
            // Nasty. Another case of "store updated the render following the text update".
            return triggers.includes(t) ? "" : t;
        }

        const { start, end } = currentAutoCompleteRange;
        const prefix = (c1.prefix && (c1.prefix.length + c1.prefix.padding)) ?? 0;

        const r = s.slice(0, start - prefix) + s.slice(end - prefix + 1);

        return r.trimStart();
    },
    c2 => renderDeltaToTextForSuggestionsQuery(c2.draftMarkup),
    () => "",
);

const getInitialState = (props?: Partial<BondCreationState>): BondCreationState => {
    const draft = props?.draft ?? emptyDraftChatMessage();

    return {
        draft,
        title: props?.title,
        suggestedBonds: props?.suggestedBonds ?? [],
        draftPassesBack: props?.draftPassesBack ?? false,
        queryParameters: undefined,
    };
};

const updateParamsOnDraftChange = (
    oldHasBody: boolean,
    newHasBody: boolean,
): {
    suggestedBonds: Optional<SuggestedBond[]>;
    draftPassesBack: Optional<boolean>;
} => {
    const bodyDeleted = oldHasBody && !newHasBody;
    const suggestedBonds = bodyDeleted ? [] : undefined;

    const draftPassesBack = !newHasBody ? false : undefined;

    return {
        suggestedBonds,
        draftPassesBack,
    };
};

/**
 * @deprecated
 */
const contentMutationReducer = <P>(
    mutation: ContentMutation<P>,
    options?: {
        validate?: (state: ImmerDraft<BondCreationState>, payload: P) => boolean;
    },
) =>
(
    state: ImmerDraft<BondCreationState>,
    action: PayloadAction<P>,
) => {
    if (!(options?.validate?.(state, action.payload) ?? true)) return;

    const draft = {
        ...state.draft,
        content: convertDraftContentToV1(state.draft.content),
    };
    const oldHasBody = !isDraftBodyEmpty(draft.content);

    const newDraft = {
        ...draft,
        content: mutation(draft.content, action.payload),
    };
    const newHasBody = !isDraftBodyEmpty(newDraft.content);

    assignDefined(
        state as BondCreationState,
        updateParamsOnDraftChange(
            oldHasBody,
            newHasBody,
        ),
    );

    state.draft = newDraft;
};

export const bondCreationSlice = createSlice({
    name: "bondCreation",
    initialState: getInitialState(),
    selectors: {
        draft: state => state.draft,
        title: state => state.title,
        prefixLength: state => (state.draft.content as DraftChatContent_V1)?.prefix?.length ?? 0,
        suggestedBonds: state => state.suggestedBonds,
        draftPassesBack: state => state.draftPassesBack,
        queryParameters: state => state.queryParameters,
    },
    reducers: {
        updateTitle: (
            state,
            { payload }: PayloadAction<Optional<string>>,
        ) => {
            state.title = payload;
        },
        updateQueryParameters: (
            state,
            { payload }: PayloadAction<BondSuggestionQueryParameters>,
        ) => {
            state.queryParameters = payload;
        },
        promotePrefilled: (state, action: PayloadAction<DraftTarget>) => {
            if (!isBondCreationDraftTarget(action.payload)) return;

            switchDraftContentVersion(
                state.draft.content,
                _ => contentMutationReducer<DraftTarget>(promotePrefixInContent)(state, action),
                _ => {
                    const draft = {
                        ...state.draft,
                        content: convertDraftContentToV2(state.draft.content),
                    };
                    const oldHasBody = isDraftBodyEmpty(draft.content);

                    // Build and apply a change to promote any existing prefilled mentions in the draft
                    const markup = getDraftMessageMarkup(draft.content);
                    const change = promotePrefilledChange(markup);

                    const newDraft = {
                        ...draft,
                        content: withChange(draft.content, change, EmitterSources.API),
                    };
                    const newHasBody = isDraftBodyEmpty(draft.content);

                    assignDefined(
                        state as BondCreationState,
                        updateParamsOnDraftChange(oldHasBody, newHasBody),
                    );
                    state.draft = newDraft;
                },
                () => {},
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(transferDraftThunk.fulfilled, (
            state,
            { payload: { from, to, content } }: PayloadAction<TransferDraftArgs>,
        ) => {
            const fromUs = isBondCreationDraftTarget(from);
            const toUs = isBondCreationDraftTarget(to);

            if (toUs && fromUs) return;

            if (fromUs) {
                return getInitialState({
                    draftPassesBack: true,
                    suggestedBonds: state.suggestedBonds,
                });
            }

            if (toUs) {
                return getInitialState({
                    draft: { ...content, draftTarget: bondCreationDraftTarget },
                    draftPassesBack: true,
                    suggestedBonds: state.suggestedBonds,
                });
            }
        });
        builder.addCase(
            transferDraftThunk.rejected,
            (state, { meta: { arg: { from, to } } }) => {
                const fromUs = isBondCreationDraftTarget(from);
                const toUs = isBondCreationDraftTarget(to);

                if (toUs && fromUs) return;

                return getInitialState({
                    draftPassesBack: false,
                    draft: state.draft,
                    suggestedBonds: [],
                });
            },
        );

        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, action) => {
                if (!isBondCreationDraftTarget(action.payload.draftTarget)) return;

                switchDraftContentVersion(
                    state.draft.content,
                    _ => contentMutationReducer<{ draftTarget: DraftTarget; }>(
                        clearContent,
                    )(state, action),
                    _ => {
                        state.draft = emptyDraftChatMessage({
                            contentVer: 2,
                            makeClearChange: true,
                        });
                    },
                    () => {},
                );

                state.title = undefined;
            },
        );

        // TODO: think about optimistic updates here?
        builder.addCase(createBondFromMessageThunk.fulfilled, (_state, _action) => {
            return getInitialState();
        });
        builder.addCase(createBondFromMessageThunk.rejected, (_state, _action) => {
            // throw action.error;
        });

        builder.addCase(updateDraftMarkup, (state, action) => {
            if (!isBondCreationDraftTarget(action.payload.draftTarget)) {
                return;
            }

            const {
                update: { markup, change },
                source,
            } = action.payload;

            const draft = {
                ...state.draft,
                content: convertDraftContentToV2(state.draft.content),
            };
            const oldHasBody = isDraftBodyEmpty(draft.content);

            const newDraft = {
                ...draft,
                content: change ?
                    withChange(draft.content, change, source) :
                    withMarkup(draft.content, markup!, source),
            };
            const newHasBody = isDraftBodyEmpty(newDraft.content);

            assignDefined(
                state as BondCreationState,
                updateParamsOnDraftChange(oldHasBody, newHasBody),
            );
            state.draft = newDraft;
        });

        /**
         * @deprecated
         */
        const isBondCreationValidator = {
            validate: (_: any, p: { draftTarget: DraftTarget; }): boolean =>
                p.draftTarget.type === DraftType.BondCreation,
        };

        /**
         * @deprecated
         */
        builder.addCase(
            updateDraftText,
            contentMutationReducer<UpdateDraftTextArgs>(
                applyTextToContent,
                isBondCreationValidator,
            ),
        );
        /**
         * @deprecated
         */
        builder.addCase(
            insertDraftText,
            contentMutationReducer<InsertDraftTextArgs>(
                insertTextIntoContent,
                isBondCreationValidator,
            ),
        );
        /**
         * @deprecated
         */
        builder.addCase(
            insertDraftMention,
            contentMutationReducer<InsertDraftMentionArgs>(
                insertMentionIntoContent,
                isBondCreationValidator,
            ),
        );

        builder.addCase(
            setPrivateOrSquadFilterThunk.fulfilled,
            (state, action) =>
                switchDraftContentVersion(
                    state.draft.content,
                    c1 =>
                        contentMutationReducer<SetPrivateOrSquadFilterThunkResult>(
                            (_, args: SetPrivateOrSquadFilterThunkResult) => {
                                const { newFilter, newSquad, oldFilter } = args;

                                if (newFilter.by == "squad" && newSquad !== undefined) {
                                    return setPrefixMentionInContent(
                                        c1,
                                        newSquadMention(newSquad.id),
                                        `@${squadNameForMention(newSquad)}`,
                                    );
                                }
                                else if (oldFilter.by == "squad") {
                                    return removePrefixFromContent(c1);
                                }

                                return c1;
                            },
                        )(state, action),
                    c2 => {
                        const { newFilter, newSquad, oldFilter } = action.payload;

                        const draft = state.draft;
                        const oldHasBody = !isDraftBodyEmpty(c2);

                        // If the filter didn't actually change there is no need to scan the
                        // message for prefilled mentions
                        if (isEqual(newFilter, oldFilter)) return;

                        // If the new filter is a squad filter, create a change to prepend a
                        // 'prefilled' mention to the draft
                        const changeForAdd = (newFilter.by === "squad" && newSquad !== undefined) ?
                            changeDeltaBuilder()
                                .insert({
                                    mention: {
                                        ...newSquadMention(newSquad.id),
                                        text: `@${squadNameForMention(newSquad)}`,
                                    },
                                }, { prefilled: true })
                                .insert(" ", { prefilled: true })
                                .build() :
                            emptyChangeDelta;

                        // Build a change to delete any existing prefilled mentions from the draft
                        const markup = getDraftMessageMarkup(c2);
                        const changeForDelete = deletePrefilledChange(markup);

                        // Concatenate the the two changes and apply the result to the draft
                        const change = concatDeltas(changeForAdd, changeForDelete);

                        const newDraft = {
                            ...draft,
                            content: withChange(c2, change, EmitterSources.API),
                        };
                        const newHasBody = isDraftBodyEmpty(newDraft.content);

                        assignDefined(
                            state as BondCreationState,
                            updateParamsOnDraftChange(
                                oldHasBody,
                                newHasBody,
                            ),
                        );
                        state.draft = newDraft;
                    },
                    () => {},
                ),
        );
        builder.addCase(
            addAttachmentsToDraft,
            (state, { payload: attachments }: PayloadAction<ProposedAttachment[]>) => {
                if (attachments.length === 0) return;

                attachments.forEach(attachment => {
                    if (!isBondCreationDraftTarget(attachment.draftTarget)) return;

                    const { localId } = attachment;
                    const index = state.draft.attachmentIds.indexOf(localId);
                    if (index === -1) {
                        state.draft.attachmentIds.push(localId);
                    }
                });
            },
        );
        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId, draftTarget } }) => {
                if (!isBondCreationDraftTarget(draftTarget)) return;

                const index = state.draft.attachmentIds.indexOf(localId);
                if (index !== -1) {
                    state.draft.attachmentIds.splice(index, 1);
                }
            },
        );

        builder.addCase(
            clearAttachmentsFromDraft,
            (state, { payload: draftTarget }: PayloadAction<DraftTarget>) => {
                if (!isBondCreationDraftTarget(draftTarget)) return;

                state.draft.attachmentIds = [];
            },
        );
        builder.addCase(stageMessageForChannel.fulfilled, (_state, _) => {
            return getInitialState();
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    updateTitle: updateBondCreationTitle,
    updateQueryParameters: updateBondCreationDraftQueryParameters,
    promotePrefilled,
} = bondCreationSlice.actions;

const selectors = bondCreationSlice.getSelectors((state: RootState) => state.bondCreation);
export const {
    draft: selectBondCreationDraft, // guarantees non-undefined DraftChatMessage
    title: selectBondCreationTitle,
    prefixLength: selectBondCreationPrefixLength,
    suggestedBonds: selectSuggestedBonds,
} = selectors;

const firstNonWhitespaceCharRegex = new RegExp(/^\s*[^\s]/);
const indexOfFirstNonWhitespaceChar = (s: string): number | undefined => {
    const match = firstNonWhitespaceCharRegex.exec(s);
    if (!match) return undefined; // null => undefined
    return match[0].length - 1;
};

export const selectBondCreationMessageQueryString = createAppSelector(
    [selectBondCreationDraft, selectors.queryParameters],
    determineQueryString,
    memoizeOptions.weakMap,
);

export const selectOfficialAttachmentIdsForBondCreationMessage = createAppSelector(
    [state => state, selectBondCreationDraft],
    (state, msg) =>
        msg?.attachmentIds
            .map(a => selectLocalAttachment(state, a))
            .filter(isUploadedAttachment)
            .map(a => a.blobId) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectShowSuggestedBonds = createAppSelector(
    [
        selectors.queryParameters,
        state => selectDraftMentions(state, bondCreationDraftTarget),
        selectBondCreationMessageQueryString,
        selectors.draft,
    ],
    (params, mentions, queryString, draft): boolean => {
        // We wait for the MessageComposer to update the query parameters.
        // This happens the render after the message content is changed.
        // This avoids the flashing of "Suggestions" during this time.
        if (!params) return false;

        // If we already have a user mention, we can show suggestions.
        if (mentions.some(isUserMention)) return true;

        // If the query string (after removing the text of the current ongoing
        // mention) is empty, and we don't have any non-prefix mentions, don't
        // show anything.
        if (!queryString) return false;

        // If we have a query string and we're not currently autocompleting,
        // show suggestions.
        if (!params.currentAutoCompleteRange) return true;

        // Make sure we don't show suggestions if the message is just whitespace
        // before an ongoing mention.
        const text = getDraftMessageText(draft.content) ?? "";
        return params.currentAutoCompleteRange.start !== indexOfFirstNonWhitespaceChar(text);
    },
    memoizeOptions.weakMap,
);

export const selectSuggestedChannelIds = createAppSelector(
    [selectSuggestedBonds, (state: RootState) => selectBondEntities(state)],
    (suggestedBonds, bonds): Partial<Record<d.BondId, d.ChannelId>> => {
        const entries = suggestedBonds
            .filter(sb => bonds[sb.bondId]?.channelId)
            .map(sb => [sb.bondId, bonds[sb.bondId]!.channelId]);
        return Object.fromEntries(entries);
    },
    memoizeOptions.weakMapShallow,
);

export const reducer = bondCreationSlice.reducer;

// region Persistence

export type PersistedBondCreationState = Omit<BondCreationState, "suggestedBonds">;

const stores = {
    // Write to `sessionStorage` - we may want to be creating 2 different bonds
    // in 2 different tabs.
    _draft1: sessionStorageStore<migrateV14.PersistedBondCreationState>(
        "bond-creation/draft",
        1,
        13,
    ),
    draft: sessionStorageStore<PersistedBondCreationState>("bond-creation/draft-2", 14),
};

const persist = (
    previousState: BondCreationState,
    currentState: BondCreationState,
): PersistenceUpdate[] => {
    if (previousState == currentState) {
        return [];
    }

    // Remove `suggestedBonds` from the state, and persist whatever remains.
    const { suggestedBonds: _, ...toPersist } = currentState;
    if (mapIsSubset(toPersist, previousState)) {
        return [];
    }

    return [[stores.draft, toPersist]];
};

const hydrate = async (_conn: Connection) => {
    const bcs = storageRead(stores.draft);
    return getInitialState(bcs);
};

const purge = () => {
    storageRemove(stores.draft);
};

const migrate = async (_: RWTransaction, oldVersion: number) => {
    if (oldVersion < 14) {
        const oldData = storageRead(stores._draft1);
        if (!oldData) return;

        const newData = migrateV14.translatePersistedBondCreationState(oldData);
        storageWrite(stores.draft, newData);

        storageRemove(stores._draft1);
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    purge,
    migrate,
};
checkPersistor<BondCreationState>(persistor);
