import { ExpandType, Optional } from "../misc/types";
import { AnyLocalAttachment, isUploadedAttachment } from "./attachments";
import { BackoffState } from "./backoff";
import { bondCreationDraftTarget, DraftTarget, newChannelDraftTarget } from "./channels";
import { getContent_Mentions, SanitisedChatContent, sanitiseDraftContent } from "./chatContent";
import * as d from "./domain";
import {
    createBlankDraftContentV1,
    createBlankDraftContentV2,
    DraftChatContent,
    getDraftMentionsCount,
    getDraftTrimmedLength,
} from "./draftChatContent";

// region Official types
// These correspond to messages we have been pushed by a backend.

export enum OfficialMessageType {
    Chat,
    CallStart,
    CallEnd,
}

export interface OfficialMessage {
    id: d.MessageId;
    channelId: d.ChannelId;
    serverRxTs: d.Timestamp;
    sequenceNumber: number;
    type: OfficialMessageType;
}

// Stored as JSON, hence given an explicit type here.
export interface CallMessageContentBlob {
    callId: d.CallId;
    // The summary list of participants (only in call end messages)
    // TODO: marking as required - need to check this assumption as part of possible migration
    participantIds: d.UserId[];
}

export type CallStartMessage = ExpandType<
    & OfficialMessage
    & Pick<CallMessageContentBlob, "callId">
    & { type: OfficialMessageType.CallStart; }
>;

export type CallEndedMessage = ExpandType<
    & OfficialMessage
    & CallMessageContentBlob
    & { type: OfficialMessageType.CallEnd; }
>;

export type OfficialChatMessage = ExpandType<
    & OfficialMessage
    & {
        type: OfficialMessageType.Chat;
        senderId: d.UserId;
        clientTxTs: d.Timestamp;
        content: SanitisedChatContent;
        callId?: d.CallId;
        // Unused - add back later.
        // editOf?: number;
        attachmentIds: d.BlobId[];
    }
>;

export type AnyOfficialMessage = OfficialChatMessage | CallStartMessage | CallEndedMessage;

// region Local types
// The backend has no idea about these messages until we try to send them.

export enum LocalMessageType {
    Draft = "draft",
    Unsent = "unsent",
    Errored = "errored",
}

interface LocalChatMessage {
    type: LocalMessageType;
    localId: d.UnsentMessageId;
    draftTarget: DraftTarget;
    // Always store all local attachments here.
    // Selectors will get you those in the different states.
    attachmentIds: d.LocalAttachmentId[];
    // In order to avoid a flashing UI, we want to track which call we think a
    // message has been sent during. This is ignored totally when the message
    // is actually sent.
    liveCallId?: d.CallId;
}

export type DraftChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Draft;
        content: DraftChatContent;
    }
>;
export type UnsentChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Unsent;
        // A place to put the official message id given to us by the backend.
        // We want to keep using our local id until we receive the message from
        // an RPC stream.
        messageId?: d.MessageId;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        backoffState?: BackoffState;
        // editOf?: d.MessageId; // Unused
        // localEditOf?: d.UnsentMessageId; // Unused
    }
>;

export type ErroredChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Errored;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        // No backoffState - being errored means we have given up.
        // editOf?: d.MessageId; // Unused
    }
>;

export type AnyUnsentLocalMessage = UnsentChatMessage | ErroredChatMessage;

export type AnyLocalMessage = DraftChatMessage | UnsentChatMessage | ErroredChatMessage;

export type AnyMessage = AnyOfficialMessage | AnyLocalMessage;

// region Helper functions

export const isUnsentMsg = (msg: Optional<AnyLocalMessage>): msg is UnsentChatMessage =>
    msg?.type === LocalMessageType.Unsent;

export const isErroredMsg = (msg: Optional<AnyLocalMessage>): msg is ErroredChatMessage =>
    msg?.type === LocalMessageType.Errored;

export const toErroredMsg = (
    { backoffState: _, messageId: __, ...msg }: UnsentChatMessage,
): ErroredChatMessage => ({
    ...msg,
    type: LocalMessageType.Errored,
});

export const determineOfficialMessageContributors = (
    msg: Optional<AnyOfficialMessage>,
): d.UserId[] => {
    switch (msg?.type) {
        case OfficialMessageType.Chat:
            return [msg.senderId];
        case OfficialMessageType.CallEnd:
            return msg.participantIds ?? [];
        default:
            return [];
    }
};

export function emptyDraftChatMessage(
    options?: { channelId?: d.ChannelId; contentVer?: 1 | 2; makeClearChange?: boolean; },
): DraftChatMessage {
    const { channelId, contentVer, makeClearChange } = options ?? {};
    const content = ((contentVer ?? 1) == 1) ?
        createBlankDraftContentV1() :
        createBlankDraftContentV2(makeClearChange ?? false);
    return {
        type: LocalMessageType.Draft,
        localId: content.draftId,
        draftTarget: channelId ? newChannelDraftTarget(channelId) : bondCreationDraftTarget,
        attachmentIds: [],
        content,
    };
}

export enum MessageSendableStatus {
    /**
     * The message in question may not be sent.
     */
    Denied = -1,

    /**
     * The message in question is ready to send.
     */
    Ready = 0,

    /**
     * The message in question has attachments that are uploading and must
     * finish doing so before the message can be sent.
     */
    Waiting = 1,

    /**
     * The string portion of the message in question is too long to be sent.
     */
    TooLong = 2,

    /**
     * The message has no recipients. (Look at bondCreation messages.)
     */
    NoRecipients = 3,
}

const statusText = {
    [MessageSendableStatus.Denied]: "Denied",
    [MessageSendableStatus.Ready]: "Send",
    [MessageSendableStatus.NoRecipients]: "Your message has no recipients",
    [MessageSendableStatus.TooLong]: "Your message is longer than the allowed maximum size",
    [MessageSendableStatus.Waiting]: "Waiting for attachments to finish uploading",
};
export const messageSendableStatusText = (status: MessageSendableStatus): string =>
    statusText[status];

/**
 * Decide if a message is sendable based on its trimmedContent and attachments
 */
export function messageIsSendable(
    draft: Optional<DraftChatMessage>,
    requireRecipients: boolean,
    attachments?: AnyLocalAttachment[],
): MessageSendableStatus {
    if (!draft) {
        return MessageSendableStatus.Denied;
    }

    const { attachmentIds } = draft;

    const mentionsCount = getDraftMentionsCount(draft?.content);
    const trimmedLength = getDraftTrimmedLength(draft?.content);

    if (requireRecipients && mentionsCount === 0) {
        return MessageSendableStatus.NoRecipients;
    }
    if (trimmedLength > 4000) {
        return MessageSendableStatus.TooLong;
    }
    if (attachments?.some(a => !isUploadedAttachment(a))) {
        return MessageSendableStatus.Waiting;
    }
    if (trimmedLength == 0 && attachmentIds.length == 0) {
        return MessageSendableStatus.Denied;
    }
    return MessageSendableStatus.Ready;
}

export function isOfficialMessage<T extends AnyMessage>(
    msg: Optional<T>,
): msg is AnyOfficialMessage & T {
    return !!msg && "id" in msg;
}

export function isOfficialChatMessage<T extends AnyMessage>(
    msg: Optional<T>,
): msg is OfficialChatMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.Chat;
}

export function isCallEndedMessage<T extends AnyMessage>(msg?: T): msg is CallEndedMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.CallEnd;
}

export function isLocalMessage<T extends AnyMessage>(msg?: T): msg is LocalChatMessage & T {
    return !!msg && "localId" in msg;
}

export function isUnsentLocalMessage<T extends AnyMessage>(
    msg?: T,
): msg is AnyUnsentLocalMessage & T {
    switch (msg?.type) {
        case LocalMessageType.Unsent:
        case LocalMessageType.Errored:
            return true;
        default:
            return false;
    }
}

export function haveSameMessageType(a: AnyMessage, b: AnyMessage): boolean {
    if (isOfficialMessage(a) && isOfficialMessage(b)) return a.type === b.type;
    const aLocal = isLocalMessage(a);
    const bLocal = isLocalMessage(b);
    if (aLocal && bLocal) return true;
    return (isOfficialChatMessage(a) && bLocal) || (aLocal && isOfficialChatMessage(b));
}

export function haveSameSender(a: AnyMessage, b: AnyMessage, ourUserId?: d.UserId): boolean {
    if (isLocalMessage(a)) {
        return isLocalMessage(b) || (isOfficialChatMessage(b) && b.senderId === ourUserId);
    }
    else if (isLocalMessage(b)) {
        return isOfficialChatMessage(a) && a.senderId === ourUserId;
    }
    else {
        return isOfficialChatMessage(a) && isOfficialChatMessage(b) && a.senderId === b.senderId;
    }
}

export function getSenderId(msg: Optional<AnyMessage>, ourUserId?: d.UserId): Optional<d.UserId> {
    if (isOfficialChatMessage(msg)) return msg.senderId;
    if (isUnsentLocalMessage(msg)) return ourUserId;
}

export function getCallId(msg: Optional<AnyMessage>): Optional<d.CallId> {
    if (!msg) return;
    if (isOfficialChatMessage(msg)) return msg.callId;
    if (isLocalMessage(msg)) return msg.liveCallId;
    return msg.callId;
}

export const convertDraftToUnsentMessage = (
    draft: DraftChatMessage,
    now: d.Timestamp,
): UnsentChatMessage => {
    const sanitisedContent = sanitiseDraftContent(draft.content);
    return {
        ...draft,
        localId: sanitisedContent.id ?? "unknown",
        type: LocalMessageType.Unsent,
        content: sanitisedContent,
        clientTxTs: now,
    };
};

export function getSenderIdForMention(
    msg: AnyOfficialMessage,
    id?: d.UserId,
): Optional<d.UserId> {
    if (!isOfficialChatMessage(msg)) return;
    if (id === undefined) return;

    const mentions = getContent_Mentions(msg.content);
    if (!mentions || mentions.length === 0) return;

    return mentions.find(m => m.case === "user" && m.target === id) && msg.senderId;
}

export const getAttachmentMsgId = (msg: AnyMessage): d.AttachmentMessageId =>
    isLocalMessage(msg) ? { localId: msg.localId } : { id: msg.id };

export const getMsgSequenceNumber = (msg?: AnyMessage): Optional<number> => {
    if (isOfficialMessage(msg)) return msg.sequenceNumber;
};

export const getMsgAttachmentIds = (msg?: AnyMessage): d.BlobId[] | d.LocalAttachmentId[] => {
    const noAttachments = !isLocalMessage(msg) && !isOfficialChatMessage(msg);
    return noAttachments ? [] : msg.attachmentIds;
};

export const getMsgTs = (msg?: AnyMessage): Optional<Date> => {
    if (!msg) return;
    if (isOfficialMessage(msg)) return new Date(msg.serverRxTs);
    if (msg.type !== LocalMessageType.Draft) return new Date(msg.clientTxTs);
};
