import classNames from "classnames";
import React, { memo, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { bondCreationDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { newUserMention, userNameForMention } from "../domain/mentions";
import { selectCurrentUserId } from "../features/auth";
import {
    insertDraftMention,
    selectDraftMarkup,
    selectDraftText,
    updateDraftMarkup,
} from "../features/channels";
import { FilterScope, selectPrivateOrSquadFilter, topFilterScope } from "../features/filterPanel";
import { selectCurrentSquadIds } from "../features/squads";
import { selectUser } from "../features/users";
import useFreshSquadObservers from "../hooks/useFreshSquadObservers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useAppDispatch, useAppSelector } from "../store/redux";
import Avatar from "./gui/Avatar";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { changeDeltaBuilder, EmitterSources } from "../domain/delta";
import { Human } from "./gui/AvatarV3";
import useAddressParams from "../hooks/useAddressParams";

function PresenceAvatarUnmemoed(
    props: { userId: d.UserId; },
): React.JSX.Element {
    const { userId } = props;

    const dispatch = useAppDispatch();

    const draftTarget = bondCreationDraftTarget;
    const draftMarkup = useSelectorArgs(selectDraftMarkup, draftTarget);
    const draftText = useSelectorArgs(selectDraftText, draftTarget);
    const user = useSelectorArgs(selectUser, userId);

    const currentUserId = useAppSelector(selectCurrentUserId);
    const isCurrentUser = userId == currentUserId;

    const richTextComposer = useBooleanFeatureFlag("rich-text-composer");

    // Set the new bond scope, pre-select this user, and navigate to the new bond view
    const pickUserForNewBond = useCallback(() => {
        if (!user) {
            return;
        }

        const mention = newUserMention(userId);
        const text = "@" + userNameForMention(user);

        if (richTextComposer) {
            dispatch(updateDraftMarkup({
                draftTarget,
                update: {
                    change: changeDeltaBuilder()
                        .retain(draftMarkup._instance.length() - 1)
                        .insert({ mention: { ...mention, text } })
                        .insert(" ")
                        .buildPlain(),
                },
                source: EmitterSources.API,
            }));
        }
        else {
            dispatch(insertDraftMention({
                draftTarget,
                mention,
                text,
                range: {
                    start: draftText.length,
                    end: draftText.length,
                },
            }));
        }
    }, [dispatch, draftTarget, draftMarkup, draftText, user, userId, richTextComposer]);

    if (!userId) {
        return <></>;
    }

    const classes = classNames("c-btn", "c-btn--presence", {
        "c-btn--self": isCurrentUser,
    });

    return (
        <button
            className={classes}
            onClick={isCurrentUser ? undefined : pickUserForNewBond}
        >
            <Avatar
                userId={userId}
                context={{ showSquadPresence: true }}
                size="small"
            />
        </button>
    );
}

const PresenceAvatar = memo(PresenceAvatarUnmemoed);

function PresenceHeader(): React.JSX.Element {
    const pathSegments = useLocation().pathname.split("/");
    const viewLocation = pathSegments.length > 1 ? pathSegments[1] : "";
    const isPhase3 = useBooleanFeatureFlag("phase-3-ui");
    const { squadId } = useAddressParams();

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);
    const privateOrSquadFilter = useAppSelector(selectPrivateOrSquadFilter);
    const scope: FilterScope = useMemo(() => {
        if (isPhase3) {
            if (squadId !== undefined) {
                return { squadId };
            }
            return "unknown";
        }

        return topFilterScope(privateOrSquadFilter);
    }, [privateOrSquadFilter, isPhase3, squadId]);

    const squadsToSubObservers = useMemo(() => {
        if (scope == "private" || scope == "unknown") {
            return currentUserSquads;
        }
        return [scope.squadId];
    }, [scope, currentUserSquads]);

    const sortedSquadObservers = useFreshSquadObservers(
        squadsToSubObservers,
        scope,
    );

    // This logic is getting really nasty. Does it ever evaluate to false?
    const showPresenceHeader = viewLocation == "bond" ||
        viewLocation == "squad" ||
        (pathSegments[1] == "mobile" && pathSegments[2] == "tab" && pathSegments[3] == "mybonds");

    if (!showPresenceHeader) {
        return <></>;
    }

    if (isPhase3) {
        return (
            <div className="c-bonds-presence">
                <div className="c-bonds-presence__humans">
                    {sortedSquadObservers.map(userId => (
                        <Human
                            userId={userId}
                            key={userId}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="c-presence">
            <div className="c-presence__humans">
                {sortedSquadObservers.map(userId => (
                    <PresenceAvatar
                        userId={userId}
                        key={userId}
                    />
                ))}
            </div>
        </div>
    );
}

export default PresenceHeader;
