import React, { LegacyRef } from "react";
import { CallId } from "../domain/domain";
import {
    CallActionStatus,
    openLiveView,
    selectCallById,
    selectCallJoinStatus,
    selectCallStartStatus,
} from "../features/calls";
import { useAppDispatch } from "../store/redux";
import useSelectorArgs from "../hooks/useSelectorArgs";
import SensitiveText from "./gui/SensitiveText";
import { DisplayMediaParticipant } from "../domain/rtc";
import ParticipantTile, { ParticipantType } from "./gui/ParticipantTile";
import useStopwatch from "../hooks/useStopwatch";
import { Optional } from "../misc/types";
import { getTileId } from "../domain/rtc";

export const getLiveStartMessage = (callStartStatus: CallActionStatus) => {
    switch (callStartStatus) {
        case CallActionStatus.Ready:
        case CallActionStatus.Pending:
            return "Going live...";
        case CallActionStatus.Completed:
            return "Focus on the bond to join.";
        case CallActionStatus.Backoff:
            return "Failed to go live. Retrying...";
        case CallActionStatus.Error:
            return "Failed to go live.";
    }
};

export const getLiveConnectionMessage = (
    callStartStatus: CallActionStatus,
    callJoinStatus: CallActionStatus,
) => {
    switch (callJoinStatus) {
        case CallActionStatus.None:
            return callStartStatus === CallActionStatus.Completed ? "Focus on the bond to join."
                : undefined;
        case CallActionStatus.Ready:
        case CallActionStatus.Pending:
            return "Going live...";
        case CallActionStatus.Error:
            return "Failed to go live.";
        case CallActionStatus.Backoff:
            return "Failed to go live. Retrying...";
    }
};

export interface BondLiveInteractionProps {
    callId: Optional<CallId>;
    displayParticipants?: DisplayMediaParticipant[];
    expandable?: boolean;
    participantsRef?: LegacyRef<HTMLDivElement>;
}

export const BondLiveInteraction = (props: BondLiveInteractionProps): React.JSX.Element => {
    const { callId, displayParticipants, expandable, participantsRef } = props;

    const dispatch = useAppDispatch();

    const call = useSelectorArgs(selectCallById, callId);
    const callStartStatus = useSelectorArgs(selectCallStartStatus);
    const callJoinStatus = useSelectorArgs(selectCallJoinStatus);

    const durationStr = useStopwatch(call?.startedAt);

    const openLiveViewWithFocus = (id: Optional<string>) => () => {
        dispatch(openLiveView(id));
    };

    if (!call) {
        const startMsg = getLiveStartMessage(callStartStatus);

        return (
            <>
                {startMsg && (
                    <div className="c-live-message">
                        <div className="c-live-message__content">
                            {startMsg}
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderParticipant = (p: DisplayMediaParticipant) => (
        <div className="c-live-message__participant" key={`live-message-${p.id}`}>
            <ParticipantTile
                key={p.id}
                isSelf={false}
                participantUserId={p.userId}
                videoTrack={p.displayMediaTrack}
                participantType={ParticipantType.Display}
                onClick={openLiveViewWithFocus(getTileId(p))}
            />
        </div>
    );
    return (
        <div className="c-live-message">
            <div className="c-live-message__meta">
                <span className="c-live-message__timestamp">
                    {durationStr}
                </span>
                {expandable && (
                    <button
                        onClick={openLiveViewWithFocus(undefined)}
                        className="cp-btn-call-grid"
                        title="Expand call grid"
                    >
                        Expand
                    </button>
                )}
            </div>
            <div className="c-live-message__content">
                <h3 className="c-live-message__title">
                    <SensitiveText>{call.knowledge.title || "New Live Session"}</SensitiveText>
                </h3>
                {getLiveConnectionMessage(callStartStatus, callJoinStatus)}
                <p className="c-live-message__summary">
                    <SensitiveText>{call.knowledge.summary || ""}</SensitiveText>
                </p>
            </div>
            {(displayParticipants && displayParticipants.length > 0) && (
                <div className="c-live-message__participants" ref={participantsRef}>
                    {displayParticipants.map(renderParticipant)}
                </div>
            )}
        </div>
    );
};
