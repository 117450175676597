import {
    selectBondIds,
    subBondCatchupSummariesThunk,
    subBondContemporariesThunk,
    subBondObserversThunk,
} from "../../features/bonds";
import { subBondMsgSeqNumsThunk } from "../../features/channels";
import StreamManager from "./StreamManager";

function BondStreamsManager(): React.JSX.Element {
    return (
        <>
            <StreamManager
                idSelector={selectBondIds}
                streamThunk={subBondContemporariesThunk}
            />
            <StreamManager
                idSelector={selectBondIds}
                streamThunk={subBondCatchupSummariesThunk}
            />
            <StreamManager
                idSelector={selectBondIds}
                streamThunk={subBondMsgSeqNumsThunk}
            />
            <StreamManager
                idSelector={selectBondIds}
                streamThunk={subBondObserversThunk}
            />
        </>
    );
}

export default BondStreamsManager;
