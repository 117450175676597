import { z } from "zod";

// The UUID type represents a string that is guaranteed to be a valid UUID.
//
// This is achieved by taking the intersection of the string type and a struct
// with a single field which acts as a tag. Because it is impossible to create
// a value of this type (without intentionally casting to it), the only way to
// obtain a value of type d.UUID is to use one of the functions provided here.
// This allows us to check that any provided string is a valid UUID at runtime.

type Opaque<T, Tag> = T & { format: Tag; };

export type UUID = Opaque<string, "UUID">;
const uuidSchema = z.string().uuid();

export function validateUUID(input: string): UUID {
    if (!uuidSchema.safeParse(input).success) {
        throw new Error(`Invalid UUID: '${input}'`);
    }
    return input as UUID;
}

export type RaPID = Opaque<string, "RawPersonId">;
const RegexRawPersonId = /^[a-z_-]+$|^\d+$/;

export function validateRawPersonId(input: string): RaPID {
    if (!RegexRawPersonId.test(input) || input.length == 0) {
        throw new Error(`Invalid RawUserId: '${input}'`);
    }
    return input as RaPID;
}

export type RawRtcSessionId = Opaque<string, "RawRtcSessionId">;
export function validateRawRtcSessionId(input: string): RawRtcSessionId {
    // RTC Session IDs are actually UUIDs, at least for now
    if (!uuidSchema.safeParse(input).success) {
        throw new Error(`Invalid UUID: '${input}'`);
    }
    return input as RawRtcSessionId;
}

export type RawNanoId = Opaque<string, "nanoId">;
const nanoIdSchema = z.string().nanoid().min(1);

export function validateRawNanoId(input: string): RawNanoId {
    if (!nanoIdSchema.safeParse(input).success) {
        throw new Error(`Invalid RawNanoId: '${input}'`);
    }
    return input as RawNanoId;
}

// Explicit URN forms for our IDs, both with strings and UUIDs:

export type Raw = string | number | bigint;
export type Tagged<R extends Raw, T extends string> = `urn:beyond:${T}:${R}`;

function extractRaw<R extends Raw>(validator: (input: string) => R) {
    return (input: Tagged<R, any>): R => {
        return validator(input.split(":")[3]);
    };
}

export const extractUUID = extractRaw(validateUUID);
export const extractRawPersonId = extractRaw(validateRawPersonId);
export const extractRawRtcSessionId = extractRaw(validateRawRtcSessionId);
