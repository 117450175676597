import { useEffect, useMemo } from "react";
import { selectIdbUpgradeRequired, selectShowSidebar } from "../features/meta";
import { useAppSelector } from "../store/redux";
import log from "../misc/log";
import { Outlet, useLocation } from "react-router-dom";
import { useSelfInterest } from "../hooks/interest/useInterestedUsers";
import { isMobileBrowser } from "../misc/mobile";
import NotificationFeed from "../components/NotificationFeed";
import NativeNotificationManager from "../components/managers/NativeNotificationManager";
import { AuthController } from "../components/AuthController";
import Header from "../components/Header";
import { SentryReportDialog } from "../components/SentryReportButton";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { getRouter } from "../misc/router";
import { isDevEnv } from "../misc/environment";
import { isNativePlatform } from "../misc/capacitor";
import { Footer } from "../components/Footer";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { SidebarView } from "./SidebarView";
import classNames from "classnames";
import { mobilePathToViewName } from "../misc/mobilePathToTab";

export const BeyondRedirectingToLogin = () => {
    const { error, activeNavigator, isLoading, isAuthenticated } = useAuth();

    const errorMsg = error?.message;

    const authLog = useMemo(() => ({
        error: errorMsg,
        activeNavigator,
        isLoading,
        isAuthenticated,
    }), [errorMsg, activeNavigator, isLoading, isAuthenticated]);

    useEffect(() => {
        if (!isLoading && error) {
            log.info(`Auth error, redirecting to /logout`, authLog);
            setTimeout(() => getRouter().navigate("/logout", { replace: true }), 0);
        }
    }, [isLoading, error, authLog]);

    return <></>;
};

function IDBUpgrader(): React.JSX.Element {
    // There's probably a better place to put this, but I've put
    // it here for lack of imagination of where that place is.
    const idbUpgradeNeeded = useAppSelector(selectIdbUpgradeRequired);
    useEffect(() => {
        if (idbUpgradeNeeded) {
            log.warn("IndexedDB upgrade required, about to reload page");
            new Promise(r => setTimeout(r, 1000)).then(() => location.reload());
        }
    }, [idbUpgradeNeeded]);

    return <></>;
}

function MobileViewHackery(): React.JSX.Element {
    const location = useLocation();

    useEffect(() => {
        if (!location) return;

        const cl = document.body.classList;

        if (/^[/]bond[/]?$/.test(location.pathname)) {
            cl.add("m-bonds");
            cl.remove("m-bond");
        }
        else if (/^[/]bond[/].+/.test(location.pathname)) {
            cl.remove("m-bonds");
            cl.add("m-bond");
        }
        else if (/^[/]settings/.test(location.pathname)) {
            cl.remove("m-aside");
        }
    }, [location]);

    return <></>;
}

function MainContainer({ children }: { children: React.ReactNode; }) {
    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");
    const sidebarToggleEnabled = useAppSelector(selectShowSidebar);
    const showSidebar = phase3UIEnabled && !isMobileBrowser() && sidebarToggleEnabled;
    const isMobile = isMobileBrowser();
    const { pathname } = useLocation();

    const className = classNames({
        "l-main-desktop": !isMobile,
        "l-main-mobile": isMobile,
        "l-main-mobile--groups": isMobile && mobilePathToViewName(pathname) === "mysquads",
        "l-main-mobile--bond": isMobile && mobilePathToViewName(pathname) === "bond",
    });

    return (
        <main className={className}>
            {showSidebar && <SidebarView />}
            {children}
        </main>
    );
}

function RootView(): React.JSX.Element {
    useSelfInterest();

    return (
        <>
            {isMobileBrowser() && <MobileViewHackery />}
            {!isNativePlatform && <NotificationFeed />}
            <NativeNotificationManager />
            <AuthController />
            <Header />
            <IDBUpgrader />
            <SentryReportDialog />
            <MainContainer>
                <Outlet />
            </MainContainer>
            <Footer />
        </>
    );
}

export const AuthenticatedRootView = withAuthenticationRequired(RootView, {
    OnRedirecting: () => <BeyondRedirectingToLogin />,
    signinRedirectArgs: isDevEnv ? { prompt: "login" }
        : undefined,
});
