import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import useLoadMoreOnScroll from "../hooks/useLoadMoreOnScroll";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { selectBondById, selectCardsViewScrollTop, setCardsViewScrollTop } from "../features/bonds";
import * as d from "../domain/domain";
import { useNavigate } from "react-router-dom";
import useAddressParams from "../hooks/useAddressParams";
import useSelectorArgs from "../hooks/useSelectorArgs";
import {
    Phase3FilterOptions,
    selectBondIdsForBondList,
    selectPhase3Filter,
    setPhase3Filter,
} from "../features/filterPanel";
import { BondViewStreamSubscriptions } from "./BondView";
import { isMobileBrowser } from "../misc/mobile";
import classNames from "classnames";
import PresenceHeader from "../components/PresenceHeader";
import { FilterBondsAndPresenceHeader } from "../components/FilterButtonAndPresenceHeader";
import { BondCard } from "../components/BondCard";
import useOutsideClick from "../hooks/useOutsideClick";
import useSwipeDown from "../hooks/useSwipeDown";
import { markSquadRead, selectSquadIsUnread } from "../features/channels";

const initialNumberOfBondsShown = 36;
const incrementNumberOfBondsShown = 18;

function limitBondIds(bondOverviews: d.BondId[], cardLimit: number) {
    return {
        bondIds: bondOverviews.slice(0, cardLimit),
        isLimited: bondOverviews.length > cardLimit,
    };
}

export default function BondsListView(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [cardLimit, setCardLimit] = useState(initialNumberOfBondsShown);
    const endDivRef = useRef<HTMLDivElement>(null);
    const { squadId } = useAddressParams();
    const allBondIds = useSelectorArgs(selectBondIdsForBondList, squadId);
    const { bondIds, isLimited } = limitBondIds(allBondIds, cardLimit);

    const loadMore = useCallback(() => {
        if (isLimited) {
            setCardLimit(x => x + incrementNumberOfBondsShown);
        }
    }, [isLimited, setCardLimit]);
    const loadMoreOnScroll = useLoadMoreOnScroll(endDivRef, loadMore);
    const squadIsUnread = useSelectorArgs(selectSquadIsUnread, squadId);
    const topBond = useSelectorArgs(selectBondById, bondIds[0]);

    // Keep the squad marked as read while we have this view open
    useEffect(() => {
        if (!squadId) return;

        if (squadIsUnread && topBond?.lastActivityAt) {
            dispatch(markSquadRead({
                squadId,
                ts: topBond?.lastActivityAt,
            }));
        }
    }, [dispatch, squadId, squadIsUnread, topBond?.lastActivityAt]);

    // XXX: "selectCardsViewScrollTop" -- naming off, it's a list view in V3
    const savedScrollPosition = useAppSelector(selectCardsViewScrollTop);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const current = scrollableAreaRef.current;
        return () => {
            dispatch(setCardsViewScrollTop(current?.scrollTop ?? 0));
        };
    }, [dispatch]);
    useLayoutEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current?.scrollTo({
                top: savedScrollPosition,
                behavior: "instant",
            });
        }
    }, [savedScrollPosition]);

    const clickCallback = useCallback((id: d.BondId) => navigate(`/bond/${d.extractUUID(id)}`), [
        navigate,
    ]);

    const isMobile = isMobileBrowser();
    const topDivClassName = classNames({
        "c-section c-section--mybonds": !isMobile,
    });

    return (
        <>
            <BondViewStreamSubscriptions />
            <div
                onScroll={loadMoreOnScroll}
                ref={scrollableAreaRef}
                className={topDivClassName}
            >
                {isMobile ? <PresenceHeader /> : <FilterBondsAndPresenceHeader />}
                <CardContainer endDivRef={endDivRef}>
                    {bondIds.map((id, idx) => (
                        <BondCard
                            key={id}
                            id={id}
                            onClick={clickCallback}
                            isFirstCard={idx === 0}
                            isLastCard={idx === bondIds.length - 1}
                        />
                    ))}
                </CardContainer>
            </div>
        </>
    );
}

interface MobileFiltersProps {
    isVisible: boolean;
}

const MobileFilters = (props: MobileFiltersProps): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const filter = useAppSelector(selectPhase3Filter);
    const allButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "all",
    });
    const unreadButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "unread",
    });
    const dismissedButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "dismissed",
    });

    const handleFilterChange = (newFilter: Phase3FilterOptions) => {
        dispatch(setPhase3Filter(newFilter));
    };

    if (!props.isVisible) return <></>;

    return (
        <div className="c-bond-actions">
            <div className="c-bond-filters">
                <button
                    className={allButtonClassName}
                    onClick={() => handleFilterChange("all")}
                >
                    All
                </button>
                <button
                    className={unreadButtonClassName}
                    onClick={() => handleFilterChange("unread")}
                >
                    Unread
                </button>
                <button
                    className={dismissedButtonClassName}
                    onClick={() => handleFilterChange("dismissed")}
                >
                    Dismissed
                </button>
            </div>
        </div>
    );
};

interface CardContainerProps {
    children: React.ReactNode;
    endDivRef: React.RefObject<HTMLDivElement>;
}

const CardContainer = (props: CardContainerProps): React.JSX.Element => {
    const { children, endDivRef } = props;

    const [areFiltersVisible, setAreFiltersVisible] = useState(false);

    const [containerRef] = useOutsideClick<HTMLDivElement>(
        () => setAreFiltersVisible(false),
        areFiltersVisible,
    );
    useSwipeDown(containerRef, () => setAreFiltersVisible(true));

    const isMobile = isMobileBrowser();
    const cardsWrapperClassName = classNames({
        "c-cards-wrapper": isMobile,
        "c-cards-wrapper c-cards-wrapper--desktop": !isMobile,
    });

    return (
        <div ref={containerRef}>
            <div className={cardsWrapperClassName}>
                {isMobile && <MobileFilters isVisible={areFiltersVisible} />}
                <div className="c-cards">
                    {children}
                </div>
                <div ref={endDivRef} />
            </div>
        </div>
    );
};
