import { useOutletContext } from "react-router-dom";
import { PropsWithChildren, useCallback } from "react";

import * as d from "../domain/domain";
import { useAppDispatch } from "../store/redux";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import { inviteUserToBondThunk, selectAllBondInvolvees, selectBondById } from "../features/bonds";
import log from "../misc/log";

import UserRow from "./gui/UserRow";
import { CloseButton } from "./buttons/Close";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { useShallowEquals } from "../hooks/useShallowEquals";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import { useNavigateBack } from "../hooks/useNavigateBack";

interface UserSectionProps {
    title: string;
    n: number;
}
export const UserSection = (props: PropsWithChildren<UserSectionProps>) => (
    <>
        <header className="c-dialog__header">
            <h1 className="c-dialog__title">{props.title}</h1>
            <span className="c-dialog__count">
                ({props.n})
            </span>
        </header>

        <div className="c-dialog__content c-dialog__content--interested">
            {props.children}
        </div>
    </>
);

export default function BondInterestedModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });

    const bondOverview = useSelectorArgs(selectBondById, bondId);

    const dialogRef = useDialogOpenRef();

    const closeModal = useCallback(() => {
        log.debug("Closing modal");
        navigateBack();
    }, [navigateBack]);

    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const addUser = useCallback((userId: d.UserId) => {
        log.info("Adding user: ", userId);

        dispatch(
            inviteUserToBondThunk({
                bondId,
                invitedUserId: userId,
            }),
        );
    }, [dispatch, bondId]);

    const allBondInvolvees = useSelectorArgs(selectAllBondInvolvees, bondId);
    const followers = useShallowEquals(bondOverview?.followers || []);
    const canFollow = allBondInvolvees.filter(u => !followers.includes(u));

    useInterestedUsers(followers);
    useInterestedUsers(canFollow);

    return (
        <dialog
            className="c-dialog"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <CloseButton
                side="right"
                onClick={closeModal}
                data-testid={`${bondOverview?.id}-user-modal-close`}
            />

            <article className="c-dialog__content-wrapper">
                {followers.length > 0 && (
                    <UserSection
                        title="Following"
                        n={followers.length}
                    >
                        {followers.map(c => <UserRow userId={c} key={c} />)}
                    </UserSection>
                )}

                {canFollow.length > 0 && (
                    <UserSection
                        title="Can follow"
                        n={canFollow.length}
                    >
                        {canFollow.map(c => (
                            <UserRow userId={c} key={c} buttonText="Add" onButtonClick={addUser} />
                        ))}
                    </UserSection>
                )}

                {followers.length === 0 && canFollow.length === 0 && (
                    <div className="c-dialog__empty-state">
                        You are the only user in this bond.
                    </div>
                )}
            </article>
        </dialog>
    );
}
