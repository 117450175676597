import omit from "lodash.omit";
import {
    ChangeEventHandler,
    ForwardedRef,
    forwardRef,
    HTMLProps,
    MouseEventHandler,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { Setter } from "../../misc/types";
import { setOnChange } from "../../misc/utils";

type AutoExpandInputProps = Omit<HTMLProps<HTMLInputElement>, "ref" | "className" | "type">;

export const AutoExpandInput = forwardRef((
    props: AutoExpandInputProps,
    ref: ForwardedRef<Setter<string>>,
) => {
    const {
        onMouseDown,
        onMouseUp,
        onChange,
        placeholder,
        defaultValue,
        style,
    } = props;
    const inputProps = omit(props, "onMouseDown", "onMouseUp", "onChange", "style");

    const textRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const selectAllRef = useRef<boolean>(false);

    const [value, setValue] = useState(defaultValue?.toString() ?? "");
    const renderedValue = (value == "") ? placeholder : value;

    const onInputMouseDown: MouseEventHandler<HTMLInputElement> = useCallback(e => {
        selectAllRef.current = document.activeElement !== inputRef.current;

        if (selectAllRef.current) {
            inputRef.current?.select();
        }

        onMouseDown?.(e);
    }, [onMouseDown]);

    const onInputMouseUp: MouseEventHandler<HTMLInputElement> = useCallback(e => {
        if (selectAllRef.current) {
            e.preventDefault();
        }

        selectAllRef.current = false;

        onMouseUp?.(e);
    }, [onMouseUp]);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        setOnChange(setValue)(e);
        onChange?.(e);
    }, [onChange]);

    useImperativeHandle(ref, () => ({
        setValue: value => {
            if (inputRef.current !== null) {
                inputRef.current.value = value;
            }
        },
    }), []);

    return (
        <div className="c-autoexpand" style={style}>
            <span
                ref={textRef}
                className="c-autoexpand__text"
                aria-hidden="true"
            >
                {renderedValue}
            </span>
            <input
                ref={inputRef}
                className="c-autoexpand__input"
                type="text"
                onMouseDown={onInputMouseDown}
                onMouseUp={onInputMouseUp}
                onChange={onInputChange}
                {...inputProps}
            />
        </div>
    );
});

export default AutoExpandInput;
