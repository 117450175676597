import classnames from "classnames";
import React, { memo, useCallback, useMemo, useRef } from "react";

import { PrivacyLevel } from "../api/bonds";
import BondCardAvatars from "../components/BondCardAvatars";
import SwitchWithDelay, { ButtonContentComponent } from "../components/buttons/SwitchWithDelay";
import SensitiveText from "../components/gui/SensitiveText";
import TimeAgo from "../components/gui/TimeAgo";
import * as d from "../domain/domain";
import {
    archiveBond,
    followBond,
    selectBondById,
    selectBondIsArchived,
    selectBondTitle,
    selectBondSummary,
    selectIsBondLive,
    selectChannelIdByBondId,
    selectBondIsFollower,
} from "../features/bonds";
import { selectSquadById } from "../features/squads";
import { useAppDispatch, useAppSelector } from "../store/redux";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { MouseEvents, Optional } from "../misc/types";
import { isMobileBrowser } from "../misc/mobile";
import ExpandedTriggerSwitch from "../components/buttons/ExpandedTriggerSwitch";
import { FeatureFlagged } from "../components/FeatureFlags";
import { selectIsRead, updateStagedSequenceNumberToLocalMax } from "../features/channels";
import ActionButton from "../components/buttons/ActionButton";
import ScrollDetector from "../components/gui/ScrollDetector";
import useInterestedSquads from "../hooks/interest/useInterestedSquads";
import useScrollDetector from "../hooks/useScrollDetector";

type MEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;

export interface BondPreviewViewProps {
    id: d.BondId;
    score?: number;
    onClick?: (id: d.BondId, channelId: Optional<d.ChannelId>) => void;
    bondType?: string;
    index?: number;
    cardListLength?: number;
}

const sigmoid = (x: number) => 1 / (1 + Math.exp(-x));
const scoreTransform = (x: number) => (sigmoid((x - 0.5) * 15));

function BondPreviewViewPhase2(props: BondPreviewViewProps): React.JSX.Element {
    const { id, onClick } = props;
    const dispatch = useAppDispatch();
    const channelId = useAppSelector(selectChannelIdByBondId(id));

    const partialBondOverview = useSelectorArgs(selectBondById, id);
    const isLive = useSelectorArgs(selectIsBondLive, id);

    const squadId = partialBondOverview?.squadIds[0];
    const squad = useSelectorArgs(selectSquadById, squadId);
    useInterestedSquads(squadId);

    const bondTitle = useSelectorArgs(selectBondTitle, id);
    const bondSummary = useSelectorArgs(selectBondSummary, id) || "";

    const isArchived = useSelectorArgs(selectBondIsArchived, id);
    const isFollowing = useSelectorArgs(selectBondIsFollower, id);
    const isRead = useSelectorArgs(selectIsRead, channelId);

    const { isScrolled, handleScroll } = useScrollDetector();

    const archiveAction = useCallback((shouldArchive: boolean) => {
        dispatch(
            archiveBond({
                bondId: id,
                archive: shouldArchive,
                channelId: partialBondOverview?.channelId,
            }),
        );
    }, [dispatch, id, partialBondOverview?.channelId]);

    const catchUpAction = useCallback(() => {
        if (!channelId) {
            return;
        }

        dispatch(
            updateStagedSequenceNumberToLocalMax(channelId),
        );
    }, [dispatch, channelId]);

    const skewedScore = useMemo(() => props.score && scoreTransform(props.score), [props.score]);

    const mobile = isMobileBrowser();
    const readButtonRef = useRef<MouseEvents>(null);
    const followSwitchRef = useRef<MouseEvents>(null);
    const mouseEnter = useCallback((e: MEvent) => {
        if (!mobile) {
            e.stopPropagation();
            readButtonRef.current?.onMouseEnter?.(e);
            followSwitchRef.current?.onMouseEnter?.(e);
        }
    }, [mobile]);
    const mouseLeave = useCallback((e: MEvent) => {
        if (!mobile) {
            e.stopPropagation();
            readButtonRef.current?.onMouseLeave?.(e);
            followSwitchRef.current?.onMouseLeave?.(e);
        }
    }, [mobile]);

    const followAction = useCallback((shouldFollow: boolean) => {
        dispatch(followBond({ bondId: id, follow: shouldFollow }));
    }, [dispatch, id]);

    const followSwitchProps = useMemo(() => ({
        className: "cp-btn-bond cp-btn-bond--follow",
        action: followAction,
        switchValue: isFollowing,
        Content: ButtonContentComponent,
        trueState: {
            text: "Unfollow",
            togglingText: "Cancel unfollow",
            extraClassNames: "is-selected",
        },
        falseState: {
            text: "Follow",
            togglingText: "Undo",
        },
    }), [followAction, isFollowing]);

    const readButtonProps = useMemo(() => ({
        className: "cp-btn-bond cp-btn-bond--done",
        action: catchUpAction,
        switchValue: isRead,
        showWhen: false,
        falseState: {
            titleText: "Caught up",
            buttonText: "Caught up",
        },
    }), [catchUpAction, isRead]);

    const archiveButton = !isArchived ? (
        <button
            className="cp-btn-dismiss"
            title="Dismiss"
            onClick={e => {
                e.stopPropagation();
                archiveAction(true);
            }}
        >
            Dismiss
        </button>
    ) : (
        <button
            className="cp-btn-restore"
            title="Restore"
            onClick={e => {
                e.stopPropagation();
                archiveAction(false);
            }}
        >
            Restore
        </button>
    );

    const cardClasses = classnames("cp-card", {
        "is-live": isLive,
        "is-caughtup": isRead,
        "cp-card--mobile": mobile,
    });

    if (!partialBondOverview) {
        return <h1>No bond preview for {id}</h1>;
    }

    const squadName = partialBondOverview.privacy == PrivacyLevel.PRIVATE ?
        "Private" : (squad ? squad.name : `squad:${squadId}`);

    const cardFooterClasses = classnames("cp-card__footer", {
        "cp-card__footer--live": isLive,
        "cp-card__footer--scrolled": isScrolled && !isLive,
        "cp-card__footer--live-scrolled": isLive && isScrolled,
    });

    return (
        <div
            className={cardClasses}
            onClick={() => onClick?.(id, channelId)}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <header className="cp-card__header">
                {archiveButton}
                <div className="cp-pill cp-pill--squad" title={squadName}>{squadName}</div>
                <div className="cp-card__options">
                    <div className="cp-pill">
                        <TimeAgo
                            from={partialBondOverview.lastActivityAt || 0}
                            live={true}
                        />
                    </div>
                    <div className="cp-card__buttons">
                        {mobile ? (
                            <>
                                <SwitchWithDelay
                                    {...followSwitchProps}
                                />
                                <ActionButton
                                    {...readButtonProps}
                                />
                            </>
                        ) : (
                            <>
                                <ExpandedTriggerSwitch
                                    {...followSwitchProps}
                                    ref={followSwitchRef}
                                />
                                <ActionButton
                                    {...readButtonProps}
                                />
                            </>
                        )}
                    </div>
                </div>
            </header>
            <div className="cp-card__content">
                <h3
                    className={"cp-card__title u-clamp_2" +
                        (bondTitle.aiGenerated ? " c-ai-generated-title" : "")}
                    title={bondTitle.title}
                >
                    <SensitiveText>{bondTitle.title}</SensitiveText>
                </h3>
                {partialBondOverview.knowledge.summary && (
                    <FeatureFlagged
                        flag="display-catchup-summaries"
                        match={true}
                        fallback={
                            <p className="cp-card__summary">
                                <SensitiveText>
                                    {partialBondOverview.knowledge.summary}
                                </SensitiveText>
                            </p>
                        }
                    >
                        <p className="cp-card__summary">
                            <SensitiveText>{bondSummary}</SensitiveText>
                        </p>
                    </FeatureFlagged>
                )}
            </div>
            <footer className={cardFooterClasses}>
                <ScrollDetector
                    onScroll={handleScroll}
                    className="cp-card__footer--wrapper"
                >
                    <BondCardAvatars bondId={id} />
                </ScrollDetector>
            </footer>
            {skewedScore !== undefined && (
                <div className="c-bondscore">
                    <div
                        className="c-bondscore__bar"
                        style={{
                            width: `${skewedScore * 100}%`,
                            backgroundColor: `hsl(${skewedScore * 120}, 70%, 50%)`,
                        }}
                    >
                        {(skewedScore * 100).toFixed(0) + "%"}
                    </div>
                </div>
            )}
        </div>
    );
}

const BondPreviewView = memo(BondPreviewViewPhase2);

export default BondPreviewView;
