import { useCallback, useState } from "react";

/** A wrapper around the state and callback required for the ScrollDetector component.
 *
 * @param isInitiallyScrolled (optional), default false.
 * @returns
 * - isScrolled: boolean tracking whether component is scrolled, i.e. not at start/top of scroll.
 * - handleScroll: to be given to ScrollDetector as onScroll.
 */
export default function useScrollDetector(isInitiallyScrolled?: boolean) {
    const [isScrolled, setIsScrolled] = useState(!!isInitiallyScrolled);
    const handleScroll = useCallback((scrolled: boolean) => setIsScrolled(scrolled), []);
    return { isScrolled, handleScroll };
}
