import { usePostHog } from "posthog-js/react";
import { FeatureFlagId } from "../../gen/features/features";
import { useEffect, useState } from "react";

function featureFlagToBoolean(val: boolean | string | undefined): boolean {
    if (typeof val === "boolean") {
        return val;
    }
    return false;
}

/* Similar to the <FeatureFlagged /> component in that it checks a feature flag and
 * ensures it is ready on first render. But just a hook returning a boolean rather
 * than a component wrapper.
 */
export const useBooleanFeatureFlag = (flag: FeatureFlagId): boolean => {
    const client = usePostHog();
    const [value, setValue] = useState<boolean>(featureFlagToBoolean(client.getFeatureFlag(flag)));

    useEffect(() => {
        return client.onFeatureFlags(flags => {
            setValue(flags.find(f => f === flag) !== undefined);
        });
    }, [client, flag]);

    return value;
};

export default useBooleanFeatureFlag;
