import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BondCreationBar from "../components/BondCreationBar";
import PresenceHeader from "../components/PresenceHeader";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { transferDraftThunk } from "../features/bondCreation";
import { selectCardsViewScrollTop, setCardsViewScrollTop } from "../features/bonds";
import {
    selectCurrentBondIds,
    selectFollowedBondIds,
    selectPrivateOrSquadFilter,
    selectRelevanceLevelFilter,
    selectUnfollowedBondIds,
} from "../features/filterPanel";
import useLoadMoreOnScroll from "../hooks/useLoadMoreOnScroll";
import log from "../misc/log";
import { useAppDispatch, useAppSelector } from "../store/redux";
import BondPreviewView from "./BondPreviewView";
import { BondViewStreamSubscriptions } from "./BondView";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import classNames from "classnames";
import { isMobileBrowser } from "../misc/mobile";
import { FilterBondsAndPresenceHeader } from "../components/FilterButtonAndPresenceHeader";
import { Optional } from "../misc/types";

const initialNumberOfBondsShown = 36;
const incrementNumberOfBondsShown = 18;

function limitBondIds(
    followedInput: d.BondId[],
    unfollowedInput: d.BondId[],
    fullInput: d.BondId[],
    cardLimit: number,
) {
    const followedBondIds = followedInput.slice(0, cardLimit);
    const unfollowedBondIds = unfollowedInput.slice(
        0,
        cardLimit - followedBondIds.length,
    );

    const allBondIds = fullInput.slice(0, cardLimit);

    const isLimited = followedInput.length + unfollowedInput.length > cardLimit ||
        fullInput.length > cardLimit;
    return { followedBondIds, unfollowedBondIds, allBondIds, isLimited };
}

function BondsCardView(): React.JSX.Element {
    const navigate = useNavigate();
    const [cardLimit, setCardLimit] = useState(initialNumberOfBondsShown);
    const endDivRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const unlimitedCurrentBondIds = useAppSelector(selectCurrentBondIds);
    const unlimitedFollowedBondIds = useAppSelector(selectFollowedBondIds);
    const unlimitedUnfollowedBondIds = useAppSelector(selectUnfollowedBondIds);

    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");

    const { followedBondIds, unfollowedBondIds, allBondIds, isLimited } = useMemo(
        () =>
            limitBondIds(
                unlimitedFollowedBondIds,
                unlimitedUnfollowedBondIds,
                unlimitedCurrentBondIds,
                cardLimit,
            ),
        [
            unlimitedFollowedBondIds,
            unlimitedUnfollowedBondIds,
            unlimitedCurrentBondIds,
            cardLimit,
        ],
    );
    const relevanceLevelFilter = useAppSelector(selectRelevanceLevelFilter);
    const privateOrSquadFilter = useAppSelector(selectPrivateOrSquadFilter);

    const loadMore = useCallback(() => {
        if (isLimited) {
            setCardLimit(x => x + incrementNumberOfBondsShown);
        }
    }, [isLimited, setCardLimit]);
    const loadMoreOnScroll = useLoadMoreOnScroll(endDivRef, loadMore);

    const savedScrollPosition = useAppSelector(selectCardsViewScrollTop);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const current = scrollableAreaRef.current;
        return () => {
            dispatch(setCardsViewScrollTop(current?.scrollTop ?? 0));
        };
    }, [dispatch]);
    useLayoutEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current?.scrollTo({
                top: savedScrollPosition,
                behavior: "instant",
            });
        }
    }, [savedScrollPosition]);

    const clickCallback = useCallback((id: d.BondId, channelId: Optional<d.ChannelId>) => {
        if (!channelId) {
            log.warn(`Cannot transfer into bond ${id} with no channel`);
            return;
        }

        dispatch(
            transferDraftThunk({
                from: bondCreationDraftTarget,
                to: newChannelDraftTarget(channelId),
            }),
        ).then(() => navigate(`/bond/${d.extractUUID(id)}`));
    }, [
        navigate,
        dispatch,
    ]);

    const bondIdsToBondPreviews = (ids: d.BondId[]) =>
        ids.map((id, idx) => (
            <BondPreviewView
                key={id}
                id={id}
                onClick={clickCallback}
                index={idx}
                cardListLength={ids.length}
            />
        ));

    const viewingDoneBucket = relevanceLevelFilter.by === "archived";

    const followingBonds = <div className="cp-cards">{bondIdsToBondPreviews(followedBondIds)}</div>;

    const discoverBonds = (
        <>
            <h2 className="c-cards-title c-cards-title--sticky-discover">Discover</h2>
            <div className="cp-cards">{bondIdsToBondPreviews(unfollowedBondIds)}</div>
        </>
    );

    const followedAndUnfollowedBonds = (
        <div className="cp-cards">{bondIdsToBondPreviews(allBondIds)}</div>
    );

    const displayedBondsAsCards = viewingDoneBucket ? followedAndUnfollowedBonds : (
        <>
            {followingBonds}
            {unfollowedBondIds.length > 0 && discoverBonds}
        </>
    );

    let bondCards = displayedBondsAsCards;

    if (phase3UIEnabled) {
        // FIXME: remove this -- phase3 stuff doesn't need to be supported in here anymore
        // Ignores suggestions for now. TBD.
        const isMyBonds = privateOrSquadFilter.by === "option";
        if (isMyBonds) {
            bondCards = <>{bondIdsToBondPreviews(followedBondIds)}</>;
        }
        else {
            bondCards = <>{bondIdsToBondPreviews(allBondIds)}</>;
        }

        const isMobile = isMobileBrowser();
        const topDivClassName = classNames({
            "c-section c-section--mybonds": !isMobile,
        });

        return (
            <>
                <BondViewStreamSubscriptions />
                <div
                    onScroll={loadMoreOnScroll}
                    ref={scrollableAreaRef}
                    className={topDivClassName}
                >
                    {isMobile ? <PresenceHeader /> : <FilterBondsAndPresenceHeader />}
                    <div className="c-cards-wrapper c-cards-wrapper--desktop">
                        <div className="c-cards">{bondCards}</div>
                        <div ref={endDivRef} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <BondViewStreamSubscriptions />
            <main
                className={`l-main l-main--card-view`}
                onScroll={loadMoreOnScroll}
                ref={scrollableAreaRef}
            >
                {!viewingDoneBucket && <PresenceHeader />}
                <div className="cp-cards-wrapper">
                    {bondCards}
                    <div ref={endDivRef} />
                </div>
            </main>

            <BondCreationBar />
        </>
    );
}

export default BondsCardView;
