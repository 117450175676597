import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import {
    completeAttachment,
    createAttachment,
    flattenMsgAndAttachments,
    flattenOfficialMessageAndAttachments,
    getAttachmentBlobUrl,
    GetAttachmentBlobUrlArgs,
    OfficialMessageAndAttachments,
    OfficialMessagesBundle,
    sendMessageViaBond,
    streamMessages,
} from "../api/chats";
import {
    AnyLocalAttachment,
    attachmentHasBackoffState,
    CompletableAttachment,
    completableToUploadedAttachment,
    FailedAttachment,
    isCompletableAttachment,
    isCredentialedAttachment,
    isProposedAttachment,
    isUploadedAttachment,
    OfficialAttachment,
    ProposedAttachment,
    proposedToCredentialedAttachment,
    UploadedAttachment,
} from "../domain/attachments";
import { defaultBackoffUpdater } from "../domain/backoff";
import { ExpiringSasUrl } from "../domain/blobs";
import {
    draftTargetsAreEqual,
    getChannelOfDraftTarget,
    isChannelDraftTarget,
} from "../domain/channels";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    isOfficialChatMessage,
    UnsentChatMessage,
} from "../domain/messages";
import * as d from "../domain/domain";
import * as migrateV14 from "../domain/migrate/v14";
import log from "../misc/log";
import { ExpandType, Optional, optionalFriendly, partialRecord, TypedEntries } from "../misc/types";
import { idbUpdateParser } from "../persist/idb";
import { checkPersistor, fetchIdbStore, idbStore } from "../persist/shared";
import type {
    Connection,
    IdbStoreUpdate,
    PersistenceUpdate,
    RWTransaction,
} from "../persist/types";
import { createAppAsyncThunk, createAppSelector } from "../store/redux";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { clearDraftThunk, transferDraftThunk } from "./bondCreation";
import { selectBondIdByChannelId } from "./bonds";
import {
    addAttachmentsToDraft,
    catchupMessages,
    clearAttachmentsFromDraft,
    stageMessageForChannel,
} from "./channels";
import { memoizeOptions } from "./selectors";
import { streamThunkHandlerBatched, unaryThunkHandler } from "./thunk";

/** Sending messages
 *
 * 1. The user writes out a message in the `MessageComposer`. This forms a
 * `DraftChatMessage` in the store. The user then presses send.
 *
 * 2. A few extra pieces of metadata are added, creating an `UnsentChatMessage`.
 *
 * 3. `stageMessageForChannel` is dispatched, which "stages" the
 * `UnsentChatMessage`, adding it to:
 *   - `state.messages.unsent`, which has type
 *     `Record<UnsentMessageId, UnsentChatMessage>`. This is the canonical
 *     location for the message and metadata itself.
 *   - a per-channel `curatedList` - this list of ids determines the ordering of
 *     messages shown in the channel. In order to always show something sensible
 *     in the `ChatView`, we must keep referring to our local copy of the
 *     message until we receive the "official" message in a fetch from the
 *     server or from a subscription to the channel.
 *   - a per-channel `unsent` list, a list of ids of all the messages for a
 *     channel that we still need to send to the server.
 *
 * 4. The `MessageSender` component selects all the heads of the per-channel
 * `unsent` lists.
 *
 * 5. It chooses which, if any, is the next one to attempt sending.
 *   - If any messages have not been tried, they can be tried immediately.
 *   - If all messages are waiting to be retried, it delays until we reach the
 *     specified retry interval for the earliest retry opportunity. (If a new
 *     message is sent that changes the collection returned by 4., the wait is
 *     cancelled and we re-evaluate the situation immediately.)
 *   - If all outstanding messages have "permanent errors", it does nothing.
 *
 * This behaviour can obviously be improved, as it is unlikely that failures are
 * independent across channels, and we currently will retry any "temporary"
 * errors every second until we succeed. There's currently no way to initiate a
 * retry for "permanent" errors. We'll also need to use the websocket connection
 * state to determine if we should attempt to send at all.
 *
 * 6. Upon a successful send, `sendMessageThunk.fulfilled` is dispatched, and we
 *    update the `confirmedId` of the unsent message, but keep using our
 *    `localId` to reference the message.
 *
 * 7. If there is an error, it is classified as "temporary" or "permanent", and
 *    the unsent message is updated with that information.
 *
 * 8. Later, if the send was successful, we receive the full message from the
 *    server, and dispatch a `messageStreamed` action. This updates our
 *    references from `UnsentChatMessage.localId` => `ChatMessage.id`, and
 *    removes the copy of the message from `state.messages.unsent`.
 *
 * Still to think about:
 *
 * * edits/deletions whilst still unsent
 *   Probably a case of staging a new message and having logic in the channel
 *   map to reconcile these. The range of cases you have to deal with gets quite
 *   hairy here (e.g. original message send completed but without confirmation,
 *   deletion requested - you have to keep the deletion request around until you
 *   can be sure the original is delivered or not).
 *
 * * fetching older messages, and adding them to the `curatedList`
 *   Management of this is probably best based on pages of messages, with the
 *   `ChatView` stitching together pages of messages that surround the current
 *   messages being viewed. The complication here comes from message edits and
 *   deletions, as well as potentially differing views of the ordering of
 *   messages between client and server.
 *
 * * embeds/attachments
 *   We'll need to think about how uploading of files works around
 *   disconnections.
 */

const messagesAdapter = createEntityAdapter({
    selectId: (msg: AnyOfficialMessage) => msg.id,
});

const unsentMessagesAdapter = createEntityAdapter({
    selectId: (msg: AnyUnsentLocalMessage) => msg.localId,
});

interface StreamMessagesByChannelIdArgs {
    channelId: d.ChannelId;
    startSequenceNumber: number;
}
export const streamMessagesByChannelId = createAppAsyncThunk(
    "messages/stream",
    async ({ channelId, startSequenceNumber }: StreamMessagesByChannelIdArgs, thunkAPI) => {
        if (!channelId) {
            return;
        }

        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            return;
        }

        const req = streamMessages({ userId, channelId, startSequenceNumber }, thunkAPI.signal);

        streamThunkHandlerBatched(
            thunkAPI,
            req,
            50,
            (bundles: OfficialMessageAndAttachments[]) => {
                const flat = flattenOfficialMessageAndAttachments(bundles, channelId);
                thunkAPI.dispatch(streamedMessages(flat));
            },
            `chats user:${userId} channel:${channelId}`,
        );
    },
);

// Check for "messageId" on the returned object to
// know if the send attempt was successful.
export const sendMessageThunk = createAppAsyncThunk(
    "messages/send",
    async (msg: UnsentChatMessage, thunkAPI) => {
        const state = thunkAPI.getState();

        const senderId = selectCurrentUserId(state);
        if (!senderId) {
            log.error(`Unable to send message - no userId`);
            return thunkAPI.rejectWithValue({ error: `sendMessageViaBond requires a userId` });
        }

        if (!isChannelDraftTarget(msg.draftTarget)) {
            log.error(`Unable to send message - draftTarget is for bond creation`);
            return thunkAPI.rejectWithValue({ error: `sendMessageViaBond requires a draftTarget` });
        }

        const channelId = getChannelOfDraftTarget(msg.draftTarget);
        const bondId = selectBondIdByChannelId(state, channelId);
        if (!bondId) {
            log.error(`Unable to send message in channel ${channelId} - no corresponding bond`);
            return thunkAPI.rejectWithValue({ error: "no bond for channel" });
        }

        log.info(`Sending message: ${msg.localId} on ${channelId}`);

        const officialAttachmentIds = selectOfficialAttachmentIdsForMessage(state, msg.localId);

        return await sendMessageViaBond({
            msg,
            bondId,
            channelId,
            senderId,
            officialAttachmentIds,
        });
    },
);

export const getAttachmentCredentialsThunk = createAsyncThunk(
    "messages/attachments/credentials",
    async (attachment: ProposedAttachment, thunkAPI) => {
        const { localId } = attachment;

        const credentials = await unaryThunkHandler(
            thunkAPI,
            createAttachment(attachment),
            `createAttachment ${localId}`,
        );

        return {
            localId,
            credentials,
        };
    },
);

export const completeAttachmentThunk = createAppAsyncThunk(
    "messages/attachments/complete",
    async (attachment: CompletableAttachment, thunkAPI) => {
        const { localId, blobId } = attachment;

        const state = thunkAPI.getState();
        const completerId = selectCurrentUserId(state);

        if (!completerId) {
            return thunkAPI.rejectWithValue(`Cannot complete attachment without a user id`);
        }

        await unaryThunkHandler(
            thunkAPI,
            completeAttachment({ completerId, blobId }),
            `completeAttachment ${localId}`,
        );

        return localId;
    },
);

export const getAttachmentBlobUrlThunk = createAsyncThunk(
    "messages/attachments/getSasUrl",
    async (args: GetAttachmentBlobUrlArgs, thunkAPI) => {
        const expiringSasUrl = await unaryThunkHandler(
            thunkAPI,
            getAttachmentBlobUrl(args),
            `getAttachmentBlobUrl ${args.blobId}`,
        );

        return { expiringSasUrl, blobId: args.blobId };
    },
);

export const deleteAttachmentFromDraftThunk = createAppAsyncThunk(
    "messages/attachments/delete",
    (localId: d.LocalAttachmentId, thunkAPI) => {
        const state = thunkAPI.getState();

        const attachment = selectLocalAttachment(state, localId);
        if (!attachment) {
            return thunkAPI.rejectWithValue({ error: `No local attachment with id ${localId}` });
        }

        const { draftTarget } = attachment;

        return { localId, draftTarget };
    },
);

type MessageUpdate =
    | IdbStoreUpdate<typeof stores.messages>
    | IdbStoreUpdate<typeof stores.unsent>
    | IdbStoreUpdate<typeof stores.attachments>
    | IdbStoreUpdate<typeof stores.localAttachments>;

export interface MessagesState {
    msgs: EntityState<AnyOfficialMessage, d.MessageId>;
    unsent: EntityState<AnyUnsentLocalMessage, d.UnsentMessageId>;
    attachments: Record<d.BlobId, OfficialAttachment>;
    localAttachments: Record<d.LocalAttachmentId, AnyLocalAttachment>;
    updates: MessageUpdate[];
}

type GetInitialStateArgs = ExpandType<
    & Partial<Omit<MessagesState, "msgs" | "unsent" | "updates">>
    & {
        msgs?: Record<d.MessageId, AnyOfficialMessage>;
        unsent?: Record<d.UnsentMessageId, AnyUnsentLocalMessage>;
    }
>;
const getInitialState = (props?: GetInitialStateArgs): MessagesState => ({
    msgs: messagesAdapter.getInitialState({}, props?.msgs ?? {}),
    unsent: unsentMessagesAdapter.getInitialState({}, props?.unsent ?? {}),
    attachments: props?.attachments ?? {},
    localAttachments: props?.localAttachments ?? {},
    updates: [],
});

// The messages slice is intentionally quite dumb - store messages based
// on their id (either "real" id or "local" id), and allow looking up the same.
export const messagesSlice = createSlice({
    name: "messages",
    initialState: getInitialState(),
    selectors: {
        selectAttachment: (state, id: Optional<d.BlobId>) => id && state.attachments[id],
        selectLocalAttachment: (state, id: Optional<d.LocalAttachmentId>) =>
            id ? state.localAttachments[id] : undefined,
        selectLocalAttachments: state => state.localAttachments,
    },
    reducers: {
        streamedMany: (
            state,
            { payload: { msgs, attachments } }: PayloadAction<OfficialMessagesBundle>,
        ) => {
            if (msgs.length > 0 || (attachments && attachments.length > 0)) {
                state.updates = [];
            }

            if (msgs.length > 0) {
                state.msgs = messagesAdapter.upsertMany(state.msgs, msgs);
                state.updates = msgs.map(msg => [stores.messages, msg.id, msg]);
            }

            attachments?.forEach(a => {
                if (state.attachments[a.id]) return;

                state.attachments[a.id] = a;
                state.updates.push([stores.attachments, a.id, a]);
            });

            const haveLocalMessageWithId = (
                id: Optional<d.UnsentMessageId>,
            ): id is d.UnsentMessageId => {
                return !!(id && state.unsent.entities[id]);
            };

            const unsents = msgs
                .filter(isOfficialChatMessage)
                .map(msg => msg.content.id)
                .filter(haveLocalMessageWithId);

            if (unsents.length) {
                state.unsent = unsentMessagesAdapter.removeMany(state.unsent, unsents);
                state.updates = state.updates.concat(unsents.map(id => [stores.unsent, id]));
            }
        },

        testInsertUnsentMessage: (
            state,
            { payload: [_, msg] }: PayloadAction<[d.ChannelId, AnyUnsentLocalMessage]>,
        ) => {
            state.unsent = unsentMessagesAdapter.upsertOne(state.unsent, msg);
        },

        updateLocalAttachment: (
            state,
            { payload: attachment }: PayloadAction<AnyLocalAttachment>,
        ) => {
            const { localId } = attachment;
            state.localAttachments[localId] = attachment;
            state.updates = [[stores.localAttachments, localId, attachment]];
        },

        updateLocalAttachmentBackoff: (
            state,
            { payload: localId }: PayloadAction<d.LocalAttachmentId>,
        ) => {
            const attachment = state.localAttachments[localId];
            if (!attachmentHasBackoffState(attachment)) return;

            const { backoffState: { attempts } } = attachment;
            const backedOff = { ...attachment, backoffState: defaultBackoffUpdater(attempts) };

            state.localAttachments[localId] = backedOff;
            state.updates = [[stores.localAttachments, localId, backedOff]];
        },

        upsertAttachmentBlobUrlsForTest: (
            state,
            { payload: updates }: PayloadAction<
                { expiringSasUrl: ExpiringSasUrl; blobId: d.BlobId; }[]
            >,
        ) => {
            updates.forEach(({ blobId, expiringSasUrl }) => {
                const attachment = state.attachments[blobId];
                if (!attachment || !attachment.credentials) return;

                attachment.credentials = {
                    ...attachment.credentials,
                    ...expiringSasUrl,
                };
            });
        },
    },

    extraReducers: builder => {
        builder.addCase(
            stageMessageForChannel.fulfilled,
            (state, { payload: [_channelId, unsentMsg] }) => {
                // Insertion ("addOne") is correct as the message should already
                // have a unique 'localId'.
                state.unsent = unsentMessagesAdapter.addOne(state.unsent, unsentMsg);
                state.updates = [[stores.unsent, unsentMsg.localId, unsentMsg]];
            },
        );
        builder.addCase(sendMessageThunk.fulfilled, (state, { payload: [_, msg] }) => {
            // We need to keep the unsent version of the message around until we get
            // the message back from the server in, e.g. a subscription.
            // The message here will have `confirmedId` set, so upsert is correct - the
            // original copy without the `confirmedId` will have been inserted previously.
            state.unsent = unsentMessagesAdapter.upsertOne(state.unsent, msg);
            state.updates = [[stores.unsent, msg.localId, msg]];
        });
        builder.addCase(
            catchupMessages.fulfilled,
            (state, { payload: { msgs, attachments } }) => {
                state.msgs = messagesAdapter.upsertMany(state.msgs, msgs);
                state.updates = msgs.map(msg => [stores.messages, msg.id, msg]);

                attachments?.forEach(a => {
                    if (state.attachments[a.id]) return;

                    state.attachments[a.id] = a;
                    state.updates.push([stores.attachments, a.id, a]);
                });
            },
        );

        builder.addCase(
            getAttachmentBlobUrlThunk.fulfilled,
            (state, { payload: { blobId, expiringSasUrl } }) => {
                const attachment = state.attachments[blobId];
                if (!attachment || !attachment.credentials) return;

                attachment.credentials = {
                    ...attachment.credentials,
                    ...expiringSasUrl,
                };

                state.updates = [[stores.attachments, blobId, attachment]];
            },
        );

        builder.addCase(
            getAttachmentCredentialsThunk.fulfilled,
            (state, { payload: { localId, credentials } }) => {
                // draftTarget might change mid-request, so always update the
                // latest version.
                const attachment = state.localAttachments[localId];
                if (!isProposedAttachment(attachment)) return;

                const newAttachment = proposedToCredentialedAttachment(attachment, credentials);
                state.localAttachments[localId] = newAttachment;
                state.updates = [[stores.localAttachments, localId, newAttachment]];
            },
        );

        builder.addCase(
            getAttachmentCredentialsThunk.rejected,
            (state, { meta: { arg: { localId } } }) => {
                // draftTarget might change mid-request, so always update the
                // latest version.
                const attachment = state.localAttachments[localId];
                if (!isProposedAttachment(attachment)) return;

                const { backoffState: { attempts } } = attachment;
                const currentAttachment = state.localAttachments[localId];
                const backedOff = {
                    ...currentAttachment,
                    backoffState: defaultBackoffUpdater(attempts),
                };
                state.localAttachments[localId] = backedOff;
                state.updates = [[stores.localAttachments, localId, backedOff]];
            },
        );

        builder.addCase(
            completeAttachmentThunk.fulfilled,
            (state, { payload: localId }) => {
                const attachment = state.localAttachments[localId];
                if (!isCompletableAttachment(attachment)) return;

                const completedAttachment = completableToUploadedAttachment(attachment);
                state.localAttachments[attachment.localId] = completedAttachment;
                state.updates = [[
                    stores.localAttachments,
                    attachment.localId,
                    completedAttachment,
                ]];
            },
        );

        builder.addCase(
            addAttachmentsToDraft,
            (state, { payload: attachments }) => {
                if (attachments.length === 0) return;

                state.updates = [];

                attachments.forEach(attachment => {
                    state.localAttachments[attachment.localId] = attachment;
                    state.updates.push([stores.localAttachments, attachment.localId, attachment]);
                });
            },
        );

        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId } }) => {
                delete state.localAttachments[localId];
                state.updates = [[stores.localAttachments, localId]];
            },
        );

        builder.addCase(
            clearAttachmentsFromDraft,
            (state, { payload: draftTarget }) => {
                // TODO: consider keeping an index in the other direction?
                // Probably only very few local attachments at any point in time.
                const attachments = Object
                    .values(state.localAttachments)
                    .filter(({ draftTarget: adt }) => draftTargetsAreEqual(draftTarget, adt));

                if (attachments.length === 0) return;

                state.updates = [];

                attachments.forEach(({ localId }) => {
                    delete state.localAttachments[localId];
                    state.updates.push([stores.localAttachments, localId]);
                });
            },
        );

        builder.addCase(
            transferDraftThunk.fulfilled,
            (state, { payload: { to, content: { attachmentIds } } }) => {
                state.updates = [];

                attachmentIds.forEach(localId => {
                    const attachment = state.localAttachments[localId];
                    attachment.draftTarget = to;
                    state.updates.push([
                        stores.localAttachments,
                        localId,
                        attachment,
                    ]);
                });
            },
        );

        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, { payload: { attachments } }) => {
                if (!attachments || attachments.length === 0) return;

                state.updates = [];
                attachments.forEach(({ localId }) => {
                    delete state.localAttachments[localId];
                    state.updates.push([stores.localAttachments, localId]);
                });
            },
        );

        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

const sliceSelectors = messagesSlice.getSelectors<RootState>(state => state.messages);

const msgSelectors = messagesAdapter.getSelectors((state: RootState) => state.messages.msgs);
const unsentMsgSelectors = unsentMessagesAdapter.getSelectors((state: RootState) =>
    state.messages.unsent
);

export const selectUnsentMessages = partialRecord(unsentMsgSelectors.selectEntities);

type SortableAttachment = Exclude<AnyLocalAttachment, UploadedAttachment | FailedAttachment>;
const sortAttachments = (a: SortableAttachment, b: SortableAttachment): number =>
    (a.backoffState.nextAttempt ?? a.initiatedAt) - (b.backoffState.nextAttempt ?? b.initiatedAt);

const selectAllLocalAttachments = createAppSelector(
    [sliceSelectors.selectLocalAttachments],
    attachments => Object.values(attachments),
);

export const selectProposedAttachments = createAppSelector(
    [selectAllLocalAttachments],
    attachments =>
        attachments
            .filter(isProposedAttachment)
            .sort(sortAttachments)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

export const selectCredentialedAttachmentIds = createAppSelector(
    [selectAllLocalAttachments],
    attachments =>
        attachments
            .filter(isCredentialedAttachment)
            .sort(sortAttachments)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

export const selectCompletableAttachmentIds = createAppSelector(
    [selectAllLocalAttachments],
    attachments =>
        attachments
            .filter(isCompletableAttachment)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

const selectOfficialMsg = optionalFriendly(msgSelectors.selectById);
const selectUnsentMsg = optionalFriendly(unsentMsgSelectors.selectById);

export const selectMessage = (state: RootState, id: Optional<d.AnyMessageId>) =>
    selectOfficialMsg(state, id as d.MessageId) ??
        selectUnsentMsg(state, id as d.UnsentMessageId);

export const selectLocalAttachment = sliceSelectors.selectLocalAttachment;

export const selectOfficialAttachmentIdsForMessage = createAppSelector(
    [state => state, selectUnsentMsg],
    (state, msg) =>
        msg?.attachmentIds
            .map(a => selectLocalAttachment(state, a))
            .filter(isUploadedAttachment)
            .map(a => a.blobId) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectAttachment = (state: RootState, id: Optional<d.AnyAttachmentId>) =>
    sliceSelectors.selectAttachment(state, id as d.BlobId) ??
        sliceSelectors.selectLocalAttachment(state, id as d.LocalAttachmentId);

const selectOfficialAttachment = (state: RootState, id: Optional<d.AnyAttachmentId>) =>
    sliceSelectors.selectAttachment(state, id as d.BlobId);
export const selectOfficialAttachments: (
    state: RootState,
    _: d.AnyAttachmentId[],
) => Optional<OfficialAttachment>[] = createAppSelector(
    [
        (state, _) => (id: d.AnyAttachmentId) => selectOfficialAttachment(state, id),
        (_sel, ids?: d.AnyAttachmentId[]) => ids,
    ],
    (attachmentGetter, ids) => {
        if (!ids || ids.length === 0) return [];

        const x = ids.map(id => attachmentGetter(id));
        return x;
    },
    memoizeOptions.weakMapShallow,
);

export const {
    streamedMany: streamedMessages,
    upsertAttachmentBlobUrlsForTest,
    testInsertUnsentMessage,
    updateLocalAttachment,
    updateLocalAttachmentBackoff,
} = messagesSlice.actions;

export const messageStreamed = (msg: AnyOfficialMessage, attachments: OfficialAttachment[] = []) =>
    streamedMessages({ channelId: msg.channelId, msgs: [msg], attachments });

export const reducer = messagesSlice.reducer;

// Persistence.

const stores = {
    _messages1: idbStore<d.MessageId, migrateV14.SentMessage>("messages", 1, 13),
    messages: idbStore<d.MessageId, AnyOfficialMessage>("messages-2", 14),

    _unsent1: idbStore<d.UnsentMessageId, migrateV14.UnsentChatMessage>("unsent-messages", 1, 13),
    unsent: idbStore<d.UnsentMessageId, AnyUnsentLocalMessage>("unsent-messages-2", 14),

    _attachmentUrls: idbStore<d.BlobId, any>("attachment-urls", 1, 13),
    attachments: idbStore<d.BlobId, OfficialAttachment>("attachments", 14),
    localAttachments: idbStore<d.LocalAttachmentId, AnyLocalAttachment>("local-attachments", 14),
};

const persist = (
    previousState: MessagesState,
    currentState: MessagesState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const msgs = await fetchIdbStore(conn, stores.messages);
    const unsent = await fetchIdbStore(conn, stores.unsent);
    const attachments = await fetchIdbStore(conn, stores.attachments);
    const localAttachments = await fetchIdbStore(conn, stores.localAttachments);

    return getInitialState({ msgs, unsent, attachments, localAttachments });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 14) {
        const parser = idbUpdateParser(tx);

        const [oldMsgs, oldUnsentMsgs] = await Promise.all([
            await fetchIdbStore(tx.db, stores._messages1, tx),
            await fetchIdbStore(tx.db, stores._unsent1, tx),
        ]);

        const newMsgs = TypedEntries(oldMsgs)
            .map(migrateV14.translateSentMessage)
            .filter(o => !!o);

        const { msgs, attachments } = flattenMsgAndAttachments(newMsgs, msg => msg.id);

        const newUnsentMsgs = TypedEntries(oldUnsentMsgs)
            .map(migrateV14.translateUnsentMessage)
            .filter(o => !!o);

        const { msgs: unsents, attachments: unsentAttachments } = flattenMsgAndAttachments(
            newUnsentMsgs,
            msg => msg.localId,
        );

        const allAttachments = (attachments ?? []).concat(unsentAttachments ?? []);

        const msgPromises = msgs.map(msg => parser([stores.messages, msg.id, msg]));
        const attachmentPromises = allAttachments.map(a => parser([stores.attachments, a.id, a]));
        const unsentPromises = unsents.map(msg => parser([stores.unsent, msg.localId, msg]));

        await Promise.all(msgPromises.concat(unsentPromises, attachmentPromises));
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<MessagesState>(persistor);
