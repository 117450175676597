import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useSelfInterest } from "../hooks/interest/useInterestedUsers";
import { selectCurrentOrgId } from "../features/auth";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { fetchCurrentPersonMemberships, selectOrg } from "../features/squads";
import { selectCurrentUser } from "../features/users";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { useCallback } from "react";
import { VersionInfo } from "./VersionInfo";
import { SentryReportButton } from "./SentryReportButton";
import { OrgSelectionDropdown } from "./OrgSelectionDropdown";
import classNames from "classnames";

export function SettingsDropdown(): React.JSX.Element {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useSelfInterest();

    const orgId = useAppSelector(selectCurrentOrgId);
    const org = useSelectorArgs(selectOrg, orgId);
    const orgName = org?.personal ? "My Account" : org?.name || "Unknown Org";

    const user = useAppSelector(selectCurrentUser);

    useConnectedEffect(() => {
        dispatch(fetchCurrentPersonMemberships());
    }, [dispatch]);

    const showDialog = useCallback(() => {
        navigate("/bond/settings");
    }, [navigate]);

    const dropDownClasses = classNames("cp-dropdown", {
        "cp-dropdown--right": true,
    });

    return (
        <div className={dropDownClasses}>
            <div className="cp-dropdown__wrapper cp-dropdown-settings">
                <div className="cp-dropdown-settings__section">
                    <strong>{orgName}</strong>
                    {user?.name}
                </div>
                <div className="cp-dropdown-settings__section cp-dropdown-settings__section--version">
                    <VersionInfo />
                </div>
                <div className="cp-dropdown-settings__section has-links">
                    <button
                        type="button"
                        onClick={showDialog}
                        className="c-btn c-btn-report"
                    >
                        Preferences
                    </button>
                    <SentryReportButton />
                </div>
                <OrgSelectionDropdown />
                <div className="cp-dropdown-settings__section has-links">
                    <Link to="/logout">Sign out</Link>
                </div>
            </div>
        </div>
    );
}
