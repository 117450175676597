import { useEffect } from "react";
import TinyGesture from "tinygesture";

export default function useSwipeDown(
    ref: React.RefObject<HTMLDivElement>,
    onSwipeDown: () => void,
) {
    useEffect(() => {
        if (!ref.current) return;

        const swipeDownThreshold = 50; // Pixels to trigger swipe down action
        let startY = 0;

        const gesture = new TinyGesture(ref.current, {
            mouseSupport: true,
        });

        const handlePanStart = () => {
            if (gesture.touchMoveY !== null) {
                startY = gesture.touchMoveY;
            }
        };

        const handlePanMove = () => {
            if (gesture.touchMoveY !== null) {
                const deltaY = gesture.touchMoveY - startY;
                if (deltaY > swipeDownThreshold) {
                    onSwipeDown();
                    startY = gesture.touchMoveY; // Reset to prevent multiple triggers
                }
            }
        };

        gesture.on("panstart", handlePanStart);
        gesture.on("panmove", handlePanMove);

        return () => {
            gesture.destroy();
        };
    }, [ref, onSwipeDown]);
}
