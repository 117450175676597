import { ForwardedRef, forwardRef, useMemo } from "react";
import BeyondTheme from "../quill/themes/BeyondTheme";
import { RichTextEditorOps, RichTextEditorProps } from "./RichTextEditor";
import QuillWrapper from "./gui/QuillWrapper";

export const QuillRichTextEditor = forwardRef((
    props: RichTextEditorProps,
    ref: ForwardedRef<RichTextEditorOps>,
): React.JSX.Element => {
    const {
        markupForInit,
        placeholder,
        tabIndex,
        onChange,
        onSelect,
        onSubmit,
        onEscape,
        onShiftEscape,
        onFocus,
        onBlur,
    } = props;

    const bindings = useMemo(() => ({
        submit: {
            key: "Enter",
            handler: onSubmit,
        },
        shiftEscape: {
            key: "Escape",
            shiftKey: true,
            handler: onShiftEscape,
        },
        escape: {
            key: "Escape",
            handler: onEscape,
        },
    }), [onSubmit, onShiftEscape, onEscape]);

    return (
        <QuillWrapper
            ref={ref}
            bindings={bindings}
            placeholder={placeholder}
            theme={BeyondTheme.NAME}
            maxLength={8000}
            markupForInit={markupForInit}
            tabIndex={tabIndex}
            onChange={onChange}
            onSelect={onSelect}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
});

export default QuillRichTextEditor;
