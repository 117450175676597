import * as d from "../../domain/domain";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { selectUser } from "../../features/users";
import classNames from "classnames";
import { PersonalActivity, PersonalAvatar, UserOverview } from "../../domain/users";
import { AvatarFallback, AvatarImage } from "./Avatar";
import { describeTimeAgo } from "./TimeAgo";
import typingBubbleIcon from "../../../assets/icons/typing-bubble-icon.svg";
import speakingBubbleIcon from "../../../assets/icons/speaking-bubble-icon.svg";
import {
    getDominantPresenceMode,
    isLivePresence,
    isVisibleUserPresenceMode,
    PresenceMode,
    presenceModesFromTracks,
} from "../../domain/presence";
import { useMemo } from "react";
import { ParticipantTileProps } from "./ParticipantTile";

export interface IHumanContext {
    bondCard?: boolean;
    isMobile?: boolean;
}

export interface IHumanModifiers {
    callParticipation?: ParticipantTileProps;
}

export interface IHumanProps {
    userId: d.UserId;
    context?: IHumanContext;
    modifiers?: IHumanModifiers;
}

export function Human(props: IHumanProps) {
    const { userId, context, modifiers } = props;
    const user = useSelectorArgs(selectUser, userId);

    const isMobile = context?.isMobile || false;
    const bondCard = context?.bondCard || false;
    const callParticipation = modifiers?.callParticipation;

    const presenceModes = useMemo(() => {
        let modes = user?.activity?.presenceModes ?? [];

        if (callParticipation) {
            // For a user in a call, only use RTC stack as source of live presence info
            modes = modes.filter(mode => !isLivePresence(mode));
            modes.push(...presenceModesFromTracks(
                callParticipation.audioTrack,
                callParticipation.videoTrack,
            ));
        }
        return modes;
    }, [user?.activity?.presenceModes, callParticipation]);

    const presenceForIcon = getDominantPresenceMode(
        presenceModes.filter(isVisibleUserPresenceMode),
    );
    const presenceImage = presenceForIcon ? getPresenceModeIcon(presenceForIcon) : "";

    const title = userName(user) + activityString(user?.activity);

    const statusClass = classNames({
        "cp-human--offline": !user?.activity?.active && !callParticipation,
    });

    const contextClass = bondCard ? classNames({
        "cp-human--card-mobile": isMobile,
        "cp-human--card": !isMobile,
    }) : "";

    return (
        <HumanFigure
            name={user?.name || ""}
            id={userId}
            avatar={user?.picture}
            title={title}
            contextClass={contextClass}
            statusClass={statusClass}
            presenceImage={presenceImage}
        />
    );
}

function HumanFigure(props: {
    avatar?: PersonalAvatar;
    name: string;
    id: d.UserId;
    title: string;
    statusClass?: string;
    contextClass?: string;
    presenceImage?: string;
}): React.JSX.Element {
    const { avatar, name, id, title, statusClass, contextClass, presenceImage } = props;

    const classes = classNames("cp-human", statusClass, contextClass);

    return (
        <figure
            className={classes}
            title={title}
        >
            {avatar && (
                <AvatarImage
                    avatar={avatar}
                    altText={title}
                    className="cp-human__avatar"
                />
            )}
            {!avatar && (
                <AvatarFallback
                    name={name}
                    id={id}
                    className="cp-human__avatar cp-human__avatar--fallback"
                />
            )}
            {presenceImage && (
                <div
                    className="c-human-activity"
                    style={{
                        backgroundImage: `url("${presenceImage}")`,
                    }}
                />
            )}
        </figure>
    );
}

function userName(user?: UserOverview): string {
    if (user?.nickname && user?.name) {
        return `${user.nickname} (${user.name})`;
    }
    if (user?.name) {
        return user.name;
    }
    return user?.nickname || "";
}

const getPresenceModeIcon = (mode: PresenceMode): string => {
    switch (mode) {
        case PresenceMode.Typing:
            return typingBubbleIcon;
        case PresenceMode.Speaking:
            return speakingBubbleIcon;
        default:
            return "";
    }
};

function activityString(activity?: PersonalActivity): string {
    if (!activity) {
        return "";
    }
    if (activity.active) {
        return "";
    }
    if (!activity.connected) {
        return " (disconnected " + describeTimeAgo({ from: activity.disconnectedAt }) + ")";
    }
    return " (" + describeTimeAgo({ from: activity.inactiveSince }) + ")";
}
