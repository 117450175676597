import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
} from "react-router-dom";
import BondView from "../views/BondView";
import { ErrorElement } from "./gui/ErrorElement";
import AttachmentLightbox from "./messages/AttachmentLightbox";
import SettingsView from "../views/SettingsView";
import { LocationLogger } from "./LocationLogger";
import LoginView from "../views/LoginView";
import LogoutView from "../views/LogoutView";
import { AuthenticatedRootView } from "../views/RootView";
import log from "../misc/log";
import BondInterestedModal from "./BondInterestedModal";
import { setRouter } from "../misc/router";
import { isMobileBrowser } from "../misc/mobile";
import { MobileSquadSelectorView } from "../views/MobileViews";
import { SquadView } from "../views/SquadView";
import { NewBondView } from "../views/NewBondView";
import { MyBondsView } from "../views/MyBondsView";
import DiscoverView from "../views/DiscoverView";
import { FlaggedInviteUsersModal } from "./InviteUsersModal";
import { FlaggedModifyBondModal } from "./ModifyBondModal";

const indexRedirect = async () => {
    log.info(`Redirecting index -> /bond`);
    return redirect("/bond");
};

const mobileDiscoverRedirect = async () => {
    if (isMobileBrowser()) {
        return redirect("/mobile/tab/discover");
    }
    return null;
};

const mobileBondsRedirect = async () => {
    if (isMobileBrowser()) {
        const from = window.location.href;
        const to = `/mobile/tab/mybonds${window.location.search}`;

        log.info(`Redirecting ${from} -> ${to}`);
        return redirect(to);
    }
    return null;
};

const nonMobileBondsRedirect = async () => {
    if (!isMobileBrowser()) {
        log.info(`Redirecting /mobile/tab -> /bond`);
        return redirect("/bond");
    }
    return null;
};

const errorHandler = {
    errorElement: <ErrorElement />,
};

const routeTree = (
    <Route
        path="/"
        element={
            <>
                <LocationLogger />
                <Outlet />
            </>
        }
        {...errorHandler}
    >
        {/* /login,/logout routes */}
        <Route
            path="login"
            element={<LoginView />}
            {...errorHandler}
        />
        <Route
            path="logout"
            element={<LogoutView />}
            {...errorHandler}
        />

        {
            /* Index. We always redirect to /bond since that is our authenticated area
            today. */
        }
        <Route index={true} loader={indexRedirect} {...errorHandler} />

        {/* /bond routes */}
        <Route
            path="bond"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":bondId" element={<BondView />} {...errorHandler}>
                <Route path="message/:messageId" element={<Outlet />} {...errorHandler}>
                    <Route
                        path="attachment/:attachmentVersion/:attachmentIdx"
                        element={<AttachmentLightbox />}
                        {...errorHandler}
                    />
                </Route>
                <Route path="interested" element={<BondInterestedModal />} {...errorHandler} />
                <Route path="invite" element={<FlaggedInviteUsersModal />} {...errorHandler} />
                <Route path="modify" element={<FlaggedModifyBondModal />} {...errorHandler} />
            </Route>
            <Route
                path="settings"
                element={
                    <>
                        <SettingsView />
                        <MyBondsView />
                    </>
                }
                {...errorHandler}
            />
            <Route path="new" element={<NewBondView />} {...errorHandler} />

            <Route
                index={true}
                loader={mobileBondsRedirect}
                element={<MyBondsView />}
                {...errorHandler}
            />
        </Route>

        {/* Desktop "Discover" */}
        <Route
            path="discover"
            loader={mobileDiscoverRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route index={true} element={<DiscoverView />} {...errorHandler} />
        </Route>

        {/* Filtering bonds by a squad */}
        <Route path="squad" element={<AuthenticatedRootView />} {...errorHandler}>
            <Route path=":squadId" element={<SquadView />} {...errorHandler} />
        </Route>

        {/* Mobile only tabbed view */}
        <Route
            path="mobile"
            loader={nonMobileBondsRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path="tab">
                <Route path="mybonds" element={<MyBondsView />} {...errorHandler} />
                <Route path="mysquads" element={<MobileSquadSelectorView />} {...errorHandler} />
                <Route path="discover" element={<DiscoverView />} {...errorHandler} />
            </Route>
        </Route>
    </Route>
);

export function setupRoutes() {
    setRouter(createBrowserRouter(createRoutesFromElements(routeTree)));
}
