import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EditTitle } from "../components/EditTitle";
import SquadEntry from "../components/buttons/BondsTopbarSquadEntry";
import { MobileMenuButton } from "../components/buttons/MobileMenuButton";
import { BondId } from "../domain/domain";
import {
    followBond,
    selectBondIsFollower,
    renameBondTitle,
    selectBondById,
    selectBondContemporaries,
    selectBondTitle,
    selectChannelIdByBondId,
    selectSelectedBondId,
} from "../features/bonds";
import {
    PrivateOrSquadFilterOption,
    selectPrivateOrSquadFilter,
    selectRelevanceLevelFilter,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel";
import {
    fetchCurrentUserMemberships,
    selectCurrentSquadIdsOrderedBySquadName,
    selectSquadById,
} from "../features/squads";
import { selectCurrentUser } from "../features/users";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { JoinedCallView, selectJoinedCallView } from "../features/calls";
import OfflineIndicator from "../components/OfflineIndicator";
import { selectShowSuggestedBonds, transferDraftThunk } from "../features/bondCreation";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import classnames from "classnames";
import { updateStagedSequenceNumberToLocalMax } from "../features/channels";
import ActionButton from "../components/buttons/ActionButton";
import { SettingsDropdown } from "../components/SettingsDropdown";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";

function SquadsDropdown(): React.JSX.Element {
    const dispatch = useAppDispatch();

    useConnectedEffect(() => {
        dispatch(fetchCurrentUserMemberships());
    }, [dispatch]);

    const allSquadIds = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const squadFilter = useAppSelector(selectPrivateOrSquadFilter);
    const hasSquadFilter = squadFilter.by === "squad";

    const selectedSquad = useSelectorArgs(
        selectSquadById,
        hasSquadFilter ? squadFilter.squadId : undefined,
    );
    const squadButtonClassName = classnames("cp-btn cp-btn-squads", {
        "is-selected": hasSquadFilter,
    });

    if (allSquadIds.length === 0) {
        return <></>;
    }

    return (
        <div className="has-dropdown">
            <button className={squadButtonClassName}>
                {selectedSquad ? selectedSquad.name : "Home"}
            </button>
            <div className="cp-dropdown cp-dropdown--left">
                <div className="cp-dropdown__wrapper cp-dropdown-squad">
                    {allSquadIds.map(squadId => <SquadEntry key={squadId} id={squadId} />)}
                </div>
            </div>
        </div>
    );
}

function CardViewTopBarViewUnmemo(): React.JSX.Element {
    const squadFilter = useAppSelector(selectPrivateOrSquadFilter);
    const doneFilter = useAppSelector(selectRelevanceLevelFilter);

    // We don't support filtering by "private" right now, hence we only need to look at
    // whether there is a squad filter.
    const hasSquadFilter = squadFilter.by === "squad";
    const isDismissedFiltered = doneFilter.by === "archived";

    const dispatch = useAppDispatch();
    const resetFilters = useCallback(() => {
        dispatch(
            setPrivateOrSquadFilterThunk({ by: "option", option: PrivateOrSquadFilterOption.ALL }),
        );
        dispatch(setRelevanceLevelFilter({ by: "all" }));
    }, [dispatch]);
    const setDismissedFilter = useCallback(() => {
        if (isDismissedFiltered) dispatch(setRelevanceLevelFilter({ by: "all" }));
        else dispatch(setRelevanceLevelFilter({ by: "archived" }));
    }, [dispatch, isDismissedFiltered]);

    const dismissedButtonClassName = classnames("cp-btn cp-btn-done", {
        "is-selected": isDismissedFiltered,
    });

    const squadsDropdown = <SquadsDropdown key="squads" />;
    const dismissedButton = (
        <button
            className={dismissedButtonClassName}
            title={isDismissedFiltered ? "Show non-dismissed bonds" : "Show dismissed bonds"}
            onClick={setDismissedFilter}
            key="done"
        >
            Dismissed
        </button>
    );
    const resetButton = (
        <button
            className={`cp-btn cp-btn-reset${
                hasSquadFilter || isDismissedFiltered ? " cp-btn-reset--active" : ""
            }`}
            title="Reset view"
            onClick={resetFilters}
            key="reset"
        >
            Reset
        </button>
    );

    const controls: JSX.Element[] = [];
    if (hasSquadFilter || isDismissedFiltered) {
        controls.push(resetButton);
    }
    // Order of the buttons is dependent on whether there is a filter - filters should be pinned to the left.
    if (isDismissedFiltered && !hasSquadFilter) {
        controls.push(dismissedButton);
        controls.push(squadsDropdown);
    }
    else {
        controls.push(squadsDropdown);
        controls.push(dismissedButton);
    }

    return (
        <>
            <header className="c-header c-card-header">
                <div className="c-card-header__controls">
                    {controls}
                </div>
                <div className="c-card-header__controls">
                    <div className="c-card-header__settings has-dropdown">
                        <OfflineIndicator />
                        <button className="c-btn cp-btn-settings" title="Settings">
                            Settings
                        </button>
                        <SettingsDropdown />
                    </div>
                </div>
            </header>
            <MobileMenuButton />
        </>
    );
}

const CardViewTopBarView = memo(CardViewTopBarViewUnmemo);

const FollowSwitch = ({ bondId }: { bondId: BondId; }): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const isFollowing = useSelectorArgs(selectBondIsFollower, bondId);
    const followAction = useCallback((newValue: boolean) => {
        dispatch(
            followBond({
                bondId: bondId,
                follow: newValue,
            }),
        );
    }, [dispatch, bondId]);

    const followSwitchProps = useMemo(() => ({
        className: "cp-btn-bond cp-btn-bond--large cp-btn-bond--follow",
        action: followAction,
        switchValue: isFollowing,
        falseState: {
            titleText: "Follow",
            buttonText: "Follow",
        },
        trueState: {
            titleText: "Unfollow",
            buttonText: "Unfollow",
            extraClassNames: "is-selected",
        },
    }), [isFollowing, followAction]);

    return <ActionButton {...followSwitchProps} />;
};

const CaughtUpButton = ({ bondId }: { bondId: BondId; }): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const userId = useAppSelector(selectCurrentUser)?.id;
    const caughtUpUsers = useSelectorArgs(selectBondContemporaries, bondId);

    const catchUpAction = useCallback((_: boolean) => {
        if (!channelId) {
            return;
        }
        dispatch(
            updateStagedSequenceNumberToLocalMax(channelId),
        );
    }, [dispatch, channelId]);

    const isCaughtUp = userId && caughtUpUsers?.has(userId) || false;

    const caughtUpSwitchProps = useMemo(() => ({
        className: "cp-btn-bond cp-btn-bond--large cp-btn-bond--done",
        action: catchUpAction,
        switchValue: isCaughtUp,
        showWhen: false,
        falseState: {
            titleText: "Caught up",
            buttonText: "Caught up",
        },
    }), [catchUpAction, isCaughtUp]);

    return <ActionButton {...caughtUpSwitchProps} />;
};

function EditTitleButton({ bondId }: { bondId: BondId; }): React.JSX.Element {
    const dispatch = useAppDispatch();
    const [editing, setEditing] = useState(false);
    const bond = useSelectorArgs(selectBondById, bondId);

    useEffect(() => {
        setEditing(false);
    }, [bondId]);

    return (
        <>
            <button
                className="cp-btn cp-btn-edit"
                title="Edit title"
                onClick={() => {
                    setEditing(e => !e);
                }}
            >
                Edit
            </button>
            {editing &&
                (
                    <EditTitle
                        userSpecifiedTitle={bond?.knowledge.userSpecifiedTitle ?? ""}
                        aiGeneratedTitle={bond?.knowledge.aiGeneratedTitle ?? ""}
                        updateTitle={(title: string) => {
                            dispatch(
                                renameBondTitle({
                                    bondId: bondId,
                                    title: title,
                                }),
                            );
                        }}
                        setEditing={setEditing}
                    />
                )}
        </>
    );
}

/*
function MoreOptions(): React.JSX.Element {
    return <button className="cp-btn-more" title="More options">More</button>;
}
*/

function BondTopBarViewUnmemo({ bondId }: { bondId: BondId | undefined; }): React.JSX.Element {
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const dispatch = useAppDispatch();

    // This is attached to a `<Link>`. In order to be executed before the link is
    // followed, it must be attached to the `onClickCapture` property.
    const closeClick = useCallback(() => {
        if (channelId) {
            dispatch(transferDraftThunk({
                from: newChannelDraftTarget(channelId),
                to: bondCreationDraftTarget,
            }));
        }
    }, [dispatch, channelId]);

    const joinedCallView = useSelectorArgs(selectJoinedCallView);

    // Don't show the bond header when joined a call in Live View
    if (joinedCallView === JoinedCallView.Live) {
        return <></>;
    }

    return (
        <>
            <header className="c-header c-bond-header">
                <div className="c-bond-header__escape">
                    <Link
                        to={"/bond"}
                        className="cp-btn-escape"
                        title="Close bond"
                        onClickCapture={closeClick}
                    >
                        <span className="cp-btn-escape__icon"></span>
                        <label className="cp-btn-escape__label">Esc</label>
                    </Link>
                </div>
                {bondId && (
                    <>
                        <h1
                            className={"c-bond-header__title u-truncate-auto" +
                                (bondTitle.aiGenerated ? " c-ai-generated-title" : "")}
                            title={title}
                        >
                            {title}
                        </h1>
                        <div className="c-bond-header__controls">
                            <OfflineIndicator />
                            <EditTitleButton bondId={bondId} />
                            <FollowSwitch bondId={bondId} />
                            <CaughtUpButton bondId={bondId} />
                            {/*<MoreOptions />*/}
                        </div>
                    </>
                )}
            </header>
        </>
    );
}

const BondTopBarView = memo(BondTopBarViewUnmemo);

function Phase2BondsTopBarView(): React.JSX.Element {
    const location = useLocation();
    const bondId = useAppSelector(selectSelectedBondId);

    // We hide the top bar when the card view is showing suggestions.
    const showingSuggestions = useAppSelector(selectShowSuggestedBonds);

    if (location.pathname === "/bond" || location.pathname === "/mobile/tab/mybonds") {
        return !showingSuggestions ? <CardViewTopBarView /> : <></>;
    }

    return <BondTopBarView bondId={bondId} />;
}

export default Phase2BondsTopBarView;
