import classNames from "classnames";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bondCreationDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { DraftChatMessage } from "../domain/messages";
import { selectCurrentOrgId } from "../features/auth";
import {
    clearDraftThunk,
    createBondFromMessageThunk,
    promotePrefilled,
    selectBondCreationMessageQueryString,
} from "../features/bondCreation";
import { clearFreshBond } from "../features/bonds";
import {
    PrivateOrSquadFilterOption,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel";
import useKeypressFocus from "../hooks/useKeypressFocus";
import useMousePosCondition from "../hooks/useMousePosCondition";
import usePrevious from "../hooks/usePrevious";
import log from "../misc/log";
import { Focusable, Optional } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { FeatureFlagged } from "./FeatureFlags";
import { MessageComposer } from "./MessageComposer";
import RichTextMessageComposer from "./RichTextMessageComposer";

// The period after manually closing the bar before it can be manually opened again (either
// with the mouse or the handle). The bar will always open immediately when typing or tabbing
const barClosingGraceMillis = 500;

interface BondCreationBarProps {}

export default function BondCreationBar(
    _props: BondCreationBarProps,
): React.JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orgId = useAppSelector(selectCurrentOrgId);

    const createBondFromMsg = useCallback(async (msg: Optional<DraftChatMessage>) => {
        if (!msg) return;

        // In future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond`);
            return;
        }

        log.info("Creating bond with message");

        const newBond = await dispatch(createBondFromMessageThunk({ orgId, msg })).unwrap();

        dispatch(clearFreshBond({}));
        navigate("/bond/" + d.extractUUID(newBond.id));
    }, [
        dispatch,
        orgId,
        navigate,
    ]);

    const barRef = useRef<HTMLDivElement>(null);
    const composerRef = useRef<Focusable>(null);

    // Track composer text area focus, and the emoji input's visibility
    const setComposerTextFocused = useKeypressFocus(composerRef);
    const [modalOpen, setModalOpen] = useState(false);
    const onComposerModalChange = useCallback((current: boolean) => setModalOpen(current), []);

    // If we have body text in the draft, we must show the bar
    const suggestionQueryString = useAppSelector(selectBondCreationMessageQueryString);
    const textInBody = !!suggestionQueryString;

    // If we have focus on the editor, we want to show the bar immediately
    const [editorFocused, setEditorFocused] = useState<boolean>(false);
    const onEditorFocus = useCallback(
        () => {
            setComposerTextFocused(true);
            setEditorFocused(true);
        },
        [setComposerTextFocused],
    );
    const onEditorBlur = useCallback(
        () => {
            setComposerTextFocused(false);
            setEditorFocused(false);
        },
        [setComposerTextFocused],
    );

    // Track whether the mouse position is within the trigger region for showing the bar
    const mouseCondition = useCallback((_: number, y: number) => {
        if (!barRef.current) {
            return false;
        }
        const barTop = barRef.current.getBoundingClientRect().top;
        return y > barTop - 10;
    }, []);
    const [mouseInRegion, clearMouseInRegion] = useMousePosCondition(mouseCondition);

    // Track whether the user has opened the bar using the handle---this is the only way
    // to open the bar on mobile
    const [pinned, setPinned] = useState(false);
    const handleClickCallback = useCallback(() => {
        // TODO: need a better solution than the current 'handle' for mobile
        // Pin the bar and focus the input when the user clicks the handle
        setPinned(true);
        composerRef.current?.focus();
    }, [setPinned]);

    // Condition where the bar should get the chance to fully close before opening again
    const [closing, setClosing] = useState(false);
    const closingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const closeWithTimeout = useCallback(() => {
        setClosing(true);

        // These lines are necessary to clear `immediate`, and to prevent the bar
        // from immediately reshowing on mobile
        composerRef.current?.blur();
        setPinned(false);
        clearMouseInRegion();

        if (closingTimeout.current !== null) {
            clearTimeout(closingTimeout.current);
        }
        closingTimeout.current = setTimeout(() => {
            setClosing(false);
        }, barClosingGraceMillis);
    }, [clearMouseInRegion]);

    const discardAction = useCallback(() => {
        dispatch(clearDraftThunk(bondCreationDraftTarget))
            .unwrap()
            .finally(() => closeWithTimeout());
    }, [dispatch, closeWithTimeout]);

    // Clear the home/squad/done selection when the user starts a draft, and promote any
    // prefilled content to a regular part of the message
    useEffect(() => {
        if (textInBody) {
            dispatch(promotePrefilled(bondCreationDraftTarget));
            dispatch(setRelevanceLevelFilter({ by: "all" }));
            dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
        }
    }, [dispatch, textInBody]);

    // Close the bar when the user clicks outside
    useEffect(() => {
        const check = (e: MouseEvent | TouchEvent) => {
            if (
                barRef.current && e.target &&
                !barRef.current.contains(e.target as Node)
            ) {
                closeWithTimeout();
            }
        };

        document.addEventListener("click", check);
        document.addEventListener("touchstart", check);
        return () => {
            document.removeEventListener("click", check);
            document.removeEventListener("touchstart", check);
        };
    }, [closeWithTimeout]);

    // Prevent the janky interaction between the css transition and focussing an off-screen element
    const immediate = editorFocused || pinned;

    // Always show the bar when the emoji picker is open or the composer contains text
    // Otherwise, show the bar when the mouse moves down, unless the closing timeout is active
    const trigger = !closing && mouseInRegion;
    const showBar = modalOpen || textInBody || trigger;
    const prevShowBar = usePrevious(showBar, { initialValue: showBar });

    // When the bar should hide according to the standard (non-`immediate`) criteria, trigger a closing timeout
    useLayoutEffect(() => {
        if (!showBar && showBar != prevShowBar) {
            closeWithTimeout();
        }
    }, [showBar, prevShowBar, closeWithTimeout]);

    // By default, the bar is shown
    const classes = classNames("c-bond-creation-bar", {
        "c-bond-creation-bar--immediate": immediate,
        "c-bond-creation-bar--hidden": !immediate && !showBar,
    });

    return (
        <div className={classes} ref={barRef} data-testid="c-bond-creation-bar">
            <button
                className="c-bond-creation-bar__handle"
                onClick={handleClickCallback}
                aria-label="Compose"
                aria-hidden={showBar}
            >
            </button>
            <div aria-expanded={showBar}>
                <FeatureFlagged
                    flag={"rich-text-composer"}
                    match={true}
                    wrapWithDiv={false}
                    fallback={
                        <MessageComposer
                            id="comms-input-bond-creation"
                            key="comms-input-bond-creation"
                            ref={composerRef}
                            draftTarget={bondCreationDraftTarget}
                            msgCompletionAction={createBondFromMsg}
                            discardAction={discardAction}
                            escapeAction={closeWithTimeout}
                            numberOfParticipants={0}
                            bondComposer={true}
                            placeholder="Message..."
                            onModalChange={onComposerModalChange}
                            onTextAreaFocus={onEditorFocus}
                            onTextAreaBlur={onEditorBlur}
                        />
                    }
                >
                    <RichTextMessageComposer
                        id="comms-input-bond-creation"
                        key="comms-input-bond-creation"
                        ref={composerRef}
                        draftTarget={bondCreationDraftTarget}
                        msgCompletionAction={createBondFromMsg}
                        discardAction={discardAction}
                        escapeAction={closeWithTimeout}
                        numberOfParticipants={0}
                        bondComposer={true}
                        placeholder="Message..."
                        onModalChange={onComposerModalChange}
                        onEditorFocus={onEditorFocus}
                        onEditorBlur={onEditorBlur}
                    />
                </FeatureFlagged>
            </div>
        </div>
    );
}
