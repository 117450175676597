import { useEffect, useState } from "react";
import { selectNewestToast, ToastContent } from "../features/meta";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { Optional } from "../misc/types";
import { Duration } from "luxon";
import usePrevious from "../hooks/usePrevious";

export default function ToastContainer(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const [currentToast, setCurrentToast] = useState<Optional<ToastContent>>(undefined);

    const newestToast = useAppSelector(selectNewestToast);
    const previousToast = usePrevious(newestToast);

    useEffect(() => {
        if (newestToast != previousToast) {
            // We currently only show the single most recent toast
            // But we could manage a list of toasts here
            setCurrentToast(newestToast);
        }
    }, [newestToast, previousToast]);

    useEffect(() => {
        if (currentToast) {
            const delay = Duration.fromObject(currentToast.duration).toMillis();

            const t = setTimeout(() => {
                setCurrentToast(undefined);
            }, delay);

            return () => {
                clearTimeout(t);
            };
        }
    }, [dispatch, currentToast]);

    if (!currentToast) {
        return <></>;
    }
    return (
        <div style={{ position: "relative", width: "100%" }}>
            <div className="c-toast-notification c-toast-notification--fixed">
                {currentToast.message}
            </div>
        </div>
    );
}
