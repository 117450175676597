import classNames from "classnames";
import { Mention } from "../../domain/mentions";
import { selectCurrentUserId } from "../../features/auth";
import { selectSquadById } from "../../features/squads";
import { selectUser } from "../../features/users";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { useAppSelector } from "../../store/redux";
import SensitiveText from "../gui/SensitiveText";

interface MentionProps {
    text: string;
    mention: Mention;
}

export const MentionView = (props: MentionProps): React.JSX.Element => {
    const { text, mention } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const userId = (mention.case == "user") ? mention.target : undefined;
    const user = useSelectorArgs(selectUser, userId);

    const squadId = (mention.case == "squad") ? mention.target : undefined;
    const squad = useSelectorArgs(selectSquadById, squadId);

    const title = user ? user.name : squad?.name;

    const classes = classNames("cp-mention", {
        "cp-mention--self": userId && userId == currentUserId,
    });

    return (
        <span className={classes} title={title}>
            <SensitiveText>{text}</SensitiveText>
        </span>
    );
};

export default MentionView;
