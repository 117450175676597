import { App } from "@capacitor/app";
import { useState, useEffect } from "react";

import { selectBackendInfo } from "../features/meta";
import { useAppSelector } from "../store/redux";
import { isNativePlatform } from "../misc/capacitor";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import classNames from "classnames";

export function VersionInfo({ location }: { location?: "isAside"; }): React.JSX.Element {
    const backendInfo = useAppSelector(selectBackendInfo);
    const isPhase3 = useBooleanFeatureFlag("phase-3-ui");

    const [nativeVersion, setNativeVersion] = useState<string>("");
    useEffect(() => {
        if (isNativePlatform) {
            App.getInfo().then(info => setNativeVersion(info.version));
        }
    }, []);

    const outerClassNames = classNames({
        "c-aside__versions": isPhase3,
        "c-aside__versions--aside": isPhase3 && location === "isAside",
    });
    const innerClassNames = classNames({
        "c-aside-version": isPhase3,
    });

    return (
        <div className={outerClassNames}>
            <div className={innerClassNames}>Backend: {backendInfo?.version}</div>
            <div className={innerClassNames}>Instance: {backendInfo?.instance}</div>

            {!isNativePlatform && (
                <div className={innerClassNames}>
                    Frontend: {__BEYOND_FRONTEND_VERSION__}
                </div>
            )}

            {isNativePlatform && (
                <div className={innerClassNames}>
                    App version: {nativeVersion}
                </div>
            )}
        </div>
    );
}
