import { useEffect, useState } from "react";
import ColorThief from "colorthief";
import classNames from "classnames";
import { isMobileBrowser } from "../misc/mobile";
import * as d from "../domain/domain";
import {
    selectBondById,
    selectBondImageUrl,
    selectBondTitle,
    selectDetailedBondSummary,
} from "../features/bonds";
import useSelectorArgs from "../hooks/useSelectorArgs";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { useAppSelector } from "../store/redux";
import { selectBondIdsForDiscover } from "../features/filterPanel";
import { selectKnownSquadNames } from "../features/squads";

import { BondCardContributors } from "../components/BondCard";
import TimeAgo from "../components/gui/TimeAgo";
import { useNavigate } from "react-router-dom";
import { BondViewStreamSubscriptions } from "./BondView";

function DiscoverCard({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const [backgroundColor, setBackgroundColor] = useState("");
    const isMobile = isMobileBrowser();

    const imgSrc = useSelectorArgs(selectBondImageUrl, bondId);

    const bo = useSelectorArgs(selectBondById, bondId);
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const bondSummary = useSelectorArgs(selectDetailedBondSummary, bondId) || "";

    // FIXME: duplicated with BondCard
    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");
    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    const squadIds = bo?.squadIds || [];
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
    const timeFrom = bo?.lastActivityAt || 0;

    useEffect(() => {
        const colorThief = new ColorThief();

        const loadImageAndExtractColor = (imageSource: string) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = imageSource;
            img.onload = () => {
                try {
                    const [r, g, b] = colorThief.getColor(img);
                    setBackgroundColor(`rgb(${r}, ${g}, ${b}, 0.6)`);
                }
                catch (error) {
                    console.error("Error getting color:", error);
                }
            };
        };

        loadImageAndExtractColor(imgSrc);
    }, [setBackgroundColor, imgSrc]);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/bond/${d.extractUUID(bondId)}`);
    };

    const cardClasses = classNames("c-card-discover", {
        "c-card-discover--desktop": !isMobile,
    });

    const cardImageWrapperClasses = classNames("c-card-discover__image-wrapper", {
        "c-card-discover__image-wrapper--desktop": !isMobile,
    });

    const cardImageClasses = classNames("c-card-discover__image", {
        "c-card-discover__image--desktop": !isMobile,
    });

    const cardTitleClasses = classNames("c-card-discover__title", {
        "c-card-discover__title--desktop": !isMobile,
    });

    const cardSummaryClasses = classNames("c-card-discover__summary", {
        "c-card-discover__summary--desktop": !isMobile,
    });

    const cardFooterClasses = classNames("c-card-discover__footer", {
        "c-card-discover__footer--desktop": !isMobile,
    });

    return (
        <div className="c-card-discover-wrapper" onClick={onClick}>
            <div className={cardClasses} style={{ backgroundColor }}>
                <figure className={cardImageWrapperClasses}>
                    <img
                        src={imgSrc}
                        alt=""
                        className={cardImageClasses}
                    />
                </figure>
                <div className="c-card-discover__details">
                    <h3 className={cardTitleClasses}>{title}</h3>
                    <p className={cardSummaryClasses}>
                        {bondSummary}
                    </p>
                </div>
                <div className={cardFooterClasses}>
                    <div className="c-discover-meta">
                        <span className="c-discover-meta__group">{squadNames.join(",")}</span>{" "}
                        {/*<span className="c-middot">&#183;</span> <span>24 views</span>*/}
                    </div>
                    <div className="c-discover-meta">
                        <time>
                            <TimeAgo from={timeFrom} live={true} />
                        </time>
                        <span className="c-middot">&#183;</span>{" "}
                        <div className="c-discover-meta__participants">
                            <BondCardContributors bondId={bondId} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Quick and dirty seeded PRNG
// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
function makeRngSplitmix32(seed: number) {
    return function () {
        seed |= 0;
        seed = seed + 0x9e3779b9 | 0;
        let t = seed ^ seed >>> 16;
        t = Math.imul(t, 0x21f0aaad);
        t = t ^ t >>> 15;
        t = Math.imul(t, 0x735a2d97);
        return ((t = t ^ t >>> 15) >>> 0) / 4294967296;
    };
}

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
// Modified not to mutate but return a new array
function shuffleArray<T>(array: T[], seed: number) {
    const prng = makeRngSplitmix32(seed);
    const copy: T[] = [...array];

    for (let i = copy.length - 1; i >= 0; i--) {
        const j = Math.floor(prng() * (i + 1));
        [copy[i], copy[j]] = [copy[j], copy[i]];
    }

    return copy;
}

// See also: MobileDiscoverView. We may want to combine these.
export default function DiscoverView(): React.JSX.Element {
    const allBondIds = useAppSelector(selectBondIdsForDiscover);
    // Limit to 18 cards, give us a random selection, keep it stable each day
    const sortedBondIds = shuffleArray(allBondIds, new Date().getDate()).splice(0, 18);

    return (
        <>
            <BondViewStreamSubscriptions />
            <div className="c-cards c-cards--discover">
                <div className="c-cards-discover">
                    {sortedBondIds.map(bondId => <DiscoverCard key={bondId} bondId={bondId} />)}
                </div>
            </div>
        </>
    );
}
