// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file calls/calls.proto (package calls, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCallKnowledgeRequest, AddCallKnowledgeResponse, EndCallRequest, EndCallResponse, FakeDataInsertRequest, FakeDataInsertResponse, FilterCallsWithTranscriptsRequest, FilterCallsWithTranscriptsResponse, GetAccessTokenRequest, GetAccessTokenResponse, GetCallEventsRequest, GetCallEventsResponse, GetCallInitiatorRequest, GetCallInitiatorResponse, GetCallKnowledgeByTypeRequest, GetCallKnowledgeByTypeResponse, GetCallKnowledgePreviewRequest, GetCallKnowledgePreviewResponse, GetCallRequest, GetCallResponse, ListCallParticipantsRequest, ListCallParticipantsResponse, StartCallRequest, StartCallResponse, SubCallsRequest, SubCallsResponse } from "./calls_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service calls.CallService
 */
export const CallService = {
  typeName: "calls.CallService",
  methods: {
    /**
     * StartCall starts a new session in the RTC stack
     *
     * @generated from rpc calls.CallService.StartCall
     */
    startCall: {
      name: "StartCall",
      I: StartCallRequest,
      O: StartCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * EndCall ends a call via the RTC stack.
     *
     * @generated from rpc calls.CallService.EndCall
     */
    endCall: {
      name: "EndCall",
      I: EndCallRequest,
      O: EndCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCall returns the overview for a call id
     *
     * @generated from rpc calls.CallService.GetCall
     */
    getCall: {
      name: "GetCall",
      I: GetCallRequest,
      O: GetCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetAccessToken gets a new RTC access token for the user to join a session
     *
     * @generated from rpc calls.CallService.GetAccessToken
     */
    getAccessToken: {
      name: "GetAccessToken",
      I: GetAccessTokenRequest,
      O: GetAccessTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FakeDataInsert creates a new call from fake data, then stores and handles a series of fake call events.
     *
     * @generated from rpc calls.CallService.FakeDataInsert
     */
    fakeDataInsert: {
      name: "FakeDataInsert",
      I: FakeDataInsertRequest,
      O: FakeDataInsertResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Adds new call knowledge (e.g. title, summary) to a call, without overwriting any old knowledge.
     *
     * @generated from rpc calls.CallService.AddCallKnowledge
     */
    addCallKnowledge: {
      name: "AddCallKnowledge",
      I: AddCallKnowledgeRequest,
      O: AddCallKnowledgeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallKnowledgeByType returns all the call knowledges for a given knowledge type for a call.
     *
     * @generated from rpc calls.CallService.GetCallKnowledgeByType
     */
    getCallKnowledgeByType: {
      name: "GetCallKnowledgeByType",
      I: GetCallKnowledgeByTypeRequest,
      O: GetCallKnowledgeByTypeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallKnowledgePreview returns a call knowledge preview for a call
     *
     * @generated from rpc calls.CallService.GetCallKnowledgePreview
     */
    getCallKnowledgePreview: {
      name: "GetCallKnowledgePreview",
      I: GetCallKnowledgePreviewRequest,
      O: GetCallKnowledgePreviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListCallParticipants lists the participants in a call, by call id.
     *
     * @generated from rpc calls.CallService.ListCallParticipants
     */
    listCallParticipants: {
      name: "ListCallParticipants",
      I: ListCallParticipantsRequest,
      O: ListCallParticipantsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallEvents returns a series of call events for a call ID sorted by sequence number.
     *
     * @generated from rpc calls.CallService.GetCallEvents
     */
    getCallEvents: {
      name: "GetCallEvents",
      I: GetCallEventsRequest,
      O: GetCallEventsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FilterCallsWithTranscripts checks the provided call ids and return the subset of ids that have transcript call events
     *
     * @generated from rpc calls.CallService.FilterCallsWithTranscripts
     */
    filterCallsWithTranscripts: {
      name: "FilterCallsWithTranscripts",
      I: FilterCallsWithTranscriptsRequest,
      O: FilterCallsWithTranscriptsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallInitiator returns the initiator of a call.
     *
     * @generated from rpc calls.CallService.GetCallInitiator
     */
    getCallInitiator: {
      name: "GetCallInitiator",
      I: GetCallInitiatorRequest,
      O: GetCallInitiatorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubCalls subscribes to changes in a series of calls.
     *
     * @generated from rpc calls.CallService.SubCalls
     */
    subCalls: {
      name: "SubCalls",
      I: SubCallsRequest,
      O: SubCallsResponse,
      kind: MethodKind.BiDiStreaming,
    },
  }
};

