import { useEffect, useRef } from "react";

export default function useOutsideClick<ElementType extends HTMLElement>(
    callback: (target: EventTarget | null) => void,
    active?: boolean,
) {
    const ref = useRef<ElementType>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (
                (active === undefined || active) &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                callback(event.target);
            }
        };

        document.addEventListener("click", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [active, callback]);

    return [ref] as [
        ref: React.RefObject<ElementType>,
    ];
}
