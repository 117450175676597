import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import NewBondMessageComposer from "../components/NewBondMessageComposer";
import { NewBondTitleBar } from "../components/NewBondTitleBar";
import { bondCreationDraftTarget } from "../domain/channels";
import { clearDraftThunk } from "../features/bondCreation";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/meta";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useAppDispatch } from "../store/redux";
import NewBondAudienceInput from "../components/NewBondAudienceInput";

export const NewBondView = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack();

    // Clear the bond creation draft when entering the view
    useEffect(() => {
        dispatch(clearDraftThunk(bondCreationDraftTarget));
    }, [dispatch]);

    // TODO: "Are you sure?"
    const backAction = navigateBack;

    // Navigate back when escape is pressed
    const hotkeyBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );
    const hotkeyBlockedWrapper = useCallback(() => hotkeyBlocked, [hotkeyBlocked]);
    useHotkeys("esc", backAction, {
        ignoreEventWhen: hotkeyBlockedWrapper,
        enableOnFormTags: ["input", "textarea"],
    });

    return (
        <div className="c-new-bond">
            <NewBondTitleBar backAction={backAction} />
            <div className="c-new-bond__content">
                <NewBondAudienceInput />
            </div>
            <NewBondMessageComposer backAction={backAction} />
        </div>
    );
};
