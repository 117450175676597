import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Optional, ScreenPos } from "../misc/types";
import { applyStyle } from "../misc/css";

/**
 * Get the screen-space position of the character at a given offset in a textarea
 *
 * NOTE: this is disgusting and I pinky-promise it's the only way to do it
 */
const getOffsetScreenPos = (input: HTMLTextAreaElement, offset: number): ScreenPos => {
    // Get the screen position and scroll offsets of the real input
    const {
        left: inputLeft,
        top: inputTop,
    } = input.getBoundingClientRect();
    // TODO: find a sensible way to trigger a (debounced) update when the scroll offset changes
    const {
        scrollLeft: inputScrollLeft,
        scrollTop: inputScrollTop,
    } = input;

    // Create a div which mirrors the styling of the input
    const div = document.createElement("div");
    applyStyle(window.getComputedStyle(input), div.style);

    // Position the div just out of view
    div.style.position = "absolute";
    div.style.top = "100dvh";
    div.style.visibility = "hidden";

    // Copy the text up to the offset into the new div
    div.textContent = input.value.substring(0, offset);

    // Create a span with a non-breaking space at the cursor position
    const span = document.createElement("span");
    span.textContent = "\xa0";
    div.appendChild(span);

    // Put the div in the document and find the offset position of the span
    document.body.appendChild(div);
    const {
        offsetLeft: spanOffsetLeft,
        offsetTop: spanOffsetTop,
    } = span;
    document.body.removeChild(div);

    // Calculate the screen position of the offset using the position of the real input
    return {
        x: inputLeft - inputScrollLeft + spanOffsetLeft,
        y: inputTop - inputScrollTop + spanOffsetTop,
    };
};

export const useOffsetScreenPos = (
    ref: RefObject<HTMLTextAreaElement>,
    offset: Optional<number>,
): Optional<ScreenPos> => {
    const [position, setPosition] = useState<Optional<ScreenPos>>(undefined);

    const calculatePosition = useCallback(() => {
        if (!ref.current || offset === undefined) {
            setPosition(undefined);
        }
        else {
            setPosition(getOffsetScreenPos(ref.current, offset));
        }
    }, [ref, offset]);

    // Recalculate when the dependencies change
    useLayoutEffect(calculatePosition, [calculatePosition]);

    // Recalculate when the window size changes
    useEffect(() => {
        window.addEventListener("resize", calculatePosition);
        return () => window.removeEventListener("resize", calculatePosition);
    }, [calculatePosition]);

    return position;
};
