import { useCallback, useMemo } from "react";
import { DraftTarget } from "../domain/channels";
import {
    Mention,
    newSquadMention,
    newUserMention,
    squadNameForMention,
    userNameForMention,
} from "../domain/mentions";
import { SquadOverview } from "../domain/squads";
import { UserOverview } from "../domain/users";
import { selectValidSquadsForMention, selectValidUserIdsForMention } from "../features/mentions";
import { selectUsers } from "../features/users";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { similaritySearch, similaritySort } from "../misc/similarStrings";
import { NumberRange } from "../misc/types";
import { isEmailAddress, partialComponent } from "../misc/utils";
import { SuggestionContentProps } from "../components/gui/AutoComplete";
import useMetaInterest from "../hooks/interest/useMetaInterest";
import { MetaInterestCounterKey } from "../features/meta";

interface SquadMentionSuggestionProps {
    squad: SquadOverview;
}
export type SquadMentionSuggestionContentProps =
    & SquadMentionSuggestionProps
    & SuggestionContentProps;

interface UserMentionSuggestionProps {
    user: UserOverview;
}
export type UserMentionSuggestionContentProps =
    & UserMentionSuggestionProps
    & SuggestionContentProps;

interface EmailInvitationSuggestionProps {
    email: string;
}
export type EmailInvitationSuggestionContentProps =
    & EmailInvitationSuggestionProps
    & SuggestionContentProps;

export type MentionInsertFunc = (
    mention: Mention,
    text: string,
    range: NumberRange,
) => void;

interface UseMentionSuggestionsProps {
    insert: MentionInsertFunc;
    query: AutoCompleteQuery;
    draftTarget: DraftTarget;
    similarityThreshold?: number;
    extraUsers?: UserOverview[];
    SquadMentionSuggestionContent?(
        props: SquadMentionSuggestionContentProps,
    ): React.JSX.Element;
    UserMentionSuggestionContent?(
        props: UserMentionSuggestionContentProps,
    ): React.JSX.Element;
    EmailInvitationSuggestionContent?(
        props: EmailInvitationSuggestionContentProps,
    ): React.JSX.Element;
    emailInvitationCb?(email: string): void;
}

export default function useMentionSuggestions(props: UseMentionSuggestionsProps) {
    const {
        insert,
        query,
        draftTarget,
        extraUsers,
        SquadMentionSuggestionContent,
        UserMentionSuggestionContent,
        EmailInvitationSuggestionContent,
        emailInvitationCb,
    } = props;
    const similarityThreshold = props.similarityThreshold ?? -100000;

    const validSquads = useSelectorArgs(selectValidSquadsForMention, draftTarget);
    const validUserIds = useSelectorArgs(selectValidUserIdsForMention, draftTarget);
    const validUsers = useSelectorArgs(selectUsers, validUserIds);

    useInterestedUsers(validUserIds);

    const insertSquadMention = useCallback((squad: SquadOverview) => {
        insert(
            newSquadMention(squad.id),
            query.trigger + squadNameForMention(squad),
            query.range,
        );
    }, [insert, query]);

    const insertUserMention = useCallback((user: UserOverview) => {
        insert(
            newUserMention(user.id),
            query.trigger + userNameForMention(user),
            query.range,
        );
    }, [insert, query]);

    const suggest = useCallback(
        <E extends { name: string; nickname?: string; }>(entities: E[]) => {
            if (!query.text) {
                return entities
                    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            }

            const searchText = (e: E) => `${e.nickname ?? ""} ${e.name}`;
            return entities
                .filter(e => similaritySearch(query.text, searchText(e), similarityThreshold))
                .sort((a, b) => similaritySort(query.text, a.name, b.name));
        },
        [query.text, similarityThreshold],
    );

    const suggestionsForEmail = useMemo(
        () => {
            if (
                !EmailInvitationSuggestionContent ||
                !emailInvitationCb ||
                !isEmailAddress(query.text)
            ) return [];

            return [{
                key: "email",
                className: "c-suggestion--email",
                Content: partialComponent<EmailInvitationSuggestionProps, SuggestionContentProps>(
                    EmailInvitationSuggestionContent,
                    { email: query.text },
                ),
                onAdd: () => emailInvitationCb(query.text),
            }];
        },
        [query.text, EmailInvitationSuggestionContent, emailInvitationCb],
    );

    const suggestedSquads = useShallowEqualsMemo(
        () => suggest(validSquads),
        [suggest, validSquads],
    );
    const suggestionsForSquads = useShallowEqualsMemo(
        () => {
            if (!SquadMentionSuggestionContent) return [];

            return suggestedSquads.map(squad => ({
                key: squad.id,
                className: "c-suggestion--squad",
                Content: partialComponent<SquadMentionSuggestionProps, SuggestionContentProps>(
                    SquadMentionSuggestionContent,
                    { squad },
                ),
                onAdd: () => insertSquadMention(squad),
            }));
        },
        [suggestedSquads, SquadMentionSuggestionContent, insertSquadMention],
    );

    const suggestedUsers = useShallowEqualsMemo(
        () => {
            // The extra users are a bit of a hack for the squads management lookup
            return [...(extraUsers || []), ...suggest(validUsers)];
        },
        [suggest, extraUsers, validUsers],
    );
    const suggestionsForUsers = useShallowEqualsMemo(
        () => {
            if (!UserMentionSuggestionContent) return [];

            // The memoisation would be improved if we provide just the user ID
            // to the component, as opposed to the full overview.
            return suggestedUsers.map(user => ({
                key: user.id,
                className: "c-suggestion--human",
                Content: partialComponent<UserMentionSuggestionProps, SuggestionContentProps>(
                    UserMentionSuggestionContent,
                    { user },
                ),
                onAdd: () => insertUserMention(user),
            }));
        },
        [suggestedUsers, UserMentionSuggestionContent, insertUserMention],
    );

    const suggestionGroups = useShallowEqualsMemo(
        () =>
            [
                suggestionsForEmail,
                suggestionsForSquads,
                suggestionsForUsers,
            ].filter(g => g.length > 0),
        [suggestionsForEmail, suggestionsForSquads, suggestionsForUsers],
    );

    const suggestionCount = useMemo(
        () => suggestionGroups.flat().length,
        [suggestionGroups],
    );

    useMetaInterest(suggestionCount > 0, MetaInterestCounterKey.BlockMsgCompletion);

    return {
        suggestionGroups,
        suggestionCount,
    };
}
