import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import BondsCardView from "./BondsCardView";
import BondsListView from "./BondsListView";

// The "Inbox" view on the Phase3 UI, or the cards view on Phase 2.
export function MyBondsView(): React.JSX.Element {
    const isPhase3 = useBooleanFeatureFlag("phase-3-ui");

    return isPhase3 ? <BondsListView /> : <BondsCardView />;
}
