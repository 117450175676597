import * as Sentry from "@sentry/react";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useAppDispatch, useAppSelector } from "../store/redux";
import log, { getArchivedLogsFormatted } from "../misc/log";
import { selectCurrentUser } from "../features/users";
import { CloseButton } from "./buttons/Close";
import { setUser as sentrySetUser, setContext as sentrySetContext } from "@sentry/react";
import {
    selectBackendInfo,
    selectConnectionIdentifiers,
    selectShowReportIssueDialog,
    showReportIssueDialog,
} from "../features/meta";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { FeatureFlagged } from "./FeatureFlags";
import { getRecentRtcStats, getRtcStats } from "../hooks/rtc/useRtcClient";
import { RtcSessionId } from "../domain/domain";
import { randomHexString } from "../misc/utils";

function SentryReportDialogInternal(
    { show, close: closeCallback }: { show: boolean; close: () => void; },
) {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const currentUser = useAppSelector(selectCurrentUser);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.focus();
        }
    }, []);

    const close = useCallback(() => {
        setDescription("");
        formRef.current?.reset();
        closeCallback();
    }, [closeCallback, formRef]);

    const onSubmit = useCallback(async (ev: FormEvent) => {
        log.info(
            "User send feedback requested, sending to Sentry",
            description,
            currentUser,
        );

        const currentRtcDump = getRtcStats();
        const recentRtcDump = getRecentRtcStats();

        ev.preventDefault();

        if (!description) {
            return;
        }

        const replay = Sentry.getReplay();
        if (replay) {
            try {
                await replay.flush();
            }
            catch (e) {
                console.warn("Failed to upload replay in user feedback", e);
            }
        }

        const archivedLogLines = await getArchivedLogsFormatted();
        const attachments: {
            filename: string;
            data: string | Uint8Array;
        }[] = [
            { filename: "log.txt", data: archivedLogLines.join("\n") },
        ];

        const pushRtcFile = (isJoined: boolean) => (dump: string[], id: RtcSessionId) =>
            attachments.push({
                filename: `rtc-${isJoined ? "curr" : "last"}-${id}.txt`,
                data: dump.join("\n"),
            });
        if (currentRtcDump.size > 0) {
            currentRtcDump.forEach(pushRtcFile(true));
        }
        if (recentRtcDump.lastSessionId && recentRtcDump.lastSessionStats.length > 0) {
            pushRtcFile(false)(recentRtcDump.lastSessionStats, recentRtcDump.lastSessionId);
        }

        for (let i = 0; i < (fileRef.current?.files?.length ?? 0); i++) {
            const file = fileRef.current?.files?.item(i);
            if (file) {
                const data = await file.arrayBuffer();
                attachments.push({ filename: file.name, data: new Uint8Array(data) });
            }
        }

        const event_message = "Event for send feedback: " + randomHexString();

        const eid = Sentry.captureEvent({
            message: event_message,
            user: {
                id: currentUser?.id,
            },
        }, {
            attachments: attachments,
        });

        Sentry.captureUserFeedback({
            event_id: eid,
            comments: description,
            email: currentUser?.email,
            name: currentUser?.name || "unknown user",
        });

        log.info(`Feedback captured, event ID ${eid} message ${event_message}`);

        close();
    }, [currentUser, description, close]);

    useEffect(() => {
        if (show) {
            dialogRef.current?.showModal();
        }
        else {
            dialogRef.current?.close();
        }
    }, [show, dialogRef]);

    useEffect(() => {
        setSubmitEnabled(description.length > 0);
    }, [description]);

    const closeIfOutside = useDialogOutsideClick(dialogRef, close);

    // Bug: autoFocus broken inside <dialog /> https://github.com/facebook/react/issues/23301
    useEffect(() => {
        if (!descriptionRef.current) return;
        descriptionRef.current.setAttribute("autofocus", "true");
    }, []);

    return (
        <dialog
            ref={dialogRef}
            className="c-dialog c-dialog--report-issue"
            onMouseDown={closeIfOutside}
        >
            <header className="c-dialog__header">
                <h1 className="c-dialog__title">Report issue 🐛</h1>
                <CloseButton side="right" onClick={close} />
            </header>

            <div className="c-dialog__content">
                <form onSubmit={onSubmit} ref={formRef}>
                    <fieldset>
                        <div className="c-form-element c-form-element--inline">
                            <label className="c-label c-label--inline" htmlFor="userfeedback_name">
                                Name:
                            </label>
                            <input
                                type="text"
                                id="userfeedback_name"
                                readOnly={true}
                                value={currentUser?.name || ""}
                                className="c-input"
                            />
                        </div>
                        <div className="c-form-element c-form-element--inline">
                            <label className="c-label c-label--inline" htmlFor="userfeedback_email">
                                Email:
                            </label>
                            <input
                                type="email"
                                id="userfeedback_email"
                                readOnly={true}
                                value={currentUser?.email || ""}
                                className="c-input"
                            />
                        </div>
                        <div className="c-form-element c-form-element--margin">
                            <label
                                className="c-label c-label--top"
                                htmlFor="userfeedback_description"
                            >
                                Description:
                            </label>
                            <textarea
                                id="userfeedback_description"
                                rows={4}
                                ref={descriptionRef}
                                value={description}
                                onChange={() => setDescription(descriptionRef.current?.value || "")}
                                tabIndex={0}
                                className="c-textarea"
                            />
                        </div>
                        <div className="c-form-element c-form-element--upload">
                            <label
                                className="c-label c-label--inline"
                                htmlFor="userfeedback_attachment"
                            >
                                Attachments:
                            </label>
                            <input
                                type="file"
                                id="userfeedback_attachment"
                                name="userfeedback_attachment"
                                multiple={true}
                                ref={fileRef}
                                className="cp-btn c-fileupload c-fileupload--report"
                                title="Upload attachments"
                            />
                        </div>
                    </fieldset>
                    <div className="c-btn-group">
                        <input
                            type="submit"
                            className="c-btn-submit-report"
                            disabled={!submitEnabled}
                        />
                    </div>
                </form>
            </div>
        </dialog>
    );
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
function keyboardShortcut() {
    if (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
    ) {
        return ["⌘-B", "meta+b"]; // command key
    }
    return ["Ctrl-B", "ctrl+b"];
}

export function SentryReportButton(
    { buttonClassNames = "c-btn c-btn-report" }: { buttonClassNames?: string; },
) {
    const currentUser = useAppSelector(selectCurrentUser);
    const deviceIds = useAppSelector(selectConnectionIdentifiers);
    const backendInfo = useAppSelector(selectBackendInfo);
    const dispatch = useAppDispatch();
    const showDialog = useCallback(() => dispatch(showReportIssueDialog(true)), [dispatch]);

    useEffect(() => {
        if (!currentUser) return;
        sentrySetUser({
            id: currentUser.id,
            email: currentUser.email,
            fullName: currentUser.name,
        });
    }, [currentUser]);

    useEffect(() => {
        if (!deviceIds) return;
        sentrySetContext("connection", deviceIds);
    }, [deviceIds]);

    useEffect(() => {
        if (!backendInfo) return;
        sentrySetContext("backendInfo", backendInfo);
    }, [backendInfo]);

    return (
        <FeatureFlagged flag={"report-issue-button"} match={true}>
            <button
                type="button"
                onClick={() => showDialog()}
                className={buttonClassNames}
            >
                Report issue 🐛
                <span className="c-btn-report__shortcut">{keyboardShortcut()[0]}</span>
            </button>
        </FeatureFlagged>
    );
}

export function SentryReportDialog() {
    const dispatch = useAppDispatch();
    const showDialog = useCallback((show: boolean) => dispatch(showReportIssueDialog(show)), [
        dispatch,
    ]);
    const isDialogVisible = useAppSelector(selectShowReportIssueDialog);

    useHotkeys(
        keyboardShortcut()[1],
        () => showDialog(!isDialogVisible),
        {
            preventDefault: true,
            enableOnFormTags: ["textarea"],
        },
        [showDialog, isDialogVisible],
    );

    return (
        <FeatureFlagged flag={"report-issue-button"} match={true}>
            <SentryReportDialogInternal show={isDialogVisible} close={() => showDialog(false)} />
        </FeatureFlagged>
    );
}
