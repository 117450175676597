import { FC, useCallback, useRef } from "react";
import { bondCreationDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { DraftChatMessage } from "../domain/messages";
import { selectCurrentOrgId } from "../features/auth";
import { createBondFromMessageThunk, selectBondCreationTitle } from "../features/bondCreation";
import { clearFreshBond } from "../features/bonds";
import { useNavigateBack } from "../hooks/useNavigateBack";
import log from "../misc/log";
import { Focusable, Optional } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { FeatureFlagged } from "./FeatureFlags";
import MessageComposer from "./MessageComposer";
import RichTextMessageComposer from "./RichTextMessageComposer";

const placeholder = "Message...";

interface NewBondMessageComposerProps {
    backAction: () => void;
}

export const NewBondMessageComposer: FC<NewBondMessageComposerProps> = ({ backAction }) => {
    const dispatch = useAppDispatch();
    const { navigate } = useNavigateBack();
    const composerRef = useRef<Focusable>(null);
    const orgId = useAppSelector(selectCurrentOrgId);
    const title = useAppSelector(selectBondCreationTitle);

    // const onComposerModalChange = useCallback(() => {}, []);
    // const onEditorFocus = useCallback(() => {}, []);
    // const onEditorBlur = useCallback(() => {}, []);

    const createBondFromMsg = useCallback(async (msg: Optional<DraftChatMessage>) => {
        if (!msg) return;

        // In future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond`);
            return;
        }

        log.info("Creating bond with message");

        const newBond = await dispatch(createBondFromMessageThunk({ orgId, title, msg })).unwrap();

        dispatch(clearFreshBond({}));
        navigate("/bond/" + d.extractUUID(newBond.id), { replace: true });
    }, [
        dispatch,
        orgId,
        title,
        navigate,
    ]);

    return (
        <FeatureFlagged
            flag={"rich-text-composer"}
            match={true}
            wrapWithDiv={false}
            fallback={
                <MessageComposer
                    id="comms-input-bond-creation"
                    key="comms-input-bond-creation"
                    ref={composerRef}
                    draftTarget={bondCreationDraftTarget}
                    msgCompletionAction={createBondFromMsg}
                    escapeAction={backAction}
                    bondComposer={true}
                    placeholder={placeholder}
                    // onModalChange={onComposerModalChange}
                    // onTextAreaFocus={onEditorFocus}
                    // onTextAreaBlur={onEditorBlur}
                />
            }
        >
            <RichTextMessageComposer
                id="comms-input-bond-creation"
                key="comms-input-bond-creation"
                ref={composerRef}
                draftTarget={bondCreationDraftTarget}
                msgCompletionAction={createBondFromMsg}
                escapeAction={backAction}
                bondComposer={true}
                placeholder={placeholder}
                // onModalChange={onComposerModalChange}
                // onEditorFocus={onEditorFocus}
                // onEditorBlur={onEditorBlur}
            />
        </FeatureFlagged>
    );
};

export default NewBondMessageComposer;
