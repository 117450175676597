import { FC } from "react";

interface NewBondAudienceInputProps {}

// TODO: This should fill a squad in if it was clicked from a squad view. So we should
//       pass an optional squad ID in through the props.
//       For now, this is just a nasty hack to expose the message composer so we have
//       some way of creating a new bond.
export const NewBondAudienceInput: FC<NewBondAudienceInputProps> = _props => <></>;

export default NewBondAudienceInput;
