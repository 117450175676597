// Phase 3 bond card
import * as d from "../domain/domain";
import {
    selectBondById,
    selectBondIsArchived,
    selectBondSummary,
    selectBondTitle,
    selectIsBondLive,
    selectSortedBondLiveParticipantIdsPair,
    streamBond,
} from "../features/bonds";
import { selectKnownSquadNames } from "../features/squads";
import useInterestedSquads from "../hooks/interest/useInterestedSquads";
import useSelectorArgs from "../hooks/useSelectorArgs";
import TimeAgo from "./gui/TimeAgo";
import SensitiveText from "./gui/SensitiveText";
import { selectUser } from "../features/users";
import useSortedUsers from "../hooks/useSortedUsers";
import {
    selectIsRead,
    selectSortedUserIdsWithUnreadPair,
    selectUserIdSetWithMentions,
} from "../features/channels";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { removeDuplicates } from "../misc/primatives";
import { memo, useCallback } from "react";
import useSlider from "../hooks/useSlider";
import { isMobileBrowser } from "../misc/mobile";
import useFreshBondObservers from "../hooks/useFreshBondObservers";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import { Human } from "./gui/AvatarV3";
import classNames from "classnames";
import useStreamDispatch from "../hooks/useStreamDispatch";
import { Optional } from "../misc/types";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { useAppSelector } from "../store/redux";
import { selectCurrentUserId } from "../features/auth";
import useAddressParams from "../hooks/useAddressParams";
import { BondDismissButton, BondFollowButton, BondReadButton } from "./gui/BondActions";
import useInterestedCall from "../hooks/interest/useInterestedCall";

export interface BondCardProps {
    id: d.BondId;
    score?: number;
    onClick?: (id: d.BondId, channelId: Optional<d.ChannelId>) => void;
    bondType?: string;
    isFirstCard: boolean;
    isLastCard: boolean;
}

function UserName(
    { userId, comma, and, last, additional, isRead }: {
        userId: d.UserId;
        comma: boolean;
        and: boolean;
        last: boolean;
        additional: number;
        isRead: boolean;
    },
): React.JSX.Element {
    const user = useSelectorArgs(selectUser, userId);
    const classes = classNames("c-mention", {
        "c-mention--read": isRead,
    });

    return (
        <span className={classes}>
            {user?.nickname}
            {comma && ", "}
            {and && " & "}
            {last && !!additional && ` + ${additional}`}
            {!comma && !and && " "}
        </span>
    );
}

export function BondCardContributors({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const bondOverview = useSelectorArgs(selectBondById, bondId);
    const channelId = bondOverview?.channelId;
    const liveParticipantIds = useSortedUsers(selectSortedBondLiveParticipantIdsPair, bondId);
    const participantIds = useSortedUsers(selectSortedUserIdsWithUnreadPair, channelId);
    const isRead = useSelectorArgs(selectIsRead, channelId);

    // FIXME: just a hack to give us a contributor list when the bond is caught up. What
    // this should really be doing is remembering the list of contributors at the point
    // the bond was caught up, and then showing that list here.
    const previewUsers = useShallowEqualsMemo(() => {
        const finalIds = participantIds.length == 0 ? (bondOverview?.contributors || [])
            : participantIds;

        return removeDuplicates(
            liveParticipantIds,
            finalIds,
        );
    }, [
        liveParticipantIds,
        participantIds,
        bondOverview,
    ]);

    const processed = useShallowEqualsMemo(() => {
        const slicedPreviewUsers = previewUsers.slice(0, 3);
        const additionalUsers = previewUsers.length - slicedPreviewUsers.length;

        return {
            slicedPreviewUsers,
            additionalUsers,
        };
    }, [previewUsers]);

    return (
        <>
            {processed.slicedPreviewUsers.map((cid, idx, arr) => {
                const last = idx + 1 === arr.length;
                const and = idx + 2 === arr.length;
                const comma = idx + 2 < arr.length;

                return (
                    <UserName
                        key={cid}
                        userId={cid}
                        comma={comma}
                        and={and}
                        last={last}
                        additional={processed.additionalUsers}
                        isRead={isRead}
                    />
                );
            })}
        </>
    );
}

function BondSummary({ bondId }: { bondId: d.BondId; }) {
    // FIXME: the summary logic is quite different in phase 3.
    // 1. If a catchup summary is possible, show that (i.e. if it's been generated).
    // 1.b. Catchup summaries should stay the same as the user reads the bond and be
    //      frozen in time until there is new activity on the bond.
    // 2. Else, show the regular summary.
    const bondSummary = useSelectorArgs(selectBondSummary, bondId) || "";

    return bondSummary && <SensitiveText>{bondSummary}</SensitiveText>;
}

// See BondCardAvatars for the V2 impl
function BondCardPresence({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const { ids: observerIds } = useFreshBondObservers(bondId);
    const liveParticipantIds = useSortedUsers(selectSortedBondLiveParticipantIdsPair, bondId);
    const isLive = useSelectorArgs(selectIsBondLive, bondId) || false;
    const isMobile = isMobileBrowser();

    const previewUsers = useShallowEqualsMemo(() =>
        removeDuplicates(
            liveParticipantIds,
            observerIds,
        ), [
        liveParticipantIds,
        observerIds,
    ]);
    useInterestedUsers(previewUsers);

    const classes = classNames("c-card-presence", {
        "c-card-presence--live": isLive,
        "c-card-presence--mobile": isMobile,
    });

    const classesHuman = classNames("c-card-presence__humans", {
        "c-card-presence__humans--mobile": isMobile,
    });

    if (previewUsers.length === 0) {
        return <></>;
    }
    return (
        <div className={classes}>
            <div className={classesHuman}>
                {previewUsers.map(cid => (
                    <Human
                        key={cid}
                        userId={cid}
                        context={{ bondCard: true, isMobile }}
                    />
                ))}
            </div>
        </div>
    );
}

const Swiper = (
    { id, children, className }: {
        id: string;
        children: React.ReactNode;
        className: string;
    },
): React.JSX.Element => {
    const isMobile = isMobileBrowser();

    const swipeRef = useSlider(id, {
        swipeSensitivity: 0.5,
        revealWidthLeft: 80,
        revealWidthRight: 160,
        snapThreshold: 3,
    });

    return <div className={className} ref={isMobile && swipeRef || undefined}>{children}</div>;
};

function BondCardInner(props: BondCardProps): React.JSX.Element {
    const { id, onClick, isFirstCard, isLastCard } = props;
    const bo = useSelectorArgs(selectBondById, id);

    const squadIds = bo?.squadIds || [];
    const channelId = bo?.channelId;
    const contributors = bo?.contributors;
    const liveCallId = bo?.liveCallIds[0];

    const currentUserId = useAppSelector(selectCurrentUserId);
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);

    const isArchived = useSelectorArgs(selectBondIsArchived, id) || false;
    const isLive = useSelectorArgs(selectIsBondLive, id) || false;
    const isMentioned = userSetWithMentions.size > 0;
    const isRead = useSelectorArgs(selectIsRead, channelId);
    const isPrivate = squadNames.length === 0;
    const isFollowed = currentUserId && bo && bo.followers.includes(currentUserId) || false;
    const bondTitle = useSelectorArgs(selectBondTitle, id);
    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const { squadId } = useAddressParams();
    const isSquadView = squadId !== undefined;

    // The squad and user names should be combined, such that we show any squad, and any
    // user who is not in that squad who has access to the bond (therefore private bonds
    // are just lists of users). This isn't quite right yet as we don't handle bonds with
    // squad and usernames needing showing.
    // We also need a sensible ellipsis strategy - we probably want to truncate to e.g.
    // "+2 more" rather than just "...".
    // const squadOrUserNames = squadNames.length === 0 ? userNames : squadNames;

    const timeFrom = bo?.lastActivityAt || 0;

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    // Title should not show for a "short direct message". This logic is an attempt at
    // codifying that, but it's not quite right yet.
    const showTitle = !bondTitle.aiGenerated || !isPrivate ||
        (contributors || []).length > 1;

    const onCardClick = useCallback(() => {
        onClick?.(id, channelId);
    }, [id, channelId, onClick]);

    // XXX: we need the full bond overview to get the list of contributors, as for the time
    // being that is what we're using to show the list of people on this card. The stream of
    // bond *previews* does not contain this information today.
    // We probably want to revisit this area.
    useStreamDispatch(() => id && streamBond(id), [id]);

    useInterestedSquads(squadIds);
    useInterestedUsers(contributors);
    useInterestedCall(liveCallId);

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const topClasses = classNames("c-card", {
        "c-card--live": isLive,
        "c-card--first": isFirstCard && !isLastCard,
        "c-card--single": !isFirstCard && !isLastCard,
        "c-card--last": !isFirstCard && isLastCard,
        "c-card--only": isFirstCard && isLastCard,
        "c-card--desktop": isDesktop,
    });
    const contentClasses = classNames("c-card__content", {
        "c-card__content--live": isLive,
        "c-card__content--desktop": !isMobile,
    });
    const titleClasses = classNames("c-card__title", "u-clamp_1", {
        "c-card__title--read": isRead,
    });
    const summaryClasses = classNames("c-card__summary", "u-clamp_2", {
        "c-card__summary--read": isRead,
    });
    const notificationClasses = classNames("c-card-notification", {
        "c-card-notification--live": isLive,
        "c-card-notification--mentioned": isMentioned,
        "c-card-notification--desktop": !isMobile,
    });
    const timeClasses = classNames("c-card__time", {
        "c-card__time--read": isRead,
    });
    const wrapperClasses = classNames("c-card-wrapper", {
        "c-card-wrapper--desktop": !isMobile,
    });
    const detailWrapperClasses = classNames("c-card__details-wrapper", {
        "c-card__details-wrapper--desktop": !isMobile,
    });

    return (
        <div
            className={wrapperClasses}
            onClick={onCardClick}
        >
            <div
                className={topClasses}
            >
                {isMobile && (
                    <div className="c-card__controls c-card-controls">
                        <div className="c-card-controls__left">
                            <BondReadButton
                                isRead={isRead}
                                channelId={channelId}
                                isMobile={true}
                            />
                        </div>
                        <div className="c-card-controls__right">
                            {!isSquadView && (
                                <BondDismissButton
                                    id={id}
                                    channelId={channelId}
                                    isArchived={isArchived}
                                    isMobile={true}
                                />
                            )}
                            {!isPrivate && (
                                <BondFollowButton id={id} isFollowed={isFollowed} isMobile={true} />
                            )}
                        </div>
                    </div>
                )}
                <Swiper id={id} className={contentClasses}>
                    <div className="c-card__header">
                        {!isRead && !isLive && (
                            <span
                                className={notificationClasses}
                            >
                            </span>
                        )}
                        {showTitle && (
                            <h3
                                className={titleClasses}
                            >
                                {title}
                            </h3>
                        )}
                        <time className={timeClasses}>
                            <TimeAgo from={timeFrom} live={true} />
                        </time>
                        {isDesktop && (
                            <div className="c-card__controls c-card__controls--desktop">
                                {!isPrivate && (
                                    <BondFollowButton
                                        id={id}
                                        isFollowed={isFollowed}
                                        isMobile={false}
                                    />
                                )}
                                {!isSquadView && (
                                    <BondDismissButton
                                        id={id}
                                        channelId={channelId}
                                        isArchived={isArchived}
                                        isMobile={false}
                                    />
                                )}
                                <BondReadButton
                                    channelId={channelId}
                                    isRead={isRead}
                                    isMobile={false}
                                />
                            </div>
                        )}
                    </div>
                    <div className={detailWrapperClasses}>
                        <div className="c-card__details">
                            <p
                                className={summaryClasses}
                            >
                                <BondCardContributors bondId={id} />
                                <BondSummary bondId={id} />
                            </p>
                        </div>
                        <BondCardPresence bondId={id} />
                    </div>
                </Swiper>
            </div>
        </div>
    );
}

export const BondCard = memo(BondCardInner);
