import IntervalTree, { Interval } from "@flatten-js/interval-tree";
import omit from "lodash.omit";
import { InsertOp, MarkupDelta, markupDeltaBuilder } from "../domain/delta";
import {
    DraftChatContent_V1,
    genCaretHintId,
    getDraftPrefixContentMentions,
    getDraftPrefixWithPadding,
} from "../domain/draftChatContent";
import { ContentMention, Mention } from "../domain/mentions";
import { NumberRange, Optional } from "./types";
import { clamp } from "./utils";

// TODO: remove this file once rich text is fully rolled out

/**
 * @deprecated
 */
export interface ChangeSelections {
    oldSelectionStart: number;
    oldSelectionEnd: number;

    newSelectionEnd: number;
}

/**
 * @deprecated
 */
export type ContentMutation<T> = (
    draft: DraftChatContent_V1,
    args: T,
) => DraftChatContent_V1;

/**
 * @deprecated
 */
const spliceString = (
    oldText: Optional<string>,
    range: NumberRange,
    insertedText: string,
): string =>
    (oldText === undefined) ?
        insertedText :
        oldText.substring(0, range.start) + insertedText + oldText.substring(range.end);

/**
 * @deprecated
 */
const contentMentionToInterval = (
    contentMention: ContentMention,
): [number, number] => [contentMention.startOffset + 1, contentMention.endOffset - 1];

/**
 * @deprecated
 */
const intervalToContentMention = (
    interval: [number, number],
    mention: Mention,
): ContentMention => ({
    ...mention,
    startOffset: interval[0] - 1,
    endOffset: interval[1] + 1,
});

/**
 * @deprecated
 */
const spliceContent = (
    oldContent: DraftChatContent_V1,
    oldRange: NumberRange,
    insertedText: string,
): {
    newContent: DraftChatContent_V1;
    newRange: NumberRange;
} => {
    const { draftMessage: oldMessage, mentions: oldMentions } = oldContent;

    // Build an interval tree from the existing mentions
    const mentionTree = new IntervalTree<Mention>();
    for (const mention of oldMentions ?? []) {
        mentionTree.insert(contentMentionToInterval(mention), mention);
    }

    // Delete any mentions that fall partially or wholly within the spliced section from the tree
    const toDeleteMentions = mentionTree.search(
        [oldRange.start, oldRange.end],
        (m, i) => [m, i],
    ) as [Mention, Interval][];
    for (const [mention, interval] of toDeleteMentions) {
        mentionTree.remove(interval, mention);
    }

    // Calculate the full splice range and splice the message (this could be bigger than
    // the requested range if deleted mentions extend beyond it)
    const splice = {
        start: Math.min(oldRange.start, ...toDeleteMentions.map(([, i]) => i.low - 1)),
        end: Math.max(oldRange.end, ...toDeleteMentions.map(([, i]) => i.high + 1)),
    };
    const newMessage = spliceString(
        oldMessage,
        splice,
        insertedText,
    );
    const lengthDiff = newMessage.length - oldMessage.length;

    // Create the new mentions from those left in the tree, offsetting the mentions that
    // fall to the right of the splice range
    const newMentions = mentionTree.items.map(
        (item: { key: Interval; value: Mention; }): ContentMention => {
            const key = item.key as unknown as [number, number]; // `key` is incorrectly typed by the library spec
            const mention = intervalToContentMention(key, item.value);
            if (key[0] >= splice.end) {
                mention.startOffset += lengthDiff;
                mention.endOffset += lengthDiff;
            }
            return mention;
        },
    ).replaceFrom(oldMentions);

    // Calculate the start and end offsets in the new string
    const newRange = {
        start: splice.start,
        end: splice.start + insertedText.length,
    };

    // Determine whether the prefix was removed or brought into the message
    const oldPrefixLength = oldContent.prefix?.length ?? 0;
    const newPrefixLength = splice.start >= oldPrefixLength ? oldPrefixLength : 0;

    const newPrefixPadding = clamp(0, oldContent.prefix?.padding ?? 0)(
        splice.start - oldPrefixLength,
    );

    return {
        newContent: {
            ...oldContent,
            draftMessage: newMessage,
            trimmedMessage: newMessage.trim(),
            mentions: newMentions,
            prefix: {
                length: newPrefixLength,
                padding: newPrefixPadding,
            },
        },
        newRange,
    };
};

/**
 * Applies a change from the plain text textarea to the underlying marked up value
 * guided by the textarea text selection ranges before and after the change
 * @deprecated
 */
export const applyTextToContent = (
    oldContent: DraftChatContent_V1,
    args: {
        newText: string;
        selections: ChangeSelections;
    },
): DraftChatContent_V1 => {
    const { draftMessage: oldText } = oldContent;
    const { newText, selections: { oldSelectionStart, oldSelectionEnd, newSelectionEnd } } = args;

    // Determine the text that was inserted from the old and new selections
    const insertedText = newText.slice(oldSelectionStart, newSelectionEnd);

    // Determine the range of text to replace in the old content
    const spliceStart = Math.min(oldSelectionStart, newSelectionEnd);
    let spliceEnd = oldSelectionEnd;
    if (oldSelectionStart === newSelectionEnd) {
        spliceEnd = Math.max(
            oldSelectionEnd,
            oldSelectionStart + oldText.length - newText.length,
        );
    }

    const { newContent, newRange: { end: newEnd } } = spliceContent(
        oldContent,
        { start: spliceStart, end: spliceEnd },
        insertedText,
    );

    return {
        ...newContent,
        caretHint: {
            location: newEnd,
            id: genCaretHintId(),
        },
    };
};

/**
 * Inserts a string to the underlying marked up draft message, replacing the substring
 * with the given start and end offsets
 * @deprecated
 */
export const insertTextIntoContent = (
    oldContent: DraftChatContent_V1,
    args: {
        insertedText: string;
        range: NumberRange;
    },
): DraftChatContent_V1 => {
    const { insertedText, range } = args;

    const { newContent, newRange: { end: newEnd } } = spliceContent(
        oldContent,
        range,
        insertedText,
    );

    return {
        ...newContent,
        caretHint: {
            location: newEnd,
            id: genCaretHintId(),
        },
    };
};

/**
 * @deprecated
 */
const insertMentionIntoContentInternal = (
    oldContent: DraftChatContent_V1,
    mention: Mention,
    text: string,
    oldRange: NumberRange,
    padding: string,
): DraftChatContent_V1 => {
    const { newContent, newRange: { start: newStart, end: newEnd } } = spliceContent(
        oldContent,
        oldRange,
        `${text}${padding}`,
    );

    // Insert the new mention maintaining the offset ordering
    const newMentions = newContent.mentions ?? [];
    newMentions.push({
        ...mention,
        startOffset: newStart,
        endOffset: newStart + text.length,
    });
    newMentions.sort((a, b) => a.startOffset - b.startOffset);

    return {
        ...newContent,
        mentions: newMentions,
        caretHint: {
            location: newEnd,
            id: genCaretHintId(),
        },
    };
};

/**
 * Inserts a mention to the underlying marked up draft message, replacing the substring
 * with the given start and end offsets
 * @deprecated
 */
export const insertMentionIntoContent = (
    oldContent: DraftChatContent_V1,
    args: {
        mention: Mention;
        text: string;
        range: NumberRange;
    },
): DraftChatContent_V1 => {
    const { mention, text, range } = args;
    return insertMentionIntoContentInternal(oldContent, mention, text, range, " ");
};

/**
 * Remove the prefix from the content by replacing it with an empty string
 * @deprecated
 */
export const removePrefixFromContent = (
    oldContent: DraftChatContent_V1,
): DraftChatContent_V1 => {
    const prefixLengthWithPadding = (oldContent.prefix?.length ?? 0) +
        (oldContent.prefix?.padding ?? 0);

    if (prefixLengthWithPadding === 0) return oldContent;

    return insertTextIntoContent(
        oldContent,
        {
            insertedText: "",
            range: { start: 0, end: prefixLengthWithPadding },
        },
    );
};

/**
 * Replace or add a prefix to the message containing the given mention
 * @deprecated
 */
export const setPrefixMentionInContent = (
    oldContent: DraftChatContent_V1,
    mention: Mention,
    text: string,
): DraftChatContent_V1 => {
    const strippedContent = removePrefixFromContent(oldContent);

    const padding = " ";

    const newContent = insertMentionIntoContentInternal(
        strippedContent,
        mention,
        text,
        { start: 0, end: 0 },
        padding,
    );

    return {
        ...newContent,
        prefix: {
            length: text.length,
            padding: padding.length,
        },
    };
};

/**
 * @deprecated
 */
export const clearContent = (
    oldContent: DraftChatContent_V1,
): DraftChatContent_V1 => ({
    ...oldContent,
    draftMessage: getDraftPrefixWithPadding(oldContent),
    mentions: getDraftPrefixContentMentions(oldContent),
    caretHint: {
        location: undefined,
        id: genCaretHintId(),
    },
});

/**
 * @deprecated
 */
export const promotePrefixInContent = (
    oldContent: DraftChatContent_V1,
): DraftChatContent_V1 => ({
    ...oldContent,
    prefix: { length: 0, padding: 0 },
});

/**
 * Convert old style message content (text and content mentions list) to a `MarkupDelta` with
 * text and mention insert operations
 * @deprecated
 */
export const contentToMarkupDelta = (text: string, mentions: ContentMention[]): MarkupDelta => {
    const sortedMentions = [...mentions].sort((a, b) => a.startOffset - b.startOffset);
    return markupDeltaBuilder()
        .pushAll(
            [
                0,
                ...(sortedMentions.flatMap(m => [m.startOffset, m.endOffset])),
                text.length,
            ]
                .windows(2)
                .map(w => text.substring(w[0], w[1]))
                .map((s, i) =>
                    (i % 2 == 0) ?
                        s :
                        {
                            mention: {
                                ...omit(
                                    sortedMentions[Math.floor(i / 2)],
                                    "startOffset",
                                    "endOffset",
                                ),
                                text: s,
                            },
                        }
                )
                .filter(d => d != "")
                .map<InsertOp>(d => ({ insert: d })),
        )
        .build();
};
