import { ReactEventHandler, useCallback, useEffect, useMemo, useRef } from "react";
import ParticipantTile, { ParticipantType } from "../components/gui/ParticipantTile";
import {
    Participant,
    OrderedParticipant,
    orderParticipants,
    hasActiveVideo,
    getAudio,
    getVideo,
    isDisplay,
    getParticipantUserId,
    isSelfView,
    getTileId,
} from "../domain/rtc";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { selectCurrentUserId } from "../features/auth";
import {
    closeLiveView,
    selectFocusedTileId,
    setFocusedTileId,
    setGridTiles,
} from "../features/calls";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import useGridLayout from "../hooks/useGridLayout";
import useMap from "../hooks/useMap";
import { Optional } from "../misc/types";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import classNames from "classnames";

interface BondLiveGridViewProps {
    participants: Participant[];
}

// setting aspect ratio to 0 before metadata loads prevents existing tiles jumping around
const defaultAspectRatio = 0;

// BondLiveGridView renders the participant tiles in a call, and handles (un)focusing them
export default function BondLiveGridView(props: BondLiveGridViewProps): React.JSX.Element {
    const { participants } = props;

    const dispatch = useAppDispatch();

    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");

    const focusedTileId = useAppSelector(selectFocusedTileId);
    const currentUserId = useAppSelector(selectCurrentUserId);

    const { orderedParticipants } = useMemo(
        () => orderParticipants(participants),
        [participants],
    );

    const videoParticipants = useShallowEqualsMemo(
        () => orderedParticipants.filter(hasActiveVideo),
        [orderedParticipants],
    );

    const isSelf = useCallback(
        (p: OrderedParticipant) => isSelfView(p, currentUserId),
        [currentUserId],
    );

    const videoParticipantsLessSelf = useShallowEqualsMemo(
        () => videoParticipants.filter(p => !isSelf(p)),
        [videoParticipants, isSelf],
    );

    // Show all video participants in grid, other than self-view (unless it is the only one)
    const gridParticipants = videoParticipantsLessSelf.length < 1 ? videoParticipants
        : videoParticipantsLessSelf;

    const focusedParticipant = useMemo(() => {
        if (!focusedTileId) {
            return undefined;
        }

        return videoParticipants.find(p => getTileId(p) === focusedTileId);
    }, [videoParticipants, focusedTileId]);

    const gridOrFocusedParticipants = useShallowEqualsMemo(
        () => focusedParticipant ? [focusedParticipant] : gridParticipants,
        [focusedParticipant, gridParticipants],
    );

    // Toggle the focused participant back to undefined if selected twice in a row
    const toggleFocused = useCallback((p: OrderedParticipant) => {
        const newFocusedTileId = focusedTileId !== getTileId(p) ? getTileId(p) : undefined;
        dispatch(setFocusedTileId(newFocusedTileId));
    }, [dispatch, focusedTileId]);

    // Handle un-focus when participant leaves or turns off video
    const onFocusedTileDeletion = useCallback(() => {
        if (!focusedTileId || videoParticipants.length < 1) {
            return undefined;
        }
        if (!videoParticipants.find(p => getTileId(p) === focusedTileId)) {
            // Find next display participant to focus, if it exists
            const displayParticipant = videoParticipants.find(p => isDisplay(p));

            return displayParticipant ? getTileId(displayParticipant) : undefined;
        }

        return focusedTileId;
    }, [focusedTileId, videoParticipants]);

    // tile IDs to aspect ratios
    const { set: setTileAspectRatio, get: getTileAspectRatio } = useMap<string, number>();

    const getVideoSizeHandler = useCallback(
        (p: OrderedParticipant): ReactEventHandler<HTMLVideoElement> => ev => {
            const node = ev.currentTarget;
            const tileId = getTileId(p);

            const newRatio = node.videoWidth / node.videoHeight;
            if (!(node.videoHeight > 0 && node.videoWidth > 0)) return;

            setTileAspectRatio(tileId, newRatio);
        },
        [setTileAspectRatio],
    );

    // Callback to render a call participant
    const renderParticipant = useCallback(
        (p: OrderedParticipant): React.JSX.Element => (
            <ParticipantTile
                audioTrack={getAudio(p)}
                videoTrack={getVideo(p)}
                isSelf={currentUserId === getParticipantUserId(p)}
                participantUserId={p.userId}
                participantType={isDisplay(p) ? ParticipantType.Display
                    : ParticipantType.User}
                onClick={() => toggleFocused(p)}
                handleVideoSize={getVideoSizeHandler(p)}
            />
        ),
        [currentUserId, toggleFocused, getVideoSizeHandler],
    );

    const closeButton = (
        <button
            onClick={() => dispatch(closeLiveView())}
            className="cp-btn-call-grid cp-btn-call-grid--leave"
            title="Close call grid"
        >
            Close
        </button>
    );

    useEffect(() => {
        dispatch(setFocusedTileId(onFocusedTileDeletion()));
    }, [dispatch, onFocusedTileDeletion]);

    useEffect(() => {
        dispatch(setGridTiles(gridOrFocusedParticipants.map(getTileId)));
    }, [dispatch, gridOrFocusedParticipants]);

    // Return to BondView when there are no videos
    useEffect(() => {
        if (videoParticipantsLessSelf.length) return;
        dispatch(closeLiveView());
    }, [videoParticipantsLessSelf.length, dispatch]);

    if (!gridOrFocusedParticipants.length) {
        return <></>;
    }

    const callClasses = classNames("c-call", { "c-bond__content": phase3UIEnabled });

    return (
        <div className={callClasses}>
            {!phase3UIEnabled && closeButton}
            <GridView
                participants={gridOrFocusedParticipants}
                renderParticipant={renderParticipant}
                getTileAspectRatio={getTileAspectRatio}
            />
        </div>
    );
}

interface GridViewProps {
    participants: OrderedParticipant[];
    renderParticipant: (p: OrderedParticipant) => React.JSX.Element;
    getTileAspectRatio: (tileId: string) => Optional<number>;
}

function GridView(props: GridViewProps): React.JSX.Element {
    const { participants, renderParticipant, getTileAspectRatio } = props;

    const gridRef = useRef<HTMLDivElement>(null);

    const tileAspectRatios = useShallowEqualsMemo(
        () => participants.map(p => getTileAspectRatio(getTileId(p)) || defaultAspectRatio),
        [participants, getTileAspectRatio],
    );

    const tileDimensions = useGridLayout({ gridRef, tileAspectRatios });

    const renderTile = useCallback((p: OrderedParticipant, index: number) => {
        const style: { height?: number; maxWidth?: number; } = {};

        const dim = tileDimensions[index];
        if (dim && dim.height > 0) style.height = dim.height;
        // Want to set the tile width so that the flex box knows when tiles fit in a row.
        // But this looks glitchy when tile aspect ratios change, so set the max-width.
        if (dim && dim.width > 0) style.maxWidth = dim.width;

        return (
            <figure
                key={getTileId(p)}
                className={`c-call-item`}
                style={style}
            >
                {renderParticipant(p)}
            </figure>
        );
    }, [renderParticipant, tileDimensions]);

    return (
        <div className="c-call__grid" ref={gridRef}>
            {participants.map(renderTile)}
        </div>
    );
}
