import { useAppDispatch, useAppSelector } from "../../store/redux";
import { selectSquadById } from "../../features/squads";
import {
    PrivateOrSquadFilter,
    PrivateOrSquadFilterOption,
    selectNumberOfUnreadBondsForSquad,
    selectPrivateOrSquadFilter,
    setPrivateOrSquadFilterThunk,
} from "../../features/filterPanel";
import * as d from "../../domain/domain";
import SensitiveText from "../gui/SensitiveText";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import useBooleanFeatureFlag from "../../hooks/useBooleanFeatureFlag";
import { useLocation } from "react-router-dom";
import useAddressParams from "../../hooks/useAddressParams";
import classNames from "classnames";
import LinkButton from "../gui/LinkButton";
import { selectSquadIsUnread } from "../../features/channels";

// This function is slightly silly -- in phase3, both logic paths should result in
// the same thing. It's more here for clarify of how this is supposed to be structured
// in phase3 vs earlier.
//
// It might be we can remove the previous filter types in phase3, in which case it's
// clear how we can refactor this code.
function isSquadSelected(
    id: d.SquadId,
    phase3: boolean,
    currentCategory: PrivateOrSquadFilter,
    pathname: string,
    pathSquadId: d.SquadId | undefined,
): boolean {
    if (phase3) {
        return pathname.startsWith("/squad") && pathSquadId === id;
    }

    return currentCategory.by === "squad" && currentCategory.squadId === id;
}

function SquadEntry(
    props: {
        id: d.SquadId;
        onClick?: (what: "squad", id: d.SquadId) => void;
        showUnreadCount?: boolean;
    },
) {
    const { id, onClick: extraOnClick, showUnreadCount } = props;
    const dispatch = useAppDispatch();
    const squad = useSelectorArgs(selectSquadById, id);
    const currentCategory = useAppSelector(selectPrivateOrSquadFilter);
    const unreadCount = useSelectorArgs(selectNumberOfUnreadBondsForSquad, id);
    const phase3UI = useBooleanFeatureFlag("phase-3-ui");
    const { pathname } = useLocation();
    const { squadId: pathSquadId } = useAddressParams();
    const squadIsUnread = useSelectorArgs(selectSquadIsUnread, id);
    const thisSquadSelected = isSquadSelected(id, phase3UI, currentCategory, pathname, pathSquadId);

    if (!squad) {
        return <></>;
    }

    if (phase3UI) {
        const classes = classNames("c-squad-listing__squad", {
            "is-selected": thisSquadSelected,
            "is-unread": squadIsUnread,
        });

        return (
            <LinkButton
                to={`/squad/${d.extractUUID(id)}`}
                className={classes}
                title={squad.name}
            >
                <label>
                    <SensitiveText>{squad.name}</SensitiveText>
                </label>
            </LinkButton>
        );
    }

    const selectThisSquadPhase2 = () => {
        extraOnClick?.("squad", id);

        if (thisSquadSelected) {
            dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
        }
        else {
            dispatch(setPrivateOrSquadFilterThunk({ by: "squad", squadId: id }));
        }
    };

    const classes = classNames("cp-dropdown-squad__item", { "is-selected": thisSquadSelected });
    return (
        <a
            className={classes}
            onClick={selectThisSquadPhase2}
        >
            <SensitiveText>{squad.name}</SensitiveText>
            {showUnreadCount && unreadCount > 0 && <span>({unreadCount})</span>}
        </a>
    );
}

export default SquadEntry;
