import { useRef, useEffect } from "react";
import { Optional } from "../misc/types";

interface usePreviousOptions<T> {
    filter?: (t: T) => boolean;
    initialValue?: T;
}

export default function usePrevious<T>(input: T, options?: usePreviousOptions<T>) {
    const { filter, initialValue } = options ?? {};

    const ref = useRef<Optional<T>>(initialValue);

    useEffect(() => {
        if (filter?.(input) ?? true) {
            ref.current = input;
        }
    }, [filter, input]);

    return ref.current;
}
