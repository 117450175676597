import Linkify from "linkify-react";
import { Opts as LinkifyOpts } from "linkifyjs";
import { ContentMention } from "../../domain/mentions";
import { useShallowEqualsMemo } from "../../hooks/useShallowEquals";
import { partialComponent } from "../../misc/utils";
import TextFields, { TextField } from "../gui/TextFields";
import MentionView from "./MentionView";

interface ChatMessageTextViewProps {
    text: string;
    mentions: ContentMention[];
    linkifyOpts?: LinkifyOpts;
}

/**
 * @deprecated
 */
export const ChatMessageTextView: React.FC<ChatMessageTextViewProps> = props => {
    const { text, mentions, linkifyOpts } = props;

    const fields: TextField[] = useShallowEqualsMemo(
        () =>
            mentions.map(m => ({
                range: { start: m.startOffset, end: m.endOffset },
                Component: partialComponent(MentionView, { mention: m }),
            })),
        [mentions],
    );

    return (
        <p>
            <TextFields
                text={text ?? ""}
                fields={fields}
                DefaultComponent={partialComponent(Linkify, { options: linkifyOpts })}
            />
        </p>
    );
};

export default ChatMessageTextView;
