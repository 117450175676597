import { PostHogFeatureProps, usePostHog } from "posthog-js/react";
import { FeatureFlagId } from "../../gen/features/features";
import { useEffect, useState } from "react";
import { JsonType } from "posthog-js";

export type FeatureFlaggedProps =
    & Pick<PostHogFeatureProps, "children" | "fallback" | "match">
    & { flag: FeatureFlagId; }
    & (
        | { wrapWithDiv?: true; } & React.HTMLProps<HTMLDivElement>
        | { wrapWithDiv: false; }
    );

/* Feature flag wrapper for showing a react component, with a fallback.
 *
 * Broadly the same as the underlying PostHogFeature component, but with three major differences:
 *  1. The `flag` is typed to only support string literals of known feature flags,
 *  2. There is no delay in calculating feature flags; on first render the flag is checked,
 *  3. There is no support yet for tracking visibility and clicks of the feature flag.
 *
 * Adding (3) is simple enough if desired in the future; just not done yet as we don't yet get
 * value from this. See the source for how we'd go about it:
 *
 * https://github.com/PostHog/posthog-js/blob/27b044c616efc8adeb6b3d1766ea5a1f25cd4708/react/src/components/PostHogFeature.tsx#L5
 *
 * (2) is important -- the official library only evaluates the feature flag in the useEffect(),
 * which creates some delay, so an initial render is in practice always done with the feature
 * flag "disabled" (the value is `undefined`), then a second render a moment later when the
 * useEffect() and onFeatureFlags run.
 */
export function FeatureFlagged(
    { flag, children, fallback, match, wrapWithDiv, ...props }: FeatureFlaggedProps,
): JSX.Element {
    wrapWithDiv ??= true;

    const client = usePostHog();
    const [value, setValue] = useState<string | boolean | undefined>(client.getFeatureFlag(flag));
    const [payload, setPayload] = useState<JsonType>(client.getFeatureFlagPayload(flag));

    useEffect(() => {
        return client.onFeatureFlags(flags => {
            setValue(flags.find(f => f === flag) !== undefined);
            setPayload(client.getFeatureFlagPayload(flag));
        });
    }, [client, flag]);

    if (match === undefined || value === match) {
        const childNode: React.ReactNode = typeof children === "function" ? children(payload)
            : children;

        return wrapWithDiv ?
            <div {...props}>{childNode}</div> :
            <>{childNode}</>;
    }

    return <>{fallback}</>;
}
