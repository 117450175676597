import merge from "lodash.merge";
import Quill from "quill/core";
import type { ThemeOptions } from "quill/core/theme";
import BaseTheme from "quill/themes/base";

class BeyondTheme extends BaseTheme {
    static readonly NAME = "beyond";

    static DEFAULTS = merge({}, BaseTheme.DEFAULTS, {
        modules: {
            toolbar: false,
        },
    });

    constructor(quill: Quill, options: ThemeOptions) {
        super(quill, options);
        this.quill.container.classList.add("ql-beyond");
    }
}

export default BeyondTheme;
