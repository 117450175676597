// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file blobs/blobs.proto (package blobs, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { BlobCategory, BlobId, BlobSet, OrgId, UserId } from "../domain/domain_pb.js";

/**
 * @generated from message blobs.BlobDimensions
 */
export const BlobDimensions = proto3.makeMessageType(
  "blobs.BlobDimensions",
  () => [
    { no: 1, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message blobs.BlobProperties
 */
export const BlobProperties = proto3.makeMessageType(
  "blobs.BlobProperties",
  () => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "dimensions", kind: "message", T: BlobDimensions },
  ],
);

/**
 * @generated from message blobs.ExpiringSasUrl
 */
export const ExpiringSasUrl = proto3.makeMessageType(
  "blobs.ExpiringSasUrl",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url_expiry", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message blobs.CreateBlobRequest
 */
export const CreateBlobRequest = proto3.makeMessageType(
  "blobs.CreateBlobRequest",
  () => [
    { no: 1, name: "properties", kind: "message", T: BlobProperties },
    { no: 2, name: "uploader_id", kind: "message", T: UserId },
    { no: 3, name: "org_id", kind: "message", T: OrgId },
    { no: 4, name: "container_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "category", kind: "enum", T: proto3.getEnumType(BlobCategory) },
  ],
);

/**
 * @generated from message blobs.CreateBlobResponse
 */
export const CreateBlobResponse = proto3.makeMessageType(
  "blobs.CreateBlobResponse",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "upload_sas_url", kind: "message", T: ExpiringSasUrl },
  ],
);

/**
 * @generated from message blobs.UploadBlobRequest
 */
export const UploadBlobRequest = proto3.makeMessageType(
  "blobs.UploadBlobRequest",
  () => [
    { no: 1, name: "blob_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "user_id", kind: "message", T: UserId },
    { no: 4, name: "properties", kind: "message", T: BlobProperties },
    { no: 5, name: "container_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(BlobCategory) },
    { no: 7, name: "blob_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 8, name: "assign_blob_id", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "tags", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 11, name: "access_tier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message blobs.UploadBlobResponse
 */
export const UploadBlobResponse = proto3.makeMessageType(
  "blobs.UploadBlobResponse",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "blob_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message blobs.CompleteBlobRequest
 */
export const CompleteBlobRequest = proto3.makeMessageType(
  "blobs.CompleteBlobRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "completer_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message blobs.CompleteBlobResponse
 */
export const CompleteBlobResponse = proto3.makeMessageType(
  "blobs.CompleteBlobResponse",
  [],
);

/**
 * @generated from message blobs.GetBlobInfoRequest
 */
export const GetBlobInfoRequest = proto3.makeMessageType(
  "blobs.GetBlobInfoRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "container_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message blobs.GetBlobInfoResponse
 */
export const GetBlobInfoResponse = proto3.makeMessageType(
  "blobs.GetBlobInfoResponse",
  () => [
    { no: 1, name: "sas_url", kind: "message", T: ExpiringSasUrl },
    { no: 2, name: "properties", kind: "message", T: BlobProperties },
    { no: 3, name: "category", kind: "enum", T: proto3.getEnumType(BlobCategory) },
  ],
);

/**
 * @generated from message blobs.CleanStaleBlobsRequest
 */
export const CleanStaleBlobsRequest = proto3.makeMessageType(
  "blobs.CleanStaleBlobsRequest",
  [],
);

/**
 * @generated from message blobs.CleanStaleBlobsResponse
 */
export const CleanStaleBlobsResponse = proto3.makeMessageType(
  "blobs.CleanStaleBlobsResponse",
  [],
);

/**
 * @generated from message blobs.DeleteBlobsRequest
 */
export const DeleteBlobsRequest = proto3.makeMessageType(
  "blobs.DeleteBlobsRequest",
  () => [
    { no: 2, name: "blob_ids", kind: "message", T: BlobSet },
  ],
);

/**
 * @generated from message blobs.DeleteBlobsResponse
 */
export const DeleteBlobsResponse = proto3.makeMessageType(
  "blobs.DeleteBlobsResponse",
  [],
);

