import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { SquadAndMyBondsSelector } from "../components/SquadAndMyBondsSelector";
import classNames from "classnames";
import { mobilePathToViewName, MobileViewName } from "../misc/mobilePathToTab";

interface TabInfo {
    title: string;
    path: string;
    id: "bonds" | "squads" | "discover";
    view: Array<MobileViewName>;
}

function Tab(
    { title, path, highlight, id }: TabInfo & { highlight: boolean; },
): React.JSX.Element {
    const classes = classNames(`c-footer-mobile__button c-btn-${id}`, {
        [`c-btn-${id}--selected`]: highlight,
    });

    return (
        <Link to={path}>
            <button className={classes}>
                {title}
            </button>
        </Link>
    );
}

export function MobileTabsView(): React.JSX.Element {
    const { pathname } = useLocation();
    const tabs: Array<TabInfo> = [
        {
            title: "Inbox",
            path: "/mobile/tab/mybonds",
            id: "bonds",
            view: ["mybonds"],
        },
        {
            title: "Groups",
            path: "/mobile/tab/mysquads",
            id: "squads",
            view: ["mysquads", "squad"],
        },
        {
            title: "Discover",
            path: "/mobile/tab/discover",
            id: "discover",
            view: ["discover"],
        },
    ];
    const view = mobilePathToViewName(pathname);
    const isHighlighted = useCallback((tab: typeof tabs[0]) => {
        return tab.view.includes(view);
    }, [view]);

    // Only render if we're on one of the tabs; i.e. one of them is highlighted
    if (!tabs.some(isHighlighted)) {
        return <></>;
    }

    const className = classNames("c-footer-mobile", {
        "c-footer-mobile--groups": mobilePathToViewName(pathname) === "mysquads",
    });

    return (
        <footer className={className}>
            {tabs.map(x => <Tab key={x.path} highlight={isHighlighted(x)} {...x} />)}
        </footer>
    );
}

export function MobileSquadSelectorView(): React.JSX.Element {
    return <SquadAndMyBondsSelector showMyBonds={false} />;
}
