import {
    PayloadAction,
    createAsyncThunk,
    createSelector,
    createSlice,
    nanoid,
} from "@reduxjs/toolkit";
import base64 from "base64-js";

import { BackendInfo, getVersion } from "../api/users";
import * as d from "../domain/domain";
import type { Connection } from "../persist/types";
import { checkPersistor, localStorageStore } from "../persist/shared";
import { storageRead, storageWrite } from "../persist/storage";
import type { RootState } from "../store/types";
import { resetStore } from "./auth";
import { unaryThunkHandler } from "./thunk";
import { ConnectionStatus, updateConnectionStatus } from "./connection";

// Only allow serialisable `DurationLike` values.
import { DateTime, Duration, DurationLike, DurationLikeObject } from "luxon";
import { CounterState, createCounter } from "../misc/counter";
import { Optional } from "../misc/types";
type SerialisableDurationLike = Exclude<DurationLike, Duration>;

export enum MetaInterestCounterKey {
    BlockHotkey = "blockHotkey",
    RaiseBondView = "raiseBondView",
    BlockMsgCompletion = "blockMsgCompletion",
}
const metaInterestCounter = createCounter<MetaInterestCounterKey>();

const stores = {
    deviceTag: localStorageStore<string>("meta/deviceTag", 1, undefined, { raw: true }),
};

export const getBackendInfo = createAsyncThunk(
    "meta/getVersion",
    async (_args, thunkAPI) => {
        return await unaryThunkHandler(
            thunkAPI,
            getVersion(),
            `getBackendVersion`,
        );
    },
);

type IdleState = {
    since?: number; // DateTime in milliseconds
    subscribers: Partial<Record<string, SerialisableDurationLike>>;
};

export type ToastContent = {
    message: string;
    created: number; // DateTime in milliseconds
    duration: DurationLikeObject;
};

type CreateToastArgs = {
    message: string;
    duration?: DurationLikeObject;
};

const defaultToastDuration: DurationLikeObject = { seconds: 2 };

export type MetaState = {
    persist: boolean;
    deviceTag: d.DeviceTag;
    connectionTag: d.ConnectionTag;
    connectionNumber: number;
    backendInfo?: BackendInfo;
    idbUpgradeRequired: boolean;
    showReportIssueDialog: boolean;
    showSidebar: boolean;
    idle: IdleState;
    interest: CounterState<MetaInterestCounterKey>;
    toast: Optional<ToastContent>;
};

const createRandomBase64String = (n: number = 9) => {
    const array = new Uint8Array(n);
    return base64.fromByteArray(crypto.getRandomValues(array));
};

const getDeviceTag = (() => {
    let deviceTag: string | null = null;
    return () => {
        if (deviceTag) {
            return deviceTag;
        }

        const stored = storageRead(stores.deviceTag);
        if (stored) {
            deviceTag = stored;
            return deviceTag;
        }

        deviceTag = createRandomBase64String();
        storageWrite(stores.deviceTag, deviceTag);
        return deviceTag;
    };
})();

const getInitialState = (props?: Partial<MetaState>): MetaState => {
    return {
        persist: props?.persist ?? false,
        deviceTag: props?.deviceTag ?? getDeviceTag(),
        connectionNumber: props?.connectionNumber ?? 0,
        connectionTag: props?.connectionTag ?? createRandomBase64String(),
        idbUpgradeRequired: props?.idbUpgradeRequired ?? false,
        showReportIssueDialog: props?.showReportIssueDialog ?? false,
        showSidebar: props?.showSidebar ?? true,
        idle: props?.idle ?? { subscribers: {} },
        interest: metaInterestCounter.getInitialState(),
        toast: undefined,
    };
};

const metaSlice = createSlice({
    name: "meta",
    initialState: getInitialState(),
    selectors: {
        backendInfo: state => state.backendInfo,
        deviceTag: state => state.deviceTag,
        connectionTag: state => state.connectionTag,
        connectionNumber: state => state.connectionNumber,
        idbUpgradeRequired: state => state.idbUpgradeRequired,
        showReportIssueDialog: state => state.showReportIssueDialog,
        showSidebar: state => state.showSidebar,
        idleSince: state => state.idle.since,
        idleSubscribers: state => state.idle.subscribers,
        interest: (state, interestKey: MetaInterestCounterKey) =>
            state.interest.includes(interestKey),
        toast: state => state.toast,
    },
    reducers: {
        idbUpgradeRequired: state => {
            state.idbUpgradeRequired = true;
        },
        updatePersisting: (state, action: PayloadAction<boolean>) => {
            state.persist = action.payload;
        },
        updateBackendInfo: (state, action: PayloadAction<BackendInfo>) => {
            state.backendInfo = action.payload;
        },
        showReportIssueDialog: (state, action: PayloadAction<boolean>) => {
            state.showReportIssueDialog = action.payload;
        },
        showSidebar: (state, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload;
        },
        setIdleSince: (state, action: PayloadAction<number>) => {
            state.idle.since = action.payload;
        },
        setNotIdle: state => {
            state.idle.since = undefined;
        },
        addIdleSubscriber: {
            prepare: (timeout: SerialisableDurationLike) => ({
                payload: { id: nanoid(), duration: timeout },
            }),
            reducer: (
                state,
                action: PayloadAction<{ id: string; duration: SerialisableDurationLike; }>,
            ) => {
                state.idle.subscribers[action.payload.id] = action.payload.duration;
            },
        },
        removeIdleSubscriber: (state, action: PayloadAction<string>) => {
            delete state.idle.subscribers[action.payload];
        },
        registerMetaInterest: (
            state,
            { payload: keys }: PayloadAction<MetaInterestCounterKey[]>,
        ) => {
            state.interest = metaInterestCounter.add(
                state.interest,
                ...keys,
            );
        },
        deregisterMetaInterest: (
            state,
            { payload: keys }: PayloadAction<MetaInterestCounterKey[]>,
        ) => {
            state.interest = metaInterestCounter.remove(
                state.interest,
                ...keys,
            );
        },
        createToast: {
            prepare: (args: CreateToastArgs) => ({
                payload: {
                    message: args.message,
                    created: DateTime.now().toMillis(),
                    duration: args.duration || defaultToastDuration,
                },
            }),
            reducer: (state, action: PayloadAction<ToastContent>) => {
                state.toast = action.payload;
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(
            getBackendInfo.fulfilled,
            (state, action: PayloadAction<BackendInfo>) => {
                state.backendInfo = action.payload;
            },
        );
        builder.addCase(
            updateConnectionStatus,
            (state, action: PayloadAction<ConnectionStatus>) => {
                if (action.payload === ConnectionStatus.ShouldConnect) {
                    state.connectionNumber++;
                }
            },
        );
        builder.addCase(resetStore, _state => {
            // Do nothing.
        });
    },
});

const selectors = metaSlice.getSelectors((state: RootState) => state.meta);

export const {
    backendInfo: selectBackendInfo,
    idbUpgradeRequired: selectIdbUpgradeRequired,
    showReportIssueDialog: selectShowReportIssueDialog,
    showSidebar: selectShowSidebar,
    idleSubscribers: selectIdleSubscribers,
    idleSince: selectIdleSince,
    interest: selectInterestInKey,
    toast: selectNewestToast,
} = selectors;

export const selectConnectionIdentifiers = createSelector(
    [selectors.deviceTag, selectors.connectionTag, selectors.connectionNumber],
    (device, tag, n) => ({ deviceTag: device, connTagPrefix: tag, connTagNum: n }),
);

export const {
    idbUpgradeRequired,
    updatePersisting,
    updateBackendInfo,
    showReportIssueDialog,
    showSidebar,
    setIdleSince,
    setNotIdle,
    addIdleSubscriber,
    removeIdleSubscriber,
    registerMetaInterest,
    deregisterMetaInterest,
    createToast,
} = metaSlice.actions;

export const reducer = metaSlice.reducer;

// Persistence.

const hydrate = async (_conn: Connection) =>
    getInitialState({
        deviceTag: getDeviceTag(),
        connectionTag: createRandomBase64String(),
        connectionNumber: 0,
    });

export const persistor = {
    stores: {},
    hydrate,
};
checkPersistor<MetaState>(persistor);
