import { useParams } from "react-router-dom";

import * as d from "../domain/domain";

export interface UrlParams {
    attachmentIdx?: number;
    attachmentVersion?: number;
    bondId?: d.BondId;
    callId?: d.CallId;
    messageId?: d.MessageId;
    squadId?: d.SquadId;
}

export default function useAddressParams(): UrlParams {
    const {
        attachmentIdx: attachmentIdxString,
        attachmentVersion: attachmentVersionString,
        bondId: bondUuidString,
        callId: callUuidString,
        messageId: messageUuidString,
        squadId: squadUuidString,
    } = useParams();

    const attachmentIdx: number | undefined = attachmentIdxString ?
        parseInt(attachmentIdxString)
        : undefined;

    const attachmentVersion: number | undefined = attachmentVersionString ?
        parseInt(attachmentVersionString)
        : undefined;

    const bondId: d.BondId | undefined = bondUuidString ?
        d.fromRawBondId(bondUuidString)
        : undefined;

    const callId: d.CallId | undefined = callUuidString ?
        d.fromRawCallId(callUuidString)
        : undefined;

    const messageId: d.MessageId | undefined = messageUuidString ?
        d.fromRawMessageId(messageUuidString)
        : undefined;

    const squadId: d.SquadId | undefined = squadUuidString ?
        d.fromRawSquadId(squadUuidString) : undefined;

    return { attachmentIdx, attachmentVersion, bondId, callId, messageId, squadId };
}
