import { createPromiseClient } from "@connectrpc/connect";
import { BondService } from "../../gen/proto/bonds/bonds_connect";
import {
    CreateBondFromChatMessageRequest,
    CreateBondFromChatMessageResponse,
} from "../../gen/proto/bonds/bonds_pb";
import { PrivacyLevel } from "../../gen/proto/domain/domain_pb";
import { BondOverview } from "../domain/bonds";
import { getContent_Mentions } from "../domain/chatContent";
import * as d from "../domain/domain";
import { filterMentions } from "../domain/mentions";
import { UnsentChatMessage } from "../domain/messages";
import log from "../misc/log";
import { transport } from "./transport";
import {
    fromProtoBondId,
    fromProtoChannelId,
    pbBlobId,
    pbMentionSet,
    pbOrgId,
    pbTimestamp,
    pbUserId,
} from "./util";

export const service = createPromiseClient(BondService, transport);
export default service;

export interface CreateBondFromMsgParams {
    senderId: d.UserId;
    orgId: d.OrgId;
    privacy?: PrivacyLevel;
    title?: string;
    msg: UnsentChatMessage;
    officialAttachmentIds?: d.BlobId[];
}

// Returns a BondOverview that is vaguely the same as what we expect to be returned by the server.
// TODO: put this into the redux store while we wait for the server to respond and then update it
// when the server responds. This will allow the user to create the bond and then immediately see it in the UI.
export async function createBondFromMsg(params: CreateBondFromMsgParams): Promise<BondOverview> {
    const {
        senderId,
        orgId,
        privacy,
        title,
        msg,
        officialAttachmentIds,
    } = params;

    const mentions = getContent_Mentions(msg.content) ?? [];
    // Eagerly extract squads from mentions for responsiveness in the UI
    const { squadIds } = filterMentions(mentions);

    const calcPrivacy = privacy ??
        ((squadIds.length == 0) ? PrivacyLevel.PRIVATE : PrivacyLevel.OPEN);

    const req = new CreateBondFromChatMessageRequest({
        bond: {
            userId: pbUserId(senderId),
            orgId: pbOrgId(orgId),
            privacy: calcPrivacy,
            userSpecifiedTitle: title,
        },
        message: {
            clientTxAt: pbTimestamp(msg.clientTxTs),
            content: JSON.stringify(msg.content),
            attachmentIds: officialAttachmentIds?.map(pbBlobId),
        },
        mentions: pbMentionSet(mentions),
    });

    let resp: CreateBondFromChatMessageResponse;
    try {
        resp = await service.createBondFromChatMessage(req);
    }
    catch (e) {
        log.error(e);
        return Promise.reject(e);
    }

    return {
        id: fromProtoBondId(resp.response?.newBondId),
        channelId: fromProtoChannelId(resp.response?.newChannelId),
        orgId,
        squadIds,
        privacy: calcPrivacy,
        knowledge: {
            userSpecifiedTitle: title ?? "",
            aiGeneratedTitle: "",
            summary: "",
            detailedSummary: "",
            imageUrl: "",
        },
        contributors: [senderId],
        followers: [senderId], // Could add mentioned users here, except we need to know if they're external or not...
        externalUsers: [],

        lastActivityAt: msg.clientTxTs,
        maxSequenceNumber: 1,
        liveCallIds: [],
    };
}
