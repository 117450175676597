import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import { isMobileBrowser } from "../misc/mobile";
import { MobileTabsView } from "../views/MobileViews";

export function Footer(): React.JSX.Element {
    const isPhase3 = useBooleanFeatureFlag("phase-3-ui");

    return (
        <>
            {isPhase3 && isMobileBrowser() && <MobileTabsView />}
        </>
    );
}
