import Linkify from "linkify-react";
import { Opts as LinkifyOpts } from "linkifyjs";
import React, { Fragment } from "react";
import {
    CompactMarkupDelta,
    isMentionOp,
    isTextInsertOp,
    OpAttributes,
    splitLines,
} from "../../domain/delta";
import SensitiveText from "../gui/SensitiveText";
import MentionView from "./MentionView";

interface ChatMessageMarkupViewProps {
    markup: CompactMarkupDelta;
    linkifyOpts?: LinkifyOpts;
}

const getLineTagName = (lineAttributes: OpAttributes): string => {
    const header = lineAttributes?.header;
    if (
        typeof header == "number" &&
        1 <= header && header <= 3
    ) {
        return `h${header}`;
    }

    if (lineAttributes?.blockquote) {
        return "blockquote";
    }

    return "p";
};

const getFieldTagNames = (attributes: OpAttributes): string[] => {
    const tagNames = [];

    if (attributes?.bold) tagNames.push("strong");
    if (attributes?.italic) tagNames.push("em");

    return tagNames;
};

export const ChatMessageMarkupView: React.FC<ChatMessageMarkupViewProps> = props =>
    splitLines(props.markup)
        .map(line =>
            React.createElement(
                getLineTagName(line.lineAttributes ?? {}),
                {},
                line.ops.map(op => {
                    if (isTextInsertOp(op)) {
                        return getFieldTagNames(op?.attributes ?? {}).reduce(
                            (node, tagName) => React.createElement(tagName, {}, node),
                            <Linkify options={props.linkifyOpts}>
                                <SensitiveText>{op.insert}</SensitiveText>
                            </Linkify>,
                        );
                    }
                    else if (isMentionOp(op)) {
                        return (
                            <MentionView
                                mention={op.insert.mention}
                                text={op.insert.mention.text}
                            />
                        );
                    }
                }).map((node, i) => <Fragment key={i}>{node}</Fragment>),
            )
        ).map((node, i) => <Fragment key={i}>{node}</Fragment>);

export default ChatMessageMarkupView;
