import Quill, { Delta } from "quill/core";
import { EmitterSources } from "../../domain/delta";

interface MaxLengthOptions {
    limit: number;
}

export function MaxLength(quill: Quill, options: MaxLengthOptions) {
    quill.on(Quill.events.TEXT_CHANGE, (delta: Delta, oldContent: Delta) => {
        if (quill.getLength() > options.limit) {
            const selection = quill.getSelection();
            const oldIndex = selection ?
                delta.invert(oldContent)
                    .transformPosition(selection.index) :
                0;
            quill.setContents(oldContent, EmitterSources.SILENT);
            quill.setSelection(oldIndex, EmitterSources.SILENT);
        }
    });
}
