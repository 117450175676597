import useResizeObserver from "@react-hook/resize-observer";
import { ChangeEventHandler, FC, useCallback, useRef, useState } from "react";
import { selectBondCreationTitle, updateBondCreationTitle } from "../features/bondCreation";
import { Setter } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import AutoExpandInput from "./gui/AutoExpandInput";

interface NewBondTitleBarProps {
    backAction: () => void;
}

export const NewBondTitleBar: FC<NewBondTitleBarProps> = ({ backAction }) => {
    const dispatch = useAppDispatch();

    const title = useAppSelector(selectBondCreationTitle);

    const barRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<Setter<string>>(null);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        const title = (e.currentTarget.value !== "") ?
            e.currentTarget.value :
            undefined;
        dispatch(updateBondCreationTitle(title));
    }, [dispatch]);

    // Limit the max width of the title input to the title bar width less a fixed margin
    const [maxWidth, setMaxWidth] = useState("unset");
    useResizeObserver(
        barRef,
        e => {
            // In a horizontal writing mode, `inlineSize` is equivalent to width
            const width = e.contentBoxSize?.[0].inlineSize;
            if (width == undefined) {
                setMaxWidth("unset");
            }
            setMaxWidth(`${width - 200}px`);
        },
    );

    // TODO: pre-emptive AI-generated title goes here
    const aiGeneratedTitle = undefined;
    const placeholder = aiGeneratedTitle ?? "New bond";

    return (
        <div ref={barRef} className="c-new-bond-title-bar">
            <button
                className="c-btn-return c-new-bond-title-bar__btn-back"
                onClick={backAction}
                title="Return"
            >
                Return
            </button>
            <AutoExpandInput
                ref={inputRef}
                style={{ maxWidth }}
                onChange={onInputChange}
                placeholder={placeholder}
                defaultValue={title}
            />
        </div>
    );
};
