import React, { ForwardedRef, forwardRef, useMemo, useRef } from "react";

import { useCallback } from "react";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { MediaToggleMap } from "../domain/mediaDevices";
import { DraftChatMessage } from "../domain/messages";
import { transferDraftThunk } from "../features/bondCreation";
import {
    selectAllBondInvolvees,
    selectBondIdByChannelId,
    selectLiveCallIdByBondId,
} from "../features/bonds";
import { stageMessageForChannel } from "../features/channels";
import useMergedRefs from "../hooks/useMergedRefs";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { Focusable, Optional } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { FeatureFlagged } from "./FeatureFlags";
import MessageComposer from "./MessageComposer";
import RichTextMessageComposer from "./RichTextMessageComposer";
import { useNavigateBack } from "../hooks/useNavigateBack";

const placeholder = "Message...";

export interface ChannelMessageComposerProps {
    channelId: d.ChannelId;
    mediaControls?: MediaToggleMap;
    showBondInterestedAction?: () => void;
    onModalChange?: (current: boolean) => void;
    onEditorFocus?: () => void;
    onEditorBlur?: () => void;
}

export const ChannelMessageComposer = forwardRef((
    props: ChannelMessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        channelId,
        mediaControls,
        showBondInterestedAction,
        onModalChange,
        onEditorFocus,
        onEditorBlur,
    } = props;

    const dispatch = useAppDispatch();

    const bondId = useSelectorArgs(selectBondIdByChannelId, channelId);
    const liveCallId = useAppSelector(selectLiveCallIdByBondId(bondId));

    const allBondInvolvees = useSelectorArgs(selectAllBondInvolvees, bondId);

    const draftTarget = useMemo(() => newChannelDraftTarget(channelId), [channelId]);

    const sendMessage = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!draft) return;

        dispatch(stageMessageForChannel({ ...draft, liveCallId }));
    }, [dispatch, liveCallId]);

    const { navigateBack } = useNavigateBack();

    const escapeAction = useCallback(() => {
        dispatch(transferDraftThunk({
            from: draftTarget,
            to: bondCreationDraftTarget,
        })).then(() => navigateBack());
    }, [dispatch, draftTarget, navigateBack]);

    const focusRef = useRef<Focusable>();

    const mergedRef = useMergedRefs(ref, focusRef);

    return (
        <FeatureFlagged
            flag={"rich-text-composer"}
            match={true}
            wrapWithDiv={false}
            fallback={
                <MessageComposer
                    id={`comms-input-${channelId}`}
                    ref={mergedRef}
                    key={`comms-input-bond-channel-${channelId}`}
                    draftTarget={draftTarget}
                    msgCompletionAction={sendMessage}
                    escapeAction={escapeAction}
                    numberOfParticipants={allBondInvolvees.length}
                    showBondInterestedAction={showBondInterestedAction}
                    mediaControls={mediaControls}
                    placeholder={placeholder}
                    onModalChange={onModalChange}
                    onTextAreaFocus={onEditorFocus}
                    onTextAreaBlur={onEditorFocus}
                />
            }
        >
            <RichTextMessageComposer
                id={`comms-input-${channelId}`}
                ref={mergedRef}
                key={`comms-input-bond-channel-${channelId}`}
                draftTarget={draftTarget}
                msgCompletionAction={sendMessage}
                escapeAction={escapeAction}
                numberOfParticipants={allBondInvolvees.length}
                showBondInterestedAction={showBondInterestedAction}
                mediaControls={mediaControls}
                placeholder={placeholder}
                onModalChange={onModalChange}
                onEditorFocus={onEditorFocus}
                onEditorBlur={onEditorBlur}
            />
        </FeatureFlagged>
    );
});
