import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Outlet } from "react-router-dom";
import {
    selectBondById,
    selectChannelIdByBondId,
    streamAndObserveBond,
    streamArchivedBondsList,
    streamCurrentUserBondPreviews,
    updateSelectedBond,
    selectLiveCallIdByBondId,
    clearSelectedBond,
    getShareableBondInviteLinkThunk,
} from "../features/bonds";
import { useAppDispatch, useAppSelector } from "../store/redux";
import {
    closeLiveView,
    JoinedCallView,
    selectJoinedCallView,
    setCurrentCallId,
} from "../features/calls";
import useAddressParams from "../hooks/useAddressParams";
import useSelectorArgs from "../hooks/useSelectorArgs";
import useStreamDispatch from "../hooks/useStreamDispatch";
import ChatView from "./ChatView";

import { useHotkeys } from "react-hotkeys-hook";
import { isMobileBrowser } from "../misc/mobile";
import { Focusable, Optional } from "../misc/types";
import useObserveCurrentSquads from "../hooks/useObserveCurrentSquads";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import { transferDraftThunk } from "../features/bondCreation";
import usePrevious from "../hooks/usePrevious";
import useInterestedCall from "../hooks/interest/useInterestedCall.ts";
import { createToast, MetaInterestCounterKey, selectInterestInKey } from "../features/meta";
import classNames from "classnames";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import {
    PrivateOrSquadFilterOption,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel.ts";
import useInterestedSquads from "../hooks/interest/useInterestedSquads.ts";
import BondChatParticipants from "../components/BondChatParticipants.tsx";
import useRtcClientWithMediaControls from "../hooks/rtc/useRtcClientWithMediaControls.ts";
import { orderParticipants } from "../domain/rtc.ts";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag.ts";
import useOnComponentUnmount from "../hooks/useOnComponentUnmount.ts";
import { useNavigateBack } from "../hooks/useNavigateBack.ts";
import { FeatureFlagged } from "../components/FeatureFlags.tsx";
import * as d from "../domain/domain.ts";

export default BondView;

export function BondViewStreamSubscriptions(): React.JSX.Element {
    // This component is always mounted whenever these are needed.
    // So they are started and ended here to ensure that they are not
    // continuously stopped and started.
    useStreamDispatch(streamCurrentUserBondPreviews, []);
    useStreamDispatch(streamArchivedBondsList, []);

    // Always observe our squads while in either the card view or single bond view
    useObserveCurrentSquads();

    return <></>;
}

function EarlyBondButtons({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate } = useNavigateBack();

    const showRenameBondModalAction = useCallback(() => {
        navigate("modify");
    }, [navigate]);

    const showUserInvitationAction = useCallback(() => {
        navigate("invite");
    }, [navigate]);

    const copyShareableLinkToClipboard = useCallback(async () => {
        if (!bondId) {
            return;
        }

        // Consider sensible offline behaviour for this button
        const link = await dispatch(
            getShareableBondInviteLinkThunk({ bondId }),
        ).unwrap().catch(
            () => {
                dispatch(createToast({
                    message: "Failed to get link.",
                    duration: { seconds: 3 },
                }));
                throw new Error("Failed to get link.");
            },
        );

        navigator.clipboard.writeText(link);

        dispatch(createToast({
            message: "Link copied to clipboard.",
            duration: { seconds: 3 },
        }));
    }, [dispatch, bondId]);

    return (
        <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
            <div className="c-section__pre-chat" style={{ display: "flex", columnGap: "16px" }}>
                <button
                    className="c-btn-lineal"
                    title="Change name"
                    onClick={showRenameBondModalAction}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--name"></div>
                    Change name
                </button>
                <button
                    className="c-btn-lineal"
                    title="Add people"
                    onClick={showUserInvitationAction}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--people"></div>
                    Add people
                </button>
                <button
                    className="c-btn-lineal"
                    title="Add link"
                    onClick={copyShareableLinkToClipboard}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--link"></div>
                    Copy link
                </button>
            </div>
        </FeatureFlagged>
    );
}

export function BondView(): React.JSX.Element {
    const { bondId, messageId } = useAddressParams();

    const liveCallId = useAppSelector(selectLiveCallIdByBondId(bondId));

    const dispatch = useAppDispatch();
    const { navigate, navigateBack } = useNavigateBack();

    const prevBondId = usePrevious(bondId);
    const bond = useSelectorArgs(selectBondById, bondId);
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const chatRef = useRef<Focusable>(null);
    useEffect(() => {
        if (isMobileBrowser()) {
            return;
        }

        if (bondId != prevBondId) {
            chatRef.current?.focus({ goToEnd: true });
        }
    }, [bondId, prevBondId]);

    useEffect(() => {
        dispatch(updateSelectedBond(bondId));
    }, [dispatch, bondId]);

    useOnComponentUnmount(() => {
        dispatch(clearSelectedBond());
    });

    // Reset live (grid) view state on render and changing bond.
    // This guarantees that we don't automatically show the call grid.
    useEffect(() => {
        dispatch(closeLiveView());
    }, [dispatch, bondId]);

    // Mark the bond's call as the current call once we learn about it
    useEffect(() => {
        dispatch(setCurrentCallId(liveCallId));
    }, [dispatch, liveCallId]);

    const {
        allParticipants: callParticipants,
        mediaControls,
    } = useRtcClientWithMediaControls({
        bondId,
        callId: liveCallId,
    });

    const { orderedParticipants } = useMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    useStreamDispatch(() => bondId && streamAndObserveBond(bondId), [bondId]);
    useInterestedCall(bond?.liveCallIds[0]);
    useInterestedSquads(bond?.squadIds);

    const showBondInterestedAction = useCallback(() => {
        navigate("interested");
    }, [navigate]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));

    // Escape key handler
    const navigateUp = useCallback(async () => {
        if (channelId) {
            await dispatch(transferDraftThunk({
                from: newChannelDraftTarget(channelId),
                to: bondCreationDraftTarget,
            }));
            navigateBack();
        }
        else {
            await dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
            dispatch(setRelevanceLevelFilter({ by: "all" }));
        }
    }, [navigateBack, dispatch, channelId]);

    const minimiseGrid = useCallback(() => dispatch(closeLiveView()), [dispatch]);
    // NB: both callbacks are memoised above, so goBack is stable when passed to the hook.
    const goBack = isLiveView ? minimiseGrid : navigateUp;
    const hotkeyBlockingInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );
    const isEscHotkeyBlocked = useCallback(() => hotkeyBlockingInterest, [hotkeyBlockingInterest]);
    useHotkeys("esc", goBack, {
        ignoreEventWhen: isEscHotkeyBlocked,
        enableOnFormTags: ["textarea"],
    });

    useEffect(() => {
        if (bondId && !bond) {
            const t = setTimeout(() => {
                navigate("/bond");
            }, 2 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [bond, bondId, navigate]);

    const raiseBondViewInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.RaiseBondView,
    );

    // Memoise the outlet context to avoid unnecessary rerenders
    const outletCtx: Optional<BondChildRouteContext> = useMemo(
        () => bondId ? { bondId } : undefined,
        [bondId],
    );

    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");

    if (!bondId || !bond) {
        return <h1>No bond found. Redirecting...</h1>;
    }

    if (phase3UIEnabled) {
        return (
            <>
                <BondViewStreamSubscriptions />
                <div className={"c-section c-bond"}>
                    <BondChatParticipants bondId={bondId} liveParticipants={orderedParticipants} />
                    <ChatView
                        ref={chatRef}
                        bondId={bondId}
                        scrollToMessageId={messageId}
                        showBondInterestedAction={showBondInterestedAction}
                        mediaControls={mediaControls}
                        liveParticipants={callParticipants}
                        preChatContent={<EarlyBondButtons bondId={bondId} />}
                    />
                    <Outlet context={outletCtx} />
                </div>
            </>
        );
    }

    const classes = classNames(
        "l-main",
        {
            "l-main--call-view": isLiveView,
            "l-main--bond-view": !isLiveView,
            "l-main--bond-view---raised": raiseBondViewInterest,
        },
    );

    return (
        <>
            <BondViewStreamSubscriptions />
            <main className={classes}>
                <BondChatParticipants bondId={bondId} liveParticipants={orderedParticipants} />
                <ChatView
                    ref={chatRef}
                    bondId={bondId}
                    scrollToMessageId={messageId}
                    showBondInterestedAction={showBondInterestedAction}
                    liveParticipants={callParticipants}
                    mediaControls={mediaControls}
                />
                <Outlet context={outletCtx} />
            </main>
        </>
    );
}
