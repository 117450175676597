import { useCallback, useMemo } from "react";

import Avatar from "./gui/Avatar";
import * as d from "../domain/domain";
import {
    OrderedParticipant,
    getAudio,
    getVideo,
    hasActiveVideo,
    isDisplay,
    getParticipantUserId,
    isTrackActive,
    getTileId,
    canOpenLiveView,
    DisplayMediaParticipant,
    Participant,
} from "../domain/rtc";
import {
    LiveActivityArgs,
    selectSortedBondParticipantIdsPair,
    selectBondContemporaries,
    selectSortedFollowerIdsPair,
    selectChannelIdByBondId,
} from "../features/bonds";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import ParticipantTile, { ParticipantTileProps, ParticipantType } from "./gui/ParticipantTile";
import useFreshBondObservers from "../hooks/useFreshBondObservers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { useAppDispatch, useAppSelector } from "../store/redux";
import {
    CallActionStatus,
    JoinedCallView,
    selectGridTilesSet,
    selectCallJoinStatus,
    selectJoinedCallView,
    openLiveView,
    closeLiveView,
} from "../features/calls";
import { Optional } from "../misc/types";
import {
    selectSortedUserIdsWithUnreadPair,
    selectUserIdSetWithMentions,
    selectUserIdSetWithUnread,
} from "../features/channels";
import { selectCurrentUserId } from "../features/auth";
import { separateDiscriminatedUnion } from "../misc/utils";
import { removeDuplicates } from "../misc/primatives";
import useSortedUsers from "../hooks/useSortedUsers";
import classNames from "classnames";
import ScrollDetector from "./gui/ScrollDetector";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import useScrollDetector from "../hooks/useScrollDetector";

// A fake OrderedParticipant.
// Used to treat non-live Bond Observers alongside OrderedParticipants.
type OrderedObserver = { userId: d.UserId; };
// Ordered Observer or Ordered Participant
type BondChatParticipant = OrderedObserver | OrderedParticipant;

const toOrderedObserver = (
    userId: d.UserId,
): OrderedObserver => ({ userId });

const isOrderedParticipant = (
    participant: BondChatParticipant,
): participant is OrderedParticipant => ("id" in participant);

const getTileIdWrapped = (participant: BondChatParticipant) => (
    isOrderedParticipant(participant) ? getTileId(participant) : `${participant.userId}-observer`
);

export interface BondChatParticipantsProps {
    bondId: d.BondId;
    liveParticipants?: OrderedParticipant[];
}

function BondChatParticipants(props: BondChatParticipantsProps): React.JSX.Element {
    const { bondId } = props;
    const liveParticipants = useShallowEqualsMemo(() => props.liveParticipants || [], [
        props.liveParticipants,
    ]);

    const phase3UIEnabled = useBooleanFeatureFlag("phase-3-ui");

    const dispatch = useAppDispatch();
    const { isScrolled: isScrolledAvatars, handleScroll: handleAvatarScroll } = useScrollDetector();
    const { isScrolled: isScrolledDisplays, handleScroll: handleDisplayScroll } =
        useScrollDetector();

    const currentUserId = useAppSelector(selectCurrentUserId);
    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    const { ids: bondObserverIds, idSet: bondObserverSet } = useFreshBondObservers(bondId);
    const sortedFollowerIds = useSortedUsers(selectSortedFollowerIdsPair, bondId);
    const liveParticipantIds = useShallowEqualsMemo(
        () => new Set(liveParticipants?.map(p => p.id)),
        [liveParticipants],
    );

    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isGrid = joinedCallView === JoinedCallView.Live;
    const isLive = callJoinStatus === CallActionStatus.Completed;

    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(liveParticipants, currentUserId),
        [liveParticipants, currentUserId],
    );
    const openLiveViewWithFocus = useCallback((id: Optional<string>) => () => {
        dispatch(openLiveView(id));
    }, [dispatch]);
    const tileIsClickable = useCallback(
        (op: OrderedParticipant) => hasActiveVideo(op) && (isGrid || canOpenLiveViewMemo),
        [isGrid, canOpenLiveViewMemo],
    );

    const closeGrid = useCallback(() => dispatch(closeLiveView()), [dispatch]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const bondSenderIds = useSortedUsers(selectSortedUserIdsWithUnreadPair, channelId);
    const userSetWithUnread = useSelectorArgs(selectUserIdSetWithUnread, channelId);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);

    const contemporarySet = useSelectorArgs(selectBondContemporaries, bondId);

    const gridTileSet = useAppSelector(selectGridTilesSet);
    const removeGridParticipants = useCallback(
        (bcp: BondChatParticipant) =>
            !(isOrderedParticipant(bcp) && gridTileSet.has(getTileIdWrapped(bcp))),
        [gridTileSet],
    );

    const [displayParticipants, liveUserParticipants] = useShallowEqualsMemo(
        () =>
            separateDiscriminatedUnion<DisplayMediaParticipant, Participant>(
                isDisplay,
                liveParticipants,
            ),
        [liveParticipants],
    );
    const displayParticipantsFiltered = useShallowEqualsMemo(
        () => isGrid ? displayParticipants.filter(removeGridParticipants) : displayParticipants,
        [displayParticipants, removeGridParticipants, isGrid],
    );

    // It is important that this includes all live users that we are aware of,
    // i.e. apply the removeGridParticipants filter at the end, NOT here.
    // This is so that we de-duplicate user tiles correctly.
    const liveParticipantUserIds = useShallowEqualsMemo(
        () => liveUserParticipants.map(getParticipantUserId),
        [liveUserParticipants],
    );

    // Store a map from user ID to participants so we can render their tracks after sorting them.
    // A user can join a call from multiple devices, so maintain an array for each user.
    const liveUserParticipantsMap = useShallowEqualsMemo(
        () =>
            liveUserParticipants.reduce((acc, part) => {
                const curr = acc.get(part.userId);
                acc.set(part.userId, curr ? curr.concat(part) : [part]);
                return acc;
            }, new Map<d.UserId, OrderedParticipant[]>()),
        [liveUserParticipants],
    );

    // Filter live participants with video so we can order them first
    const videoLiveUserIdSet = useShallowEqualsMemo(() => {
        return liveUserParticipants.filter(hasActiveVideo)
            .map(getParticipantUserId)
            .toSet();
    }, [liveUserParticipants]);
    const liveUserIdSet = useShallowEqualsMemo(
        () => liveParticipantUserIds.toSet(),
        [liveParticipantUserIds],
    );
    const liveUserArgs = useMemo((): LiveActivityArgs => ({
        videoUserSet: videoLiveUserIdSet,
        liveUserSet: liveUserIdSet,
        orderCurrentUserFirst: true,
    }), [liveUserIdSet, videoLiveUserIdSet]);
    const liveParticipantUserIdsSorted = useSortedUsers(
        selectSortedBondParticipantIdsPair,
        bondId,
        liveParticipantUserIds,
        liveUserArgs,
    );

    const participantUserIds = useShallowEqualsMemo(() => {
        return removeDuplicates(
            liveParticipantUserIdsSorted,
            bondObserverIds,
            // only show active people in phase 3
            phase3UIEnabled ? [] : bondSenderIds,
            phase3UIEnabled ? [] : sortedFollowerIds,
        );
    }, [
        phase3UIEnabled,
        liveParticipantUserIdsSorted,
        bondObserverIds,
        bondSenderIds,
        sortedFollowerIds,
    ]);
    useInterestedUsers(participantUserIds);

    const userParticipants = useShallowEqualsMemo(
        () =>
            participantUserIds.flatMap((userId): BondChatParticipant[] =>
                liveUserParticipantsMap.get(userId) ?? [toOrderedObserver(userId)]
            ),
        [liveUserParticipantsMap, participantUserIds],
    );
    const userParticipantsFiltered = useShallowEqualsMemo(
        () => isGrid ? userParticipants.filter(removeGridParticipants) : userParticipants,
        [isGrid, userParticipants, removeGridParticipants],
    );

    const getTileParams = useCallback(
        (op: BondChatParticipant): ParticipantTileProps => {
            const isLiveParticipant = isOrderedParticipant(op);
            return {
                participantUserId: op.userId,
                isSelf: currentUserId === op.userId,
                hideName: true,
                videoTitle: true,
                participantType: ParticipantType.User, // conditionally overwritten below

                ...isLiveParticipant && {
                    audioTrack: getAudio(op),
                    videoTrack: getVideo(op),

                    ...isDisplay(op) && { participantType: ParticipantType.Display },
                    ...tileIsClickable(op) && { onClick: openLiveViewWithFocus(getTileId(op)) },
                },
            };
        },
        [currentUserId, openLiveViewWithFocus, tileIsClickable],
    );

    const presenceClasses = useCallback((scrolled: boolean) =>
        classNames("c-composer-presence", {
            "c-composer-presence--live": isLive,
            "c-composer-presence--scrolled": scrolled && !isLive,
            "c-composer-presence--live-scrolled": isLive && scrolled,
        }), [isLive]);

    const renderAvatar = useCallback((tile: BondChatParticipant) => {
        const tileParams = getTileParams(tile);
        const userId = tile.userId;

        const isObserver = bondObserverSet.has(userId);
        const videoOn = isTrackActive(tileParams.videoTrack);
        const audioOn = isTrackActive(tileParams.audioTrack);
        const isDisplay = tileParams.participantType === ParticipantType.Display;
        const screenSharing = isDisplay && videoOn;

        // Determine liveness based on whether participants are in the RTC session.
        // Others are in the RTC session iff they are in liveParticipants.
        //
        // selfParticipant is always in liveParticipants, so use the inCallStatus
        // for the current user. Also check whether audio or video are on, in the
        // case that the user is attempting to start call by publishing.
        const isLiveParticipant = (userId === currentUserId) ?
            (callJoinStatus === CallActionStatus.Completed || audioOn || videoOn)
            : (isOrderedParticipant(tile) && liveParticipantIds.has(tile.id));

        /*
        // Reactivate this when the Human element supports video call tiles
        if (phase3UIEnabled) {
            return (
                <Human
                    key={getTileIdWrapped(tile)}
                    userId={userId}
                    modifiers={{
                        callParticipation: isLiveParticipant ? tileParams : undefined,
                        // mentionedCurrentUser: userSetWithMentions.has(userId),
                    }}
                />
            );
        }
            */

        return (
            <Avatar
                key={getTileIdWrapped(tile)}
                userId={userId}
                context={{
                    showBondPresence: true,
                    isScreenShare: screenSharing,
                }}
                modifiers={{
                    observingBond: isObserver || isLiveParticipant,
                    callParticipation: isLiveParticipant ? tileParams : undefined,
                    contributed: isLiveParticipant || userSetWithUnread.has(userId),
                    mentionedCurrentUser: userSetWithMentions.has(userId),
                    contemporary: contemporarySet?.has(userId) || false,
                }}
                hideName={phase3UIEnabled}
            />
        );
    }, [
        phase3UIEnabled,
        bondObserverSet,
        currentUserId,
        getTileParams,
        callJoinStatus,
        liveParticipantIds,
        userSetWithMentions,
        userSetWithUnread,
        contemporarySet,
    ]);

    const renderDisplay = (p: DisplayMediaParticipant) => (
        <div className="c-presence__share" key={`live-message-${p.id}`}>
            <ParticipantTile
                key={p.id}
                isSelf={false}
                participantUserId={p.userId}
                videoTrack={p.displayMediaTrack}
                participantType={ParticipantType.Display}
                onClick={openLiveViewWithFocus(getTileId(p))}
                hideName={true}
                videoTitle={true}
            />
        </div>
    );

    return (
        <>
            <div className={presenceClasses(isScrolledAvatars)}>
                {phase3UIEnabled && canOpenLiveViewMemo && (
                    <div className="c-bonds-presence__expand">
                        <button
                            onClick={isGrid ? closeGrid : openLiveViewWithFocus(undefined)}
                            className="cp-btn-call-grid"
                            title={`${isGrid ? "Close" : "Expand"} call grid`}
                        >
                            {isGrid ? "Close" : "Expand"}
                        </button>
                    </div>
                )}
                <ScrollDetector
                    onScroll={handleAvatarScroll}
                    className="c-composer-presence__wrapper"
                >
                    <div className="c-presence__humans">
                        {isGrid && displayParticipantsFiltered.map(renderAvatar)}
                        {userParticipantsFiltered.map(renderAvatar)}
                    </div>
                </ScrollDetector>
            </div>
            {!isGrid && displayParticipantsFiltered.length > 0 && (
                <div className={presenceClasses(isScrolledDisplays)}>
                    <ScrollDetector
                        onScroll={handleDisplayScroll}
                        className="c-composer-presence__wrapper"
                    >
                        <div className="c-presence__shares">
                            {displayParticipantsFiltered.map(renderDisplay)}
                        </div>
                    </ScrollDetector>
                </div>
            )}
        </>
    );
}

export default BondChatParticipants;
