import {
    CallActionStatus,
    selectCallJoinStatus,
    selectCallStartStatus,
    setCallJoinStatus,
    setCallJoinStatusFromCurrentCallId,
} from "../../features/calls";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useActiveEffect } from "../useActiveEffect";
import { useRtcActionBackoff } from "./useRtcActionBackoff";

export function useRtcConnection() {
    const dispatch = useAppDispatch();
    const callStartStatus = useAppSelector(selectCallStartStatus);
    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    // When a call has been started, and we're focused and active in the window,
    // prepare to connect to it.
    useActiveEffect(() => {
        if (
            callStartStatus === CallActionStatus.Completed &&
            callJoinStatus === CallActionStatus.None
        ) {
            dispatch(setCallJoinStatus(CallActionStatus.Ready));
        }
    }, [callStartStatus, callJoinStatus, dispatch]);

    // Connect to the call with backoff + retries
    const {
        signal: connectionSignal,
        getActionReady: getCanConnect,
        sendSignal: sendConnectionSignal,
    } = useRtcActionBackoff({
        selectCallStatus: selectCallJoinStatus,
        setCallStatusFromCurrentCallId: setCallJoinStatusFromCurrentCallId,
    });

    return { connectionSignal, getCanConnect, sendConnectionSignal };
}
