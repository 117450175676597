// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file domain/domain.proto (package domain, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum domain.PrivacyLevel
 */
export const PrivacyLevel = proto3.makeEnum(
  "domain.PrivacyLevel",
  [
    {no: 0, name: "PRIVACY_LEVEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRIVACY_LEVEL_OPEN", localName: "OPEN"},
    {no: 2, name: "PRIVACY_LEVEL_PRIVATE", localName: "PRIVATE"},
  ],
);

/**
 * @generated from enum domain.InvolvementRole
 */
export const InvolvementRole = proto3.makeEnum(
  "domain.InvolvementRole",
  [
    {no: 0, name: "INVOLVEMENT_ROLE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "INVOLVEMENT_ROLE_FOLLOWER", localName: "FOLLOWER"},
    {no: 2, name: "INVOLVEMENT_ROLE_CONTRIBUTOR", localName: "CONTRIBUTOR"},
    {no: 3, name: "INVOLVEMENT_ROLE_ORIGINATOR", localName: "ORIGINATOR"},
    {no: 4, name: "INVOLVEMENT_ROLE_EXTERNAL", localName: "EXTERNAL"},
    {no: 5, name: "INVOLVEMENT_ROLE_UNFOLLOWED", localName: "UNFOLLOWED"},
    {no: 6, name: "INVOLVEMENT_ROLE_ARCHIVED", localName: "ARCHIVED"},
  ],
);

/**
 * @generated from enum domain.NotifType
 */
export const NotifType = proto3.makeEnum(
  "domain.NotifType",
  [
    {no: 0, name: "NOTIF_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "NOTIF_TYPE_TEXT", localName: "TEXT"},
    {no: 2, name: "NOTIF_TYPE_CHAT_MESSAGE", localName: "CHAT_MESSAGE"},
    {no: 3, name: "NOTIF_TYPE_CALL_START", localName: "CALL_START"},
  ],
);

/**
 * @generated from enum domain.NotifPriority
 */
export const NotifPriority = proto3.makeEnum(
  "domain.NotifPriority",
  [
    {no: 0, name: "NOTIF_PRIORITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "NOTIF_PRIORITY_LOW", localName: "LOW"},
    {no: 2, name: "NOTIF_PRIORITY_MEDIUM", localName: "MEDIUM"},
    {no: 3, name: "NOTIF_PRIORITY_HIGH", localName: "HIGH"},
    {no: 4, name: "NOTIF_PRIORITY_TIME_SENSITIVE", localName: "TIME_SENSITIVE"},
    {no: 5, name: "NOTIF_PRIORITY_URGENT", localName: "URGENT"},
    {no: 6, name: "NOTIF_PRIORITY_CRITICAL", localName: "CRITICAL"},
  ],
);

/**
 * @generated from enum domain.MessageType
 */
export const MessageType = proto3.makeEnum(
  "domain.MessageType",
  [
    {no: 0, name: "MESSAGE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MESSAGE_TYPE_CHAT", localName: "CHAT"},
    {no: 2, name: "MESSAGE_TYPE_CALL_START", localName: "CALL_START"},
    {no: 3, name: "MESSAGE_TYPE_CALL_END", localName: "CALL_END"},
  ],
);

/**
 * @generated from enum domain.BlobState
 */
export const BlobState = proto3.makeEnum(
  "domain.BlobState",
  [
    {no: 0, name: "BLOB_STATE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BLOB_STATE_PENDING", localName: "PENDING"},
    {no: 2, name: "BLOB_STATE_COMPLETE", localName: "COMPLETE"},
  ],
);

/**
 * @generated from enum domain.BlobCategory
 */
export const BlobCategory = proto3.makeEnum(
  "domain.BlobCategory",
  [
    {no: 0, name: "BLOB_CATEGORY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BLOB_CATEGORY_ATTACHMENT", localName: "ATTACHMENT"},
    {no: 2, name: "BLOB_CATEGORY_AVATAR_IMAGE", localName: "AVATAR_IMAGE"},
    {no: 3, name: "BLOB_CATEGORY_LLM_INTERACTION", localName: "LLM_INTERACTION"},
  ],
);

/**
 * @generated from enum domain.MentionAction
 */
export const MentionAction = proto3.makeEnum(
  "domain.MentionAction",
  [
    {no: 0, name: "MENTION_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MENTION_ACTION_ADD", localName: "ADD"},
    {no: 2, name: "MENTION_ACTION_ADD_NOTIFY", localName: "ADD_NOTIFY"},
    {no: 3, name: "MENTION_ACTION_REMOVE", localName: "REMOVE"},
  ],
);

/**
 * @generated from enum domain.PresenceMode
 */
export const PresenceMode = proto3.makeEnum(
  "domain.PresenceMode",
  [
    {no: 0, name: "PRESENCE_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRESENCE_MODE_NONE", localName: "NONE"},
    {no: 2, name: "PRESENCE_MODE_TYPING", localName: "TYPING"},
    {no: 3, name: "PRESENCE_MODE_SPEAKING", localName: "SPEAKING"},
    {no: 4, name: "PRESENCE_MODE_VIDEOING", localName: "VIDEOING"},
    {no: 5, name: "PRESENCE_MODE_SCREEN_SHARING", localName: "SCREEN_SHARING"},
  ],
);

/**
 * @generated from enum domain.BondKnowledgeType
 */
export const BondKnowledgeType = proto3.makeEnum(
  "domain.BondKnowledgeType",
  [
    {no: 0, name: "BOND_KNOWLEDGE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BOND_KNOWLEDGE_TYPE_USER_SPECIFIED_TITLE", localName: "USER_SPECIFIED_TITLE"},
    {no: 2, name: "BOND_KNOWLEDGE_TYPE_AI_GENERATED_TITLE", localName: "AI_GENERATED_TITLE"},
    {no: 3, name: "BOND_KNOWLEDGE_TYPE_SUMMARY_DETAILED", localName: "SUMMARY_DETAILED"},
    {no: 4, name: "BOND_KNOWLEDGE_TYPE_SUMMARY_ALL_CONTENT", localName: "SUMMARY_ALL_CONTENT"},
    {no: 5, name: "BOND_KNOWLEDGE_TYPE_IMAGE_URL", localName: "IMAGE_URL"},
  ],
);

/**
 * @generated from enum domain.CallKnowledgeType
 */
export const CallKnowledgeType = proto3.makeEnum(
  "domain.CallKnowledgeType",
  [
    {no: 0, name: "CALL_KNOWLEDGE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CALL_KNOWLEDGE_TYPE_LIVE_CALL", localName: "LIVE_CALL"},
    {no: 2, name: "CALL_KNOWLEDGE_TYPE_ENDED_CALL", localName: "ENDED_CALL"},
    {no: 3, name: "CALL_KNOWLEDGE_TYPE_DETAILED_SUMMARY_ONLY", localName: "DETAILED_SUMMARY_ONLY"},
  ],
);

/**
 * @generated from enum domain.FiletypeType
 */
export const FiletypeType = proto3.makeEnum(
  "domain.FiletypeType",
  [
    {no: 0, name: "FILETYPE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FILETYPE_TYPE_IMAGE_STATIC", localName: "IMAGE_STATIC"},
    {no: 2, name: "FILETYPE_TYPE_IMAGE_ANIMATED", localName: "IMAGE_ANIMATED"},
    {no: 3, name: "FILETYPE_TYPE_DOCUMENT_TEXT", localName: "DOCUMENT_TEXT"},
    {no: 4, name: "FILETYPE_TYPE_DOCUMENT_PDF", localName: "DOCUMENT_PDF"},
    {no: 5, name: "FILETYPE_TYPE_DOCUMENT_WORD", localName: "DOCUMENT_WORD"},
    {no: 6, name: "FILETYPE_TYPE_DOCUMENT_HTML", localName: "DOCUMENT_HTML"},
  ],
);

/**
 * @generated from enum domain.CallEventType
 */
export const CallEventType = proto3.makeEnum(
  "domain.CallEventType",
  [
    {no: 0, name: "CALL_EVENT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CALL_EVENT_TYPE_SESSION_START", localName: "SESSION_START"},
    {no: 2, name: "CALL_EVENT_TYPE_SESSION_END", localName: "SESSION_END"},
    {no: 3, name: "CALL_EVENT_TYPE_PARTICIPANT_JOIN", localName: "PARTICIPANT_JOIN"},
    {no: 4, name: "CALL_EVENT_TYPE_PARTICIPANT_LEAVE", localName: "PARTICIPANT_LEAVE"},
    {no: 5, name: "CALL_EVENT_TYPE_TRACK_START", localName: "TRACK_START"},
    {no: 6, name: "CALL_EVENT_TYPE_TRACK_END", localName: "TRACK_END"},
    {no: 7, name: "CALL_EVENT_TYPE_TRANSCRIPT", localName: "TRANSCRIPT"},
    {no: 8, name: "CALL_EVENT_TYPE_PARTICIPANT_PERMISSIONS", localName: "PARTICIPANT_PERMISSIONS"},
    {no: 9, name: "CALL_EVENT_TYPE_PARTICIPANT_SFU", localName: "PARTICIPANT_SFU"},
    {no: 10, name: "CALL_EVENT_TYPE_PARTICIPANT_SIG", localName: "PARTICIPANT_SIG"},
    {no: 11, name: "CALL_EVENT_TYPE_PARTICIPANT_STATE", localName: "PARTICIPANT_STATE"},
    {no: 12, name: "CALL_EVENT_TYPE_TRACK_MUTE", localName: "TRACK_MUTE"},
  ],
);

/**
 * @generated from message domain.CacheSeq
 */
export const CacheSeq = proto3.makeMessageType(
  "domain.CacheSeq",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message domain.UUID
 */
export const UUID = proto3.makeMessageType(
  "domain.UUID",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message domain.AttachmentKnowledgeId
 */
export const AttachmentKnowledgeId = proto3.makeMessageType(
  "domain.AttachmentKnowledgeId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.BlobId
 */
export const BlobId = proto3.makeMessageType(
  "domain.BlobId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.BondId
 */
export const BondId = proto3.makeMessageType(
  "domain.BondId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.BondKnowledgeId
 */
export const BondKnowledgeId = proto3.makeMessageType(
  "domain.BondKnowledgeId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.CallId
 */
export const CallId = proto3.makeMessageType(
  "domain.CallId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.ChannelId
 */
export const ChannelId = proto3.makeMessageType(
  "domain.ChannelId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.ConnectionId
 */
export const ConnectionId = proto3.makeMessageType(
  "domain.ConnectionId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.CrewId
 */
export const CrewId = proto3.makeMessageType(
  "domain.CrewId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.DeviceId
 */
export const DeviceId = proto3.makeMessageType(
  "domain.DeviceId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.MessageId
 */
export const MessageId = proto3.makeMessageType(
  "domain.MessageId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.MessageTargetId
 */
export const MessageTargetId = proto3.makeMessageType(
  "domain.MessageTargetId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.NotifId
 */
export const NotifId = proto3.makeMessageType(
  "domain.NotifId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.OrgId
 */
export const OrgId = proto3.makeMessageType(
  "domain.OrgId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.RtcParticipantId
 */
export const RtcParticipantId = proto3.makeMessageType(
  "domain.RtcParticipantId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.PersonId
 */
export const PersonId = proto3.makeMessageType(
  "domain.PersonId",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message domain.RtcSessionId
 */
export const RtcSessionId = proto3.makeMessageType(
  "domain.RtcSessionId",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message domain.SquadId
 */
export const SquadId = proto3.makeMessageType(
  "domain.SquadId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.UserId
 */
export const UserId = proto3.makeMessageType(
  "domain.UserId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.KnowledgeId
 */
export const KnowledgeId = proto3.makeMessageType(
  "domain.KnowledgeId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.CallKnowledgeId
 */
export const CallKnowledgeId = proto3.makeMessageType(
  "domain.CallKnowledgeId",
  () => [
    { no: 1, name: "value", kind: "message", T: UUID },
  ],
);

/**
 * @generated from message domain.CallParticipationRole
 */
export const CallParticipationRole = proto3.makeMessageType(
  "domain.CallParticipationRole",
  () => [
    { no: 1, name: "is_observing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_publishing_microphone", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_publishing_camera", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_publishing_screen_share", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message domain.UserSet
 */
export const UserSet = proto3.makeMessageType(
  "domain.UserSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: UserId, repeated: true },
  ],
);

/**
 * @generated from message domain.PersonSet
 */
export const PersonSet = proto3.makeMessageType(
  "domain.PersonSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: PersonId, repeated: true },
  ],
);

/**
 * @generated from message domain.SquadSet
 */
export const SquadSet = proto3.makeMessageType(
  "domain.SquadSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: SquadId, repeated: true },
  ],
);

/**
 * @generated from message domain.BlobSet
 */
export const BlobSet = proto3.makeMessageType(
  "domain.BlobSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: BlobId, repeated: true },
  ],
);

/**
 * @generated from message domain.BondSet
 */
export const BondSet = proto3.makeMessageType(
  "domain.BondSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: BondId, repeated: true },
  ],
);

/**
 * @generated from message domain.BondKnowledgeSet
 */
export const BondKnowledgeSet = proto3.makeMessageType(
  "domain.BondKnowledgeSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: BondKnowledgeId, repeated: true },
  ],
);

/**
 * @generated from message domain.DeviceSet
 */
export const DeviceSet = proto3.makeMessageType(
  "domain.DeviceSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: DeviceId, repeated: true },
  ],
);

/**
 * @generated from message domain.CallSet
 */
export const CallSet = proto3.makeMessageType(
  "domain.CallSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: CallId, repeated: true },
  ],
);

/**
 * @generated from message domain.CallKnowledgeSet
 */
export const CallKnowledgeSet = proto3.makeMessageType(
  "domain.CallKnowledgeSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: CallKnowledgeId, repeated: true },
  ],
);

/**
 * @generated from message domain.AttachmentKnowledgeSet
 */
export const AttachmentKnowledgeSet = proto3.makeMessageType(
  "domain.AttachmentKnowledgeSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: AttachmentKnowledgeId, repeated: true },
  ],
);

/**
 * @generated from message domain.ChannelSet
 */
export const ChannelSet = proto3.makeMessageType(
  "domain.ChannelSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: ChannelId, repeated: true },
  ],
);

/**
 * @generated from message domain.ConnectionSet
 */
export const ConnectionSet = proto3.makeMessageType(
  "domain.ConnectionSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: ConnectionId, repeated: true },
  ],
);

/**
 * @generated from message domain.CrewSet
 */
export const CrewSet = proto3.makeMessageType(
  "domain.CrewSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: CrewId, repeated: true },
  ],
);

/**
 * @generated from message domain.MessageSet
 */
export const MessageSet = proto3.makeMessageType(
  "domain.MessageSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: MessageId, repeated: true },
  ],
);

/**
 * @generated from message domain.MessageTargetSet
 */
export const MessageTargetSet = proto3.makeMessageType(
  "domain.MessageTargetSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: MessageTargetId, repeated: true },
  ],
);

/**
 * @generated from message domain.NotifSet
 */
export const NotifSet = proto3.makeMessageType(
  "domain.NotifSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: NotifId, repeated: true },
  ],
);

/**
 * @generated from message domain.OrgSet
 */
export const OrgSet = proto3.makeMessageType(
  "domain.OrgSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: OrgId, repeated: true },
  ],
);

/**
 * @generated from message domain.RtcParticipantSet
 */
export const RtcParticipantSet = proto3.makeMessageType(
  "domain.RtcParticipantSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: RtcParticipantId, repeated: true },
  ],
);

/**
 * @generated from message domain.RtcSessionSet
 */
export const RtcSessionSet = proto3.makeMessageType(
  "domain.RtcSessionSet",
  () => [
    { no: 1, name: "ids", kind: "message", T: RtcSessionId, repeated: true },
  ],
);

/**
 * @generated from message domain.Mention
 */
export const Mention = proto3.makeMessageType(
  "domain.Mention",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId, oneof: "target" },
    { no: 2, name: "squad_id", kind: "message", T: SquadId, oneof: "target" },
    { no: 3, name: "action", kind: "enum", T: proto3.getEnumType(MentionAction) },
  ],
);

/**
 * @generated from message domain.MentionSet
 */
export const MentionSet = proto3.makeMessageType(
  "domain.MentionSet",
  () => [
    { no: 1, name: "mentions", kind: "message", T: Mention, repeated: true },
  ],
);

/**
 * @generated from message domain.UserDefinition
 */
export const UserDefinition = proto3.makeMessageType(
  "domain.UserDefinition",
  () => [
    { no: 3, name: "user_id", kind: "message", T: UserId },
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
  ],
);

