import classNames from "classnames";
import { SidebarLinks } from "../components/SidebarLinks";
import { SquadAndMyBondsSelector } from "../components/SquadAndMyBondsSelector";
import { UserInfoAndOrgSelector } from "../components/UserInfoAndOrgSelector";
import { VersionInfo } from "../components/VersionInfo";
import { isMobileBrowser } from "../misc/mobile";

export function SidebarView(): React.JSX.Element {
    const classes = classNames("c-sidebar", {
        "c-sidebar--desktop": !isMobileBrowser(),
    });

    return (
        <aside className={classes}>
            <div className="c-sidebar__top">
                <SquadAndMyBondsSelector showMyBonds={true} location="isAside" />
            </div>
            <div className="c-sidebar__bottom">
                <SidebarLinks location="isAside" />
                <VersionInfo location="isAside" />
                <UserInfoAndOrgSelector location="isAside" />
            </div>
        </aside>
    );
}
