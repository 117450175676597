import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import cloneDeep from "lodash.clonedeep";
import Delta, { Op } from "quill-delta";
import type { EmitterSource } from "quill/core";
import { ExpandType, isString, Optional } from "../misc/types";
import { Mention } from "./mentions";
import Emitter from "quill/core/emitter";

// region Types

const EmitterSources = Emitter.sources;
export { Delta, EmitterSources, EmitterSource, Op };

/**
 * A wrapped `Delta` instance that must only contain 'insert' operations and must
 * end with a newline character
 *
 * This type is used for deltas that come from or are passed into a Quill editor
 * instance, or must be composed or otherwise modified using the methods available
 * on the `Delta` type
 */
export interface MarkupDelta {
    case: "markup";
    _instance: Delta;
}

/**
 * A wrapped `Delta` instance that can contain any operations, but cannot be rendered
 *
 * This type is used for deltas that come from or are passed into a Quill editor
 * instance, or must be composed or otherwise modified using the methods available
 * on the `Delta` type
 */
export interface ChangeDelta {
    case: "change";
    _instance: Delta;
}

/**
 * Object with string keys containing attributes associated with an Op
 */
export type OpAttributes = Record<string, unknown>;

/**
 * An operation which inserts either text or an embed, optionally providing associated attributes
 */
export interface InsertOp {
    insert: string | Record<string, unknown>;
    attributes?: OpAttributes;
}

/**
 * An operation which inserts text, optionally providing associated attributes
 */
export type TextInsertOp = Omit<InsertOp, "insert"> & { insert: string; };

/**
 * An operation which retains some number of characters, optionally updating the attributes
 */
export interface RetainOp {
    retain?: number | Record<string, unknown>;
    attributes?: OpAttributes;
}

/**
 * An operation which deletes some number of characters
 */
export interface DeleteOp {
    delete: number;
}

/**
 * An array of insert operations which can be converted to a `MarkupDelta` instance
 *
 * This type is used for deltas that are stored in the Redux store and sent over the wire
 */
export type CompactMarkupDelta = InsertOp[];

/**
 * Helper type which contains a single line of markup and any attributes associated with that line
 */
export interface MarkupLine {
    ops: InsertOp[];
    lineAttributes?: OpAttributes;
}

/**
 * Equivalent to `MarkupDelta`, but with an array of operations in place of the `Delta`
 * instance. This allows it to be serialised in Redux actions and state
 *
 * This type is used for deltas that are stored in Redux actions
 */
export interface PlainMarkupDelta {
    case: "markup";
    ops: InsertOp[];
}

/**
 * Equivalent to `ChangeDelta`, but with an array of operations in place of the `Delta`
 * instance. This allows it to be serialised in Redux actions and state
 *
 * This type is used for deltas that are stored in Redux actions
 */
export interface PlainChangeDelta {
    case: "change";
    ops: (InsertOp | RetainOp | DeleteOp)[];
}

type AnyTaggedDelta = MarkupDelta | ChangeDelta;
type AnyMarkupDelta = MarkupDelta | PlainMarkupDelta | CompactMarkupDelta;
type AnyChangeDelta = ChangeDelta | PlainChangeDelta;

type DeltaInstance = InstanceType<typeof Delta>;
type DeltaInsertParameters = Parameters<DeltaInstance["insert"]>;
type DeltaRetainParameters = Parameters<DeltaInstance["retain"]>;
type DeltaDeleteParameters = Parameters<DeltaInstance["delete"]>;

// region Custom embeds

/**
 * A value which uniquely specifies a mention to be embedded in markup
 */
export type MentionOpValue = ExpandType<
    Mention & {
        text: string;
    }
>;

/**
 * Narrowing of `InsertOp` which specifically inserts a mention
 */
export type MentionOp = ExpandType<
    Omit<InsertOp, "insert"> & {
        insert: { mention: MentionOpValue; };
    }
>;

// region Type guards

const isTaggedDelta = (delta: AnyMarkupDelta | AnyChangeDelta): delta is AnyTaggedDelta =>
    "_instance" in delta;

const isCompactDelta = (delta: AnyMarkupDelta): delta is CompactMarkupDelta => Array.isArray(delta);

/**
 * Type guard to determine if an `Op` is a `MentionOp`
 */
export const isMentionOp = (op: Op): op is MentionOp =>
    typeof op.insert == "object" && "mention" in op.insert;

/**
 * Type guard to determine if an `Op` is a `TextInsertOp`
 */
export const isTextInsertOp = (op: Op): op is TextInsertOp => isString(op.insert);

// region Converters

/**
 * Convert a `MarkupDelta` or `PlainMarkupDelta` instance to a `CompactMarkupDelta`
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const compactMarkupDelta = (
    delta: Optional<MarkupDelta | PlainMarkupDelta>,
): CompactMarkupDelta => {
    if (delta === undefined) {
        return [];
    }
    else if (isTaggedDelta(delta)) {
        // This cast is safe so long as the markup delta was built using
        // markupDeltaBuilder, which prevents non-insert ops being added
        return delta._instance.ops as InsertOp[];
    }
    else {
        return delta.ops;
    }
};

/**
 * Convert a `MarkupDelta` to a `PlainMarkupDelta`
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const plainifyMarkupDelta = (
    delta: Optional<PlainMarkupDelta | MarkupDelta>,
): PlainMarkupDelta => {
    if (delta === undefined) {
        return markupDeltaBuilder().buildPlain();
        return { case: "markup", ops: [{ insert: "\n" }] };
    }
    else if (isTaggedDelta(delta)) {
        return {
            case: "markup",
            // This cast is safe so long as the markup delta was built using
            // markupDeltaBuilder, which prevents non-insert ops being added
            ops: delta._instance.ops as InsertOp[],
        };
    }
    else {
        return delta;
    }
};

/**
 * Convert a `ChangeDelta` to a `PlainChangeDelta`
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const plainifyChangeDelta = (
    delta: Optional<PlainChangeDelta | ChangeDelta>,
): PlainChangeDelta => {
    if (delta === undefined) {
        return { case: "change", ops: [] };
    }
    else if (isTaggedDelta(delta)) {
        return { case: "change", ops: delta._instance.ops };
    }
    else {
        return delta;
    }
};

/**
 * Convert any of `MarkupDelta`, `CompactMarkupDelta`, or `PlainMarkupDelta` into a `MarkupDelta`.
 * If a `MarkupDelta` is provided it will be returned unmodified
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const parseMarkupDelta = (
    delta: Optional<MarkupDelta | CompactMarkupDelta | PlainMarkupDelta>,
): MarkupDelta => {
    if (delta === undefined) {
        return emptyMarkupDelta;
    }
    else if (isTaggedDelta(delta)) {
        return delta;
    }
    else if (isCompactDelta(delta)) {
        return markupDeltaBuilder()
            .pushAll(delta)
            .build();
    }
    else {
        return markupDeltaBuilder()
            .pushAll(delta.ops)
            .build();
    }
};

/**
 * Convert any of `ChangeDelta`, `CompactChangeDelta`, or `PlainChangeDelta` into a `ChangeDelta`.
 * If a `ChangeDelta` is provided it will be returned unmodified
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const parseChangeDelta = (
    delta: Optional<AnyChangeDelta>,
): ChangeDelta => {
    if (delta === undefined) {
        return emptyChangeDelta;
    }
    else if (isTaggedDelta(delta)) {
        return delta;
    }
    else {
        return changeDeltaBuilder()
            .pushAll(delta.ops)
            .build();
    }
};

/**
 * Wrap an existing `Delta` instance as a `MarkupDelta`. If there are any retain or delete
 * operations in the input, throw an error
 *
 * The delta will not be deep copied, so it should not be modified after the call
 */
export const asMarkupDelta = (delta: Delta): MarkupDelta => {
    if (delta.ops.some(op => op.retain || op.delete)) {
        throw new Error("Delta cannot be cast as markup delta as it has retain or delete ops");
    }
    return { case: "markup", _instance: delta };
};

/**
 * Wrap an existing `Delta` instance as a `ChangeDelta`
 *
 * The delta will not be deep copied, so it should not be modified after the call
 */
export const asChangeDelta = (delta: Delta): ChangeDelta => ({ case: "change", _instance: delta });

// region Builders

/**
 * Interface for creating a `MarkupDelta` instance using the builder pattern
 */
interface MarkupDeltaBuilder {
    build: () => MarkupDelta;
    buildPlain: () => PlainMarkupDelta;
    buildCompact: () => CompactMarkupDelta;
    insert: (...args: DeltaInsertParameters) => MarkupDeltaBuilder;
    pushAll: (ops: InsertOp[]) => MarkupDeltaBuilder;
}

/**
 * Interface for creating a `ChangeDelta` instance using the builder pattern
 */
interface ChangeDeltaBuilder {
    build: () => ChangeDelta;
    buildPlain: () => PlainChangeDelta;
    insert: (...args: DeltaInsertParameters) => ChangeDeltaBuilder;
    retain: (...args: DeltaRetainParameters) => ChangeDeltaBuilder;
    delete: (...args: DeltaDeleteParameters) => ChangeDeltaBuilder;
    pushAll: (ops: (InsertOp | RetainOp | DeleteOp)[]) => ChangeDeltaBuilder;
}

/**
 * Return a `MarkupDeltaBuilder` instance which can be used to build a `MarkupDelta` instance
 */
export const markupDeltaBuilder = (): MarkupDeltaBuilder => {
    const delta = new Delta();
    const builder = {
        build: (): MarkupDelta => {
            // Append a terminal newline if it doesn't already exist
            const lastOp = delta.ops.at(-1);
            if (!isString(lastOp?.insert) || !lastOp.insert.endsWith("\n")) {
                delta.insert("\n");
            }
            return ({
                case: "markup",
                _instance: delta,
            });
        },
        buildPlain: (): PlainMarkupDelta => plainifyMarkupDelta(builder.build()),
        buildCompact: (): CompactMarkupDelta => compactMarkupDelta(builder.build()),
        insert: (...args: DeltaInsertParameters) => {
            delta.insert(...args);
            return builder;
        },
        pushAll: (ops: InsertOp[]) => {
            ops.forEach(op => delta.push(op));
            return builder;
        },
    };
    return builder;
};

/**
 * Return a `ChangeDeltaBuilder` instance which can be used to build a `MarkupDelta` instance
 */
export const changeDeltaBuilder = (): ChangeDeltaBuilder => {
    const delta = new Delta();
    const builder = {
        build: (): ChangeDelta => ({
            case: "change",
            _instance: delta.chop(),
        }),
        buildPlain: (): PlainChangeDelta => plainifyChangeDelta(builder.build()),
        insert: (...args: DeltaInsertParameters) => {
            delta.insert(...args);
            return builder;
        },
        retain: (...args: DeltaRetainParameters) => {
            delta.retain(...args);
            return builder;
        },
        delete: (...args: DeltaDeleteParameters) => {
            delta.delete(...args);
            return builder;
        },
        pushAll: (ops: (InsertOp | RetainOp | DeleteOp)[]) => {
            ops.forEach(op => delta.push(op));
            return builder;
        },
    };
    return builder;
};

export const emptyMarkupDelta = Object.freeze(markupDeltaBuilder().build());
export const emptyChangeDelta = Object.freeze(changeDeltaBuilder().build());

// region Operations

/**
 * Slice a `TaggedDelta`, indexed by characters
 */
export const sliceDelta = <D extends AnyTaggedDelta>(
    delta: D,
    start?: number,
    end?: number,
): D => ({
    case: delta.case,
    _instance: delta._instance.slice(start, end),
} as D);

/**
 * Compose a `TaggedDelta` with a `ChangeDelta`. The return type will be the same as the first
 * delta type. If the first delta is a `MarkupDelta`, this will also remove any trailing retain
 * and delete operations, in order to preserve the markup only-insert invariant
 */
export const composeDeltas = <A extends AnyTaggedDelta>(
    a: A,
    b: AnyChangeDelta,
): A => ({
    case: a.case,
    _instance: new Delta(
        a._instance
            .compose(
                ("_instance" in b) ?
                    b._instance :
                    parseChangeDelta(b)._instance,
            )
            .filter(op => a.case == "change" || op.insert !== undefined),
    ),
} as A);

/**
 * Concatenate the operations of two `TaggedDelta` instances. Both instances must be the same type
 */
export const concatDeltas = <D extends AnyTaggedDelta>(a: D, b: D): D => ({
    case: a.case,
    _instance: a._instance.concat(b._instance),
} as D);

/**
 * Return true if all the operations of two `TaggedDelta` instances match, and they are of the same type
 */
export const compareDeltas = (a: Optional<AnyTaggedDelta>, b: Optional<AnyTaggedDelta>): boolean =>
    a === b ||
    a !== undefined &&
        b !== undefined &&
        a.case == b.case &&
        isEqual(a._instance.ops, b._instance.ops);

/**
 * Return true if a `MarkupDelta` instance has no content other than the trailing newline
 */
export const isMarkupDeltaEmpty = (markup: MarkupDelta): boolean =>
    compareDeltas(markup, emptyMarkupDelta);

interface renderDeltaToTextOptions {
    expandMentions: boolean;
    renderPrefilled: boolean;
}

const renderDeltaToText = (
    delta: Optional<AnyMarkupDelta>,
    options: renderDeltaToTextOptions,
): string => {
    const { expandMentions, renderPrefilled } = options;

    const opToString = (op: Op | InsertOp) => {
        if (op.insert === undefined) {
            throw new TypeError(`only "insert" operations can be transformed`);
        }

        if (!renderPrefilled && op.attributes?.prefilled) {
            return "";
        }

        if (isString(op.insert)) {
            return op.insert;
        }

        if (expandMentions && isMentionOp(op)) {
            return op.insert.mention.text;
        }

        return " ";
    };

    if (delta === undefined) {
        return "";
    }
    else if (isTaggedDelta(delta)) {
        return delta._instance.ops
            .map(opToString)
            .join("")
            .replace(/\n$/, "");
    }
    else if (isCompactDelta(delta)) {
        return delta
            .map(opToString)
            .join("")
            .replace(/\n$/, "");
    }
    else {
        return delta.ops
            .map(opToString)
            .join("")
            .replace(/\n$/, "");
    }
};

/**
 * Convert a `MarkupDelta`, `PlainMarkupDelta`, or `CompactMarkupDelta` instance to text,
 * expanding mentions and including prefilled sections. This should produce a string that
 * matches what is visible to the user
 */
export const renderDeltaToTextForDisplay = (
    delta: Optional<AnyMarkupDelta>,
) => renderDeltaToText(delta, {
    expandMentions: true,
    renderPrefilled: true,
});

/**
 * Convert a `MarkupDelta`, `PlainMarkupDelta`, or `CompactMarkupDelta` instance to text,
 * including prefilled sections, but not expanding mentions. This should produce a string
 * that can be used to determine if there is an active autocomplete query at the caret
 */
export const renderDeltaToTextForAutoCompleteQuery = (
    delta: Optional<AnyMarkupDelta>,
) => renderDeltaToText(delta, {
    expandMentions: false,
    renderPrefilled: true,
});

/**
 * Convert a `MarkupDelta`, `PlainMarkupDelta`, or `CompactMarkupDelta` instance to text,
 * expanding mentions, but not including prefilled sections. This should produce a string
 * that can be used to query for suggested bonds
 */
export const renderDeltaToTextForSuggestionsQuery = (
    delta: Optional<AnyMarkupDelta>,
) => renderDeltaToText(delta, {
    expandMentions: true,
    renderPrefilled: false,
});

/**
 * Return the length of an insert operation in characters
 *
 * @param expandMentions if true, use the text content of the mention, otherwise return 1
 */
export const insertOpLength = (op: InsertOp, expandMentions: boolean) =>
    isString(op.insert) ?
        op.insert.length :
        ((expandMentions && isMentionOp(op)) ?
            op.insert.mention.text.length :
            1);

/**
 * Return the length of an insert operation in characters
 *
 * @param expandMentions if true, use the text content of the mention, otherwise return 1
 */
export const annotateMarkupDeltaOps = (
    delta: Optional<MarkupDelta>,
    expandMentions: boolean = true,
): [InsertOp, number][] => {
    if (delta === undefined) return [];

    let acc = 0;
    return delta._instance.ops.map(op => {
        const index = acc;
        acc += insertOpLength(op as InsertOp, expandMentions);
        return [op as InsertOp, index];
    });
};

/**
 * Split a `CompactMarkupDelta` into a list of lines. Each line includes a list of
 * inserts that build the line, excluding the newline character, and an optional
 * collection of attributes associated with the whole line
 */
export const splitLines = (ops: CompactMarkupDelta): MarkupLine[] => {
    const lines: MarkupLine[] = [];
    let currentLine: MarkupLine = { ops: [] };
    ops.forEach(op => {
        if (isString(op.insert)) {
            // Split text ops by the delimiter, appending the first field to the last
            // partial line, and creating new lines for any additional fields
            let appending = true;
            const fields = op.insert.split("\n");
            fields.forEach((field, i) => {
                // If this is the first split field in the op, we haven't seen any newline chars yet,
                // so we should append to the current line. Otherwise, add the current line to the
                // list and create a new blank line
                if (appending) {
                    appending = false;
                }
                else {
                    lines.push(currentLine);
                    currentLine = { ops: [] };
                }

                // The attributes in this op apply to any lines that were completed by the
                // op. The final split field in the op is part of a line that will be
                // completed in a later op, so we should skip that one
                if (i != fields.length - 1) {
                    currentLine.lineAttributes = op.attributes;
                }

                // If there is text content in the split field, push a new op to the current line
                if (field != "") {
                    currentLine.ops.push({
                        insert: field,
                        attributes: op.attributes,
                    });
                }
            });
        }
        else {
            // Add non-text ops to the current line and continue
            currentLine.ops.push(op);
        }
    });
    return lines;
};

/**
 * Produce a `ChangeDelta` for a given `MarkupDelta` which will delete all prefilled
 * content from the markup
 */
export const deletePrefilledChange = (markup: Optional<MarkupDelta>): ChangeDelta =>
    changeDeltaBuilder()
        .pushAll(
            annotateMarkupDeltaOps(markup, false)
                .windows(2)
                .map(([[op, start], [_, end]]) =>
                    op.attributes?.prefilled ?
                        { delete: end - start } :
                        { retain: end - start }
                ),
        )
        .build();

const promotePrefilledOp = (op: InsertOp): InsertOp => {
    const attributes = omit(op.attributes, "prefilled");

    if (Object.keys(attributes).length == 0) {
        return { insert: op.insert };
    }

    return { insert: op.insert, attributes };
};

/**
 * Produce a `ChangeDelta` for a given `MarkupDelta` which will promote all prefilled
 * content in the markup to regular content
 */
export const promotePrefilledChange = (markup: Optional<MarkupDelta>): ChangeDelta =>
    changeDeltaBuilder()
        .pushAll(
            annotateMarkupDeltaOps(markup, false)
                .windows(2)
                .flatMap(([[op, start], [_, end]]): Op[] =>
                    op.attributes?.prefilled ?
                        [
                            { delete: end - start },
                            promotePrefilledOp(op),
                        ] :
                        [
                            { retain: end - start },
                        ]
                ),
        )
        .build();

/**
 * Produce a `ChangeDelta` which will replace the contents of one delta with a given `MarkupDelta`
 */
export const replaceChange = (
    newMarkup: Optional<AnyMarkupDelta>,
): ChangeDelta =>
    changeDeltaBuilder()
        .pushAll(parseMarkupDelta(newMarkup)._instance.ops)
        .delete(Infinity)
        .build();

/**
 * Trim the whitespace from the start and end of a compact markup delta instance
 */
export const trimCompactMarkup = (markup: Optional<CompactMarkupDelta>): CompactMarkupDelta => {
    const ops = cloneDeep(markup ?? []);
    return ops.flatMap(
        (op, i) => {
            if (typeof op.insert != "string") return [op];

            let s = op.insert;
            if (i == 0) {
                s = s.trimStart();
            }
            if (i == ops.length - 1) {
                s = `${s.trimEnd()}\n`;
            }
            return (s == "") ? [] : [Object.assign({}, op, { insert: s })];
        },
    );
};
